import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IDeleteCompanyResponse = void;

/**
 * Удаляет компанию
 *
 * @param companyId id компании
 */
export const makeCompanyDeleteRequest = async (companyId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteCompanyResponse>(
		`companies/${companyId}`
	);
	return response.data;
};
