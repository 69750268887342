import {CellContext} from '@tanstack/react-table';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import {Settings} from './Settings';
import {useContext} from 'react';
import {SettingsCtx} from '@src/core/providers/SettingsProvider';

export const NameCell = ({row}: CellContext<INotificationType, INotificationType>) => {
	const {hasId} = useContext(SettingsCtx);

	return (
		<div>
			{row.original.name} <span className="role-page__table-id">({row.original.id})</span>
			{hasId(row.original.id) && <Settings original={row.original} />}
		</div>
	);
};
