import {useContext} from 'react';
import {IPreparedPermission} from './PermissionsTable';
import {Restrictions} from './restrictions/Restrictions';
import {Params} from './params/Params';
import {RolePermissionsCtx} from '@src/pages/RolePage/RolePage';
import {Fields} from './fields/Fields';

export const SubComponent = ({original}: {original: IPreparedPermission}) => {
	const permissions = useContext(RolePermissionsCtx);
	const isSelected = permissions.some(item => item.permissionId === original.id);
	const {id, availableRestrictions, availableParams, level, fields} = original;

	return (
		<>
			{isSelected && !!availableRestrictions?.length && (
				<Restrictions
					permissionId={id}
					restrictions={availableRestrictions}
					level={level}
				/>
			)}

			{isSelected && !!availableRestrictions?.length && fields && fields.length > 0 && (
				<Fields
					permissionId={id}
					fields={fields}
					restrictions={availableRestrictions}
					level={level}
				/>
			)}

			{isSelected && !!availableParams?.length && (
				<Params
					permissionId={id}
					params={availableParams}
					level={level}
				/>
			)}
		</>
	);
};
