import FieldsTable from './FieldsTable';
import {IEditableObjectState} from '@src/store/modules/entities/object/objectSlice';

interface IFieldsProps {
	value?: IEditableObjectState['fieldsSettings'];
}

const FieldsSettings = ({value}: IFieldsProps) => (
	<>
		<FieldsTable
			settingKey="buildingProblem"
			title="Нарушения при строительстве"
			value={value?.buildingProblem}
			destination="problem"
		/>

		<FieldsTable
			settingKey="acceptanceProblem"
			title="Нарушения при приёмке"
			value={value?.acceptanceProblem}
			destination="problem"
		/>

		<FieldsTable
			settingKey="acceptanceClaimProblem"
			title="Нарушения в заявлениях при приёмке"
			value={value?.acceptanceClaimProblem}
			destination="problem"
		/>

		<FieldsTable
			settingKey="warrantyClaimProblem"
			title="Нарушения в гарантийных обращениях"
			value={value?.warrantyClaimProblem}
			destination="problem"
		/>

		<FieldsTable
			settingKey="problemReply"
			title="Ответы на нарушения"
			value={value?.problemReply}
			destination="problemReply"
			hasCopyColumn={false}
		/>

		<FieldsTable
			settingKey="inspection"
			title="Осмотры"
			value={value?.inspection}
			destination="inspection"
		/>

		<FieldsTable
			settingKey="task"
			title="Задачи"
			value={value?.task}
			destination="task"
			hasCopyColumn={false}
		/>
	</>
);

export default FieldsSettings;
