import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import preventExtraLoading from '@tehzor/tools/core/preventExtraLoading';
import {IGetObjectsResponse, requestObjects} from '@src/api/backend/objects';
import {addErrorToast} from '@tehzor/ui-components';

export type IGetObjectsPayload = IGetObjectsResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetObjectsResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при загрузке списка объектов'});
	}
	return {
		type: types.GET_FAILURE,
		payload: error
	};
};

/**
 * Получает список объектов
 */
export const getObjects = () =>
	preventExtraLoading(
		'objects',
		checkExpiration<IState, IGetObjectsResponse, ApiAction>(
			s => s.entities.objects,
			createApiAction<IGetObjectsResponse>(request, success, failure, requestObjects)
		)
	) as AppThunkAction<Promise<IGetObjectsResponse>>;
