import {useCallback, useContext} from 'react';
import * as React from 'react';
import {Checkbox} from '@tehzor/ui-components';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import {SettingsValueCtx} from './FieldsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {CellContext} from '@tanstack/react-table';
import {SettingsKeyCtx} from './providers/SettingsKeyProvider';

export const CanBeCopiedCell = ({row}: CellContext<ICustomizableField, ICustomizableField>) => {
	const id = row.original.id;
	const settingKey = useContext(SettingsKeyCtx);
	const value = useContext(SettingsValueCtx);
	const dispatch = useAppDispatch();

	const setting = value?.find(item => item.fieldId === id);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(
				objectActions.updateFieldSetting({
					fieldId: id,
					key: settingKey as keyof IObjectFieldsSettings,
					setting: 'canBeCopied',
					value: event.target.checked
				})
			);
		},
		[dispatch, id, settingKey]
	);

	return (
		<Checkbox
			className={{
				root: 'object-page__fields-settings-checkbox',
				icon: 'object-page__fields-settings-checkbox-input'
			}}
			checked={!!setting && setting.canBeCopied}
			disabled={!setting}
			onChange={onChange}
		/>
	);
};
