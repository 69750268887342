import {HTMLAttributes, createContext} from 'react';
import ICustomizableField, {
	CustomizableFieldDestination
} from '@tehzor/tools/interfaces/ICustomizableField';
import {connect} from 'react-redux';
import {IState} from '@src/store/modules';
import {getCustomizableFieldsByDest} from '@src/selectors/entities/customizableFields';
import IObjectFieldSetting from '@tehzor/tools/interfaces/objects/IObjectFieldSetting';
import {useColumns} from './useColumns';
import {SimpleTable} from '@src/components/SimpleTable';
import {SettingKeyProvider} from './providers/SettingsKeyProvider';

interface IFieldsTableProps {
	settingKey: string;
	title: string;
	value?: IObjectFieldSetting[];
	destination: CustomizableFieldDestination;
	hasCopyColumn?: boolean;
}

interface IFieldsTableConnectedProps {
	fields: ICustomizableField[];
}

export const SettingsValueCtx = createContext<IObjectFieldSetting[] | undefined>([]);

const FieldsTable = ({
	settingKey,
	title,
	value,
	hasCopyColumn = true,
	fields
}: IFieldsTableProps & IFieldsTableConnectedProps) => {
	const columns = useColumns({hasCopyColumn});

	const getTableRowProps = (): HTMLAttributes<HTMLTableRowElement> => ({
		className: 'object-page__fields-settings-table-row'
	});

	const getTableCellProps = (): HTMLAttributes<HTMLTableCellElement> => ({
		className: 'object-page__fields-settings-table-cell'
	});

	return (
		<div className="object-page__fields-settings-table">
			<div className="field__wide-label-wrap">
				<div className="field__label">{title}:</div>
			</div>

			<SettingKeyProvider value={settingKey}>
				<SettingsValueCtx.Provider value={value}>
					<SimpleTable
						data={fields}
						columns={columns}
						pageSize={fields.length}
						getTableRowProps={getTableRowProps}
						getTableCellProps={getTableCellProps}
					/>
				</SettingsValueCtx.Provider>
			</SettingKeyProvider>
		</div>
	);
};

const mapStateToProps = (state: IState, props: IFieldsTableProps) =>
	({
		fields: getCustomizableFieldsByDest(state, props)
	}) as IFieldsTableConnectedProps;

export default connect(mapStateToProps)(FieldsTable);
