import {useMemo} from 'react';
import {NameCell} from './NameCell';
import {IsAvailableCell} from './IsAvailableCell';
import {IsRequiredCell} from './IsRequiredCell';
import {CanBeCopiedCell} from './CanBeCopiedCell';
import {ColumnDef} from '@tanstack/react-table';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';

interface IUseColumnsProps {
	hasCopyColumn: boolean;
}

export const useColumns = ({hasCopyColumn}: IUseColumnsProps) =>
	useMemo(() => {
		const columns: Array<ColumnDef<ICustomizableField>> = [
			{
				id: 'name',
				cell: NameCell,
				header: 'Название',
				minSize: 140
			},
			{
				id: 'isAvailable',
				cell: IsAvailableCell,
				header: 'Включено',
				minSize: 110
			},
			{
				id: 'isRequired',
				cell: IsRequiredCell,
				header: 'Обязательно',
				minSize: 110
			}
		];

		if (hasCopyColumn) {
			columns.push({
				id: 'canBeCopied',
				cell: CanBeCopiedCell,
				header: 'Копируется',
				minSize: 110
			});
		}

		return columns;
	}, [hasCopyColumn]);
