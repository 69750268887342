import { HTMLAttributes, useMemo } from 'react';
import {SimpleTable} from '@src/components/SimpleTable';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	extractFilteredCompanies,
	getCompaniesPageOffset,
	getCompaniesPageSize
} from '@src/store/modules/entities/companies/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import {buildCompaniesColumns, ICompanyWithCustomer} from './buildCompaniesColumns';
import {Row} from '@tanstack/react-table';
import {useChangePath} from '@src/core/hooks/useChangePath';
import {useCompaniesFilters} from '../../hooks/useCompaniesFilters/useCompaniesFilters';
import {companiesSettingsActions} from '@src/store/modules/settings/pages/companies/companiesSettingsSlice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {defaultPageSizes} from '@src/constants/defaultPageSizes';

export const CompaniesTable = () => {
	const {data, currentPage, pageCount} = useAppSelector(extractFilteredCompanies);
	const dispatch = useAppDispatch();
	const offset = useAppSelector(getCompaniesPageOffset);
	const pageSize = useAppSelector(getCompaniesPageSize);
	const columns = useMemo(() => buildCompaniesColumns, []);
	const userRoles = useAppSelector(extractUserRoles);
	const {pushPath} = useChangePath();
	const {setOffset, setPageSize} = companiesSettingsActions;
	const {sorting, onSortingChange} = useCompaniesFilters();
	const goToCompany = (companyId: string) => {
		if (
			hasPermission(userRoles, 'companiesEdit', UserRoleScopes.ALL)
			|| hasPermission(userRoles, 'companiesEdit', UserRoleScopes.COMPANY, companyId)
		) {
			pushPath(`/companies/${companyId}`);
		}
	};

	const getCompaniesTableRowProps = (row: Row<ICompanyWithCustomer>): HTMLAttributes<HTMLTableRowElement> => ({
		onClick: () => goToCompany(row.original.id)
	});

	const handlePageChange = (page: number) => {
		const newOffset = page * pageSize;
		if (offset !== newOffset) {
			dispatch(setOffset(page * pageSize));
		}
	};

	const handlePageSizeChange = (value: number) => {
		const newOffset = Math.floor(offset / value);
		dispatch(setPageSize(value));
		dispatch(setOffset(newOffset));
	};

	return (
		<SimpleTable
			enableSorting
			pageCount={pageCount}
			currentPage={currentPage}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			pageSizeOptions={defaultPageSizes}
			pageSize={pageSize}
			sorting={sorting}
			onSortingChange={onSortingChange}
			columns={columns}
			data={data}
			getTableRowProps={getCompaniesTableRowProps}
		/>
	);
};
