import { useMemo } from 'react';
import IUser from '@tehzor/tools/interfaces/IUser';
import {IAuthProvider} from '@src/interfaces/IAuthProvider';

interface IProfilesFieldProps {
	value: IUser['profiles'];
}

const ProfilesField = ({value = {}}: IProfilesFieldProps) => {
	const providers: IAuthProvider[] = [];

	const names = useMemo(
		() =>
			providers.reduce((result, provider) => {
				if (value[provider.id]) {
					result.push(provider.name);
				}
				return result;
			}, [] as string[]),
		[value, providers]
	);

	return names.length > 0 ? (
		<div className="field field_padding">
			<div className="field__label">Привязанные профили:</div>

			{names.map(name => (
				<div className="user-page__profile-name">{name}</div>
			))}
		</div>
	) : null;
};

export default ProfilesField;
