import { httpRequests } from '@tehzor/tools/api/httpRequests';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IAddTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Копирует набор шаблонов нарушений
 */
export const makeCopyTemplatesSetRequest = async (id: string, data: Pick<ISavingProblemTemplatesSet, 'companies' | 'shared'>) => {
	const response = await httpRequests.withToken.post<IAddTemplatesSetResponse>(
		`problem-templates-sets/copy`,
		{
			id,
			...data
		});
	return response.data;
}
