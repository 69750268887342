import {PureComponent} from 'react';
import './ReasonSelect.less';
import {TextField} from '../inputs/TextField';
import classNames from 'classnames';
import Dialog from '../dialogs/Dialog';
import ActionButtons from '../buttons/ActionButtons';
import debounce from '../../utils/debounce';
import Button from '../buttons/Button';
import {ReasonSelectList} from './ReasonSelectList';
import {InlineButton} from '../buttons';

interface IRegStandard {
	id: string;
	shortName: string;
	name: string;
	link?: string;
	startDate?: string;
	endDate?: string;
}

interface IEnhancedRegStandard extends IRegStandard {
	fullName: string;
}

interface IReason {
	value?: string;
	regulatoryStandard?: string;
}

interface IReasonSelectProps {
	className?: string;
	style?: {};
	data?: IRegStandard[];
	value?: IReason | null;
	defaultValue?: IReason | null;
	renderDisplayTextValue?: boolean;
	disabled?: boolean;
	dialogTitle?: string;
	error?: string;
	externalLink?: string;
	noRowsMessage?: string;
	btnLabel?: string;
	searchPlaceholder?: string;
	dialogAcceptBtnLabel?: string;
	dialogRejectBtnLabel?: string;

	onChange?(value: IReason | null): void;
}

interface IReasonSelectState {
	reason?: IReason | null;
	textValue?: string;
	regulatoryStandard?: string;
	data?: IRegStandard[];
	filteredData: IEnhancedRegStandard[];
	displayTextValue: string;	
	dialogOpen: boolean;
}

class ReasonSelect extends PureComponent<IReasonSelectProps, IReasonSelectState> {
	static displayName = 'ReasonSelect';

	static defaultProps: Partial<IReasonSelectProps> = {
		disabled: false,
		dialogTitle: 'Выберите основание'
	};

	private _textValue?: string;

	private _regulatoryStandard?: string;

	private readonly _debounceFilterData: (textValue: string) => void;

	constructor(props: IReasonSelectProps) {
		super(props);

		this._debounceFilterData = debounce(this._filterData, 200);

		const state = {
			filteredData: [],
			displayTextValue: '',
			dialogOpen: false
		} as IReasonSelectState;

		if (props.defaultValue) {
			state.textValue = props.defaultValue.value;
			state.regulatoryStandard = props.defaultValue.regulatoryStandard;
			state.displayTextValue = props.defaultValue.value || '';
		}
		this.state = state;
	}

	static getDerivedStateFromProps(nextProps: IReasonSelectProps, prevState: IReasonSelectState) {
		const state = {} as IReasonSelectState;

		if (
			(nextProps.value !== undefined && nextProps.value !== prevState.reason) ||
			(nextProps.value === undefined && prevState.reason !== undefined)
		) {
			state.reason = nextProps.value;
			if (nextProps.value) {
				state.textValue = nextProps.value.value;
				state.regulatoryStandard = nextProps.value.regulatoryStandard;
				state.displayTextValue = nextProps.value.value || '';

				// если поменялось значение - отфильтруем список стандартов
				const filteredData = ReasonSelect._getFilteredData(
					nextProps.data,
					nextProps.value.value
				);

				// если нашли ровно один стандарт - можем безопасно его выбрать за пользователя
				if (filteredData.length === 1) {
					const [standard] = filteredData;
					state.regulatoryStandard = standard.id;
				}
			} else {
				state.textValue = undefined;
				state.regulatoryStandard = undefined;
				state.displayTextValue = '';
			}
		}
		if (nextProps.data !== prevState.data) {
			state.data = nextProps.data;
			state.filteredData = ReasonSelect._getFilteredData(nextProps.data);
		}
		return Object.keys(state).length ? state : null;
	}

	/**
	 * Фильтрует список СП
	 *
	 * @param {IRegStandard[]} data список СП
	 * @param {string} textValue значение, по которому необходимо отфильтровать
	 * @private
	 */
	private static _getFilteredData = (
		data?: IRegStandard[],
		textValue?: string
	): IEnhancedRegStandard[] => {
		if (!data) {
			return [];
		}
		if (!textValue) {
			return ReasonSelect._enhanceData(data);
		}
		const s = textValue.trim().toLowerCase();
		return ReasonSelect._enhanceData(data).filter(item =>
			item.fullName.toLowerCase().includes(s)
		);
	};

	/**
	 * Добавляет полное название СП для каждого элемента
	 *
	 * @param {IRegStandard[]} data список СП
	 * @private
	 */
	private static _enhanceData = (data: IRegStandard[]): IEnhancedRegStandard[] =>
		data.map(item => ({
			...item,
			fullName: `«${item.name}»`
		}));

	render() {
		const {
			className,
			dialogTitle,
			disabled,
			externalLink,
			noRowsMessage,
			renderDisplayTextValue,
			btnLabel = 'Выбрать основание',
			searchPlaceholder = 'Поиск',
			dialogAcceptBtnLabel = 'Подтвердить',
			dialogRejectBtnLabel = 'Отменить'
		} = this.props;
		const {regulatoryStandard, filteredData, dialogOpen, displayTextValue} = this.state;
		const classes = classNames('reason-select', className);
		return (
			<div className={classes}>
				{renderDisplayTextValue && displayTextValue ? (
					<TextField
						elementType="textarea"
						value={displayTextValue}
						onClick={this._openDialog}
					/>
				) : (
					<InlineButton onClick={this._openDialog} label={btnLabel} type="accent" />
				)}
				{!disabled && (
					<Dialog
						dataTestId="ReasonSelectDialog"
						fullScreenOnMobile
						className="reason-select__dialog"
						isOpen={dialogOpen}
						title={dialogTitle}
						footer={
							<ActionButtons className="reason-select__action-buttons">
								<Button
									type="cancel"
									label={dialogRejectBtnLabel}
									onClick={this._closeDialog}
								/>
								<Button
									type="accent-blue"
									label={dialogAcceptBtnLabel}
									onClick={this._handleOkClick}
								/>
							</ActionButtons>
						}
						shouldDisableScroll={false}
						onRequestClose={this._closeDialog}
					>
						<div className="reason-select__body">
							<TextField
								className="reason-select__text-value"
								elementType="input"
								placeholder={searchPlaceholder}
								icon={<i className="tz-search-20" />}
								onChange={this._handleTextValueChange}
							/>
							<ReasonSelectList
								items={filteredData}
								regStandardId={regulatoryStandard}
								externalLink={externalLink}
								noRowsMessage={noRowsMessage}
								onItemSelect={this._handleRegStandardSelect}
							/>
						</div>
					</Dialog>
				)}
			</div>
		);
	}

	/**
	 * Открывает окно выбора
	 *
	 * @private
	 */
	private _openDialog = () => {
		if (this.props.disabled) {
			return;
		}
		const {textValue, regulatoryStandard} = this.state;
		this._textValue = textValue;
		this._regulatoryStandard = regulatoryStandard;
		this.setState({dialogOpen: true});
	};

	/**
	 * Закрывает окно выбора
	 *
	 * @private
	 */
	private _closeDialog = () => {
		this.setState((state, props) => ({
			textValue: this._textValue,
			regulatoryStandard: this._regulatoryStandard,
			filteredData: ReasonSelect._getFilteredData(props.data),
			dialogOpen: false
		}));
	};

	/**
	 * Обрабатывает нажатие на подтверждение
	 *
	 * @private
	 */
	private _handleOkClick = () => {
		const {data, onChange} = this.props;
		const {textValue, regulatoryStandard} = this.state;

		this.setState({
			displayTextValue: textValue || '',
			filteredData: ReasonSelect._getFilteredData(data),
			dialogOpen: false
		});

		if (onChange) {
			onChange(
				!textValue
					? null
					: {
							value: textValue,
							regulatoryStandard
					  }
			);
		}
	};

	/**
	 * Изменение введенного текста
	 *
	 * @param {string} textValue новое значение
	 * @private
	 */
	private _handleTextValueChange = (textValue: string) => {
		this.setState({
			textValue,
			regulatoryStandard: undefined
		});
		this._debounceFilterData(textValue);
	};

	/**
	 * Выбор элемента из списка СП
	 *
	 * @param {string} regulatoryStandard id СП
	 * @param {string} textValue название СП
	 * @private
	 */
	private _handleRegStandardSelect = (regulatoryStandard: string, textValue: string) => {
		this.setState({
			textValue,
			regulatoryStandard
		});
	};

	/**
	 * Фильтрует список СП и обновляет state
	 *
	 * @param {string} textValue значение, по которому необходимо отфильтровать
	 * @private
	 */
	private _filterData = (textValue: string) => {
		const filteredData = ReasonSelect._getFilteredData(this.props.data, textValue);
		this.setState({filteredData});
	};
}

export {IEnhancedRegStandard};
export default ReasonSelect;
