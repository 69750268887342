import * as types from '@src/constants/entities/customizableFields';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';

const socketConnector = SocketConnector.instance;

/**
 * Получает список полномочий для ролей
 *
 * @return {Object}
 */
export const getCustomizableFields = () => (dispatch: any, getState: () => IState) => {
	const customizableFields = getState().entities.customizableFields;
	if (customizableFields.loaded) {
		return Promise.resolve(customizableFields);
	}
	return dispatch({
		[CALL_BACKEND_API]: {
			actions: [
				getCustomizableFieldsRequest,
				getCustomizableFieldsSuccess,
				getCustomizableFieldsFailure
			],
			promise: () => socketConnector.getCustomizableFields()
		}
	});
};

const getCustomizableFieldsRequest = () => ({
	type: types.GET_CUSTOMIZABLE_FIELDS_REQUEST
});

const getCustomizableFieldsSuccess = (response: INormalizedData<ICustomizableField>) => ({
	type: types.GET_CUSTOMIZABLE_FIELDS_SUCCESS,
	payload: {...response}
});

function getCustomizableFieldsFailure(error: IError) {
	if (error.code !== 401) {
		addErrorToast({title: 'Ошибка при загрузке списка настраиваемых полей'});
	}
	return {
		type: types.GET_CUSTOMIZABLE_FIELDS_FAILURE,
		payload: {...error}
	};
}
