import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';

export type IEditCompanyResponse = ICompany;

/**
 * Изменяет компанию
 *
 * @param companyId id компании
 * @param company данные компании
 */

export const makeCompanyEditRequest = async (companyId: string, company: ISavingCompany) => {
	const response = await httpRequests.withToken.put<IEditCompanyResponse>(
		`companies/${companyId}`,
		{
			...company
		}
	);
	return response.data;
};
