import {createReducer} from '@reduxjs/toolkit';
import * as types from './constants';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {
	ILoadableEntitiesState,
	getLoadableEntitiesInitialState,
	handleLoadableEntitiesGetting
} from '@tehzor/tools/core/storeHelpers/reducers';
import {CLEAR_STORE} from '@src/store/modules/auth/constants';

export type IWorkAcceptanceStatusesState = ILoadableEntitiesState<IWorkAcceptanceStatus>;

export const workAcceptanceStatuses = createReducer<IWorkAcceptanceStatusesState>(
	getLoadableEntitiesInitialState<IWorkAcceptanceStatus>(),
	{
		[types.GET_SUCCESS]: handleLoadableEntitiesGetting<IWorkAcceptanceStatus>(),
		[CLEAR_STORE]: () => getLoadableEntitiesInitialState<IWorkAcceptanceStatus>()
	}
);
