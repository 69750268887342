import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface ICheckUserContentResponse {
	checks?: number;
	checkStories?: number;
	problems?: number;
	problemStories?: number;
	inspections?: number;
	inspectionStories?: number;
	warrantyClaims?: number;
	warrantyClaimStories?: number;
	categories?: number;
	categoryStories?: number;
	plans?: number;
	// planStories?: number;
}

/**
 * Проверяет наличие созданного контента пользователем
 *
 * @param userId id пользователя
 */
export const makeCheckUserContentRequest = async (userId: string) => {
	const response = await httpRequests.withToken.get<ICheckUserContentResponse>(
		`users/check-content/${userId}`
	);
	return response.data;
};
