import ICompany from './companies/ICompany';
import IPermission from './IPermission';
import {IBriefUser} from './users/IBriefUser';

export enum UserRoleScopes {
	ALL = 'all',
	COMPANY = 'company',
	OBJECT = 'object'
}

export interface IUserRole {
	roleId: string;
	roleName: string;
	scopeIds: string[];
	scope: UserRoleScopes;
	permissions?: IPermission[];
}

export default interface IUser {
	id: string;
	email?: string;
	companies?: ICompany[];
	firstName: string;
	middleName?: string;
	lastName: string;
	fullName: string;
	displayName: string;
	position?: string;
	phone?: string;
	roles?: IUserRole[];
	profiles?: {[key: string]: string};
	color?: string;
	createdAt?: Date | string;
	createdBy?: IBriefUser;
	modifiedAt?: Date | string;
	modifiedBy?: IBriefUser;
	activated?: boolean;
	deleted?: boolean;
}

export interface IUserWithSubscription extends IUser {
	subscribed?: boolean;
}
