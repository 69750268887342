import {IEditableObjectState} from '@src/store/modules/entities/object/objectSlice';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IFile from '@tehzor/tools/interfaces/IFile';
import isEqual from 'lodash/isEqual';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {ISavingObject} from '@src/interfaces/saving/ISavingObject';

const isConstructionManagerEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	(original?.constructionManager
		? original.constructionManager?.fullName !== state.constructionManager?.fullName
		  || original.constructionManager?.phone !== state.constructionManager?.phone
		: !!state.constructionManager);

const isProjectManagerEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	(original?.projectManager
		? original.projectManager?.fullName !== state.projectManager?.fullName
		  || original.projectManager.phone !== state.projectManager?.phone
		: !!state.projectManager);

const isStagesEdited = (state: IEditableObjectState, original?: ISavingObject) =>
	(state?.stages || original?.stages
		? Object.keys(state?.stages || original?.stages || {}).reduce((acc, stage: ObjectStageIds) => {
				if (original?.stages?.[stage]?.from !== state.stages?.[stage]?.from) {
					acc = true;
				}
				if (original?.stages?.[stage]?.to !== state.stages?.[stage]?.to) {
					acc = true;
				}

				return acc;
		  }, false)
		: !!state.stages);

/**
 * Конвертирует данные в формат, пригодный для отправки на сервер
 *
 * @param {IEditableObjectState} edited измененные данные
 * @param {IObject} original первоначальные данные
 * @param {string|undefined} objectId id объекта
 */
export const convertToSave = (edited: IEditableObjectState, original?: IObject, objectId?: string): ISavingObject => {
	const newImage
		= edited.newImage?.length && edited.newImage[0].tempFile ? {id: edited.newImage[0].tempFile.id} : undefined;
	if (!original || !objectId) {
		return {
			...edited,
			fullName: edited.fullName?.trim() || edited.name?.trim(),
			image: newImage as IFile,
			newImage: null
		};
	}
	const object = {} as ISavingObject;
	if (edited.name !== original.name) {
		object.name = edited.name?.trim();
	}
	if (edited.fullName !== original.fullName) {
		object.fullName = edited.fullName?.trim() || edited.name?.trim();
	}
	if (edited.externalId !== original.externalId) {
		object.externalId = edited.externalId;
	}
	if (edited.companyId !== original.companyId) {
		object.companyId = edited.companyId;
	}
	if (!edited.parentId && original.parentId) {
		object.parentId = null;
	}
	if (edited.parentId && (!original.parentId || edited.parentId !== original.parentId)) {
		object.parentId = edited.parentId;
	}
	if (edited.city !== original.city) {
		object.city = edited.city?.trim();
	}
	if (edited.address !== original.address) {
		object.address = edited.address?.trim();
	}
	if (edited.generalContractor !== original.generalContractor) {
		object.generalContractor = edited.generalContractor;
	}
	if (edited.stage !== original.stage) {
		object.stageId = edited.stage;
	}
	if (newImage) {
		object.newImage = newImage;
	} else if (!edited.image && original.fullImage) {
		object.newImage = null;
	}
	if (!isEqual(edited.fieldsSettings, original.fieldsSettings)) {
		object.fieldsSettings = edited.fieldsSettings;
	}
	if (edited.spaceIndicatorsSet !== original.spaceIndicatorsSet) {
		object.spaceIndicatorsSet = edited.spaceIndicatorsSet;
	}
	if (edited.problemTemplatesSet !== original.problemTemplatesSet) {
		object.problemTemplatesSet = edited.problemTemplatesSet;
	}
	if (edited.incObjectId !== original.incObjectId) {
		object.incObjectId = edited.incObjectId;
	}
	if (!isEqual(edited.objectIdsForInc, original.objectIdsForInc)) {
		object.objectIdsForInc = edited.objectIdsForInc;
	}
	if (isConstructionManagerEdited(edited, original)) {
		object.constructionManager = edited.constructionManager;
	}
	if (isProjectManagerEdited(edited, original)) {
		object.projectManager = {
			...edited.projectManager,
			fullName: edited.projectManager?.fullName?.trim(),
			phone: edited.projectManager?.phone?.trim()
		};
	}
	if (isStagesEdited(edited, original)) {
		object.stages = edited.stages;
	}
	return object;
};
