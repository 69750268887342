import {IconMenu, MenuItem} from '@tehzor/ui-components';
import {usePermissions} from '../utils/usePermissions';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {archiveObject, deleteObject, restoreObject} from '@src/store/modules/entities/object/actions';
import history from '@src/core/history';

interface IPageHeaderMenuProps {
	objectId?: string;
	isObjectDeleted?: boolean;
}

const PageHeaderMenu = (props: IPageHeaderMenuProps) => {
	const {objectId, isObjectDeleted} = props;
	const userRoles = useAppSelector(extractUserRoles);
	const permissions = usePermissions(userRoles, objectId);
	const dispatch = useAppDispatch();
	const [deleteDialog, getDeleteConfirmation] = useConfirmDialog('Вы действительно хотите удалить объект?');
	const [restoreDialog, getRestoreConfirmation] = useConfirmDialog('Вы действительно хотите восстановить объект?');
	const [archiveDialog, getArchiveConfirmation] = useConfirmDialog('Вы действительно хотите архивировать объект?');

	const [, handleDelete] = useAsyncFn(async () => {
		if (objectId && (await getDeleteConfirmation())) {
			await dispatch(deleteObject(objectId));
		}
	}, [objectId]);

	const [, handleRestore] = useAsyncFn(async () => {
		if (objectId && (await getRestoreConfirmation())) {
			await dispatch(restoreObject(objectId));
		}
	}, [objectId]);

	const [, handleArchive] = useAsyncFn(async () => {
		if (objectId && (await getArchiveConfirmation())) {
			await dispatch(archiveObject(objectId));
			history.replace('/objects');
		}
	}, [objectId]);

	const menuActions = [];

	if (!isObjectDeleted && permissions.canDelete) {
		menuActions.push(
			<MenuItem
				key="delete"
				className="menu-item_red"
				icon={<i className="tz-delete"/>}
				onClick={handleDelete}
			>
				Удалить
			</MenuItem>
		);
	}
	if (isObjectDeleted) {
		if (permissions.canRestore) {
			menuActions.push(
				<MenuItem
					key="restore"
					// className="menu-item_red"
					// icon={<i className="tz-delete"/>}
					onClick={handleRestore}
				>
					Восстановить
				</MenuItem>
			);
		}
		if (permissions.canArchive) {
			menuActions.push(
				<MenuItem
					key="archive"
					// className="menu-item_red"
					// icon={<i className="tz-delete"/>}
					onClick={handleArchive}
				>
					Архивировать
				</MenuItem>
			);
		}
	}

	if (menuActions.length) {
		return (
			<>
				<IconMenu>{menuActions}</IconMenu>

				{deleteDialog}
				{restoreDialog}
				{archiveDialog}
			</>
		);
	}
	return null;
};

export default PageHeaderMenu;
