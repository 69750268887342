import {CheckboxCell} from './CheckboxCell';
import {PermExpanderCell} from './PermExpanderCell';
import {RestExpanderCell} from './RestExpanderCell';
import {ColumnDef} from '@tanstack/react-table';
import {IPreparedPermission} from '@src/pages/RolePage/components/permissions/PermissionsTable';
import {NameCell} from './NameCell';

export const columns: Array<ColumnDef<IPreparedPermission>> = [
	{
		id: '_permExpander',
		cell: PermExpanderCell,
		minSize: 50,
		size: 50
	},
	{
		accessorKey: 'name',
		cell: NameCell,
		minSize: 100
	},
	{
		accessorKey: 'checkbox',
		cell: CheckboxCell,
		minSize: 36,
		maxSize: 350
	},
	{
		id: 'restExpander',
		cell: RestExpanderCell,
		minSize: 50
	}
];
