import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';

export type IGetCompaniesResponse = INormalizedData<ICompany>;

/**
 * Получает список компаний
 */
export const requestCompanies = async () => {
	const response = await httpRequests.withToken.get<IGetCompaniesResponse>('companies');
	return response.data;
};
