import { memo, MouseEvent } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellContext} from '@tanstack/react-table';
import IRole from '@src/interfaces/IRole';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import './DeleteRoleCell.less';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {deleteRole} from '@src/store/modules/entities/roles/actions/delete';

const deleteIcon = <i className="tz-delete"/>;

export const DeleteRoleCell = memo(({row}: CellContext<IRole, IRole>) => {
	const dispatch = useAppDispatch();
	const [dialog, getDeleteConfirmation] = useConfirmDialog(
		`Вы действительно хотите удалить "${row.original.name}"?`,
		undefined,
		{acceptBtnProps: {type: 'accent-red'}}
	);
	const [, handleRoleDelete] = useAsyncFn(
		async (e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			if (await getDeleteConfirmation()) {
				await dispatch(deleteRole(row.original.id));
			}
		},
		[row.original.id]
	);

	return (
		<div className="delete-role-cell">
			<IconButton
				className="delete-role-cell__delete-button"
				onClick={handleRoleDelete}
				type="transparent"
			>
				{deleteIcon}
			</IconButton>
			{dialog}
		</div>
	);
});
