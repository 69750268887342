import {HTMLAttributes, createContext, useMemo} from 'react';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import IUser, {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';
import arrayToTree from 'array-to-tree';
import {getObjectsAsArray} from '@src/selectors/entities/objects';
import IRole from '@src/interfaces/IRole';
import {getRolesAsArray} from '@src/selectors/entities/roles';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {SimpleTable} from '@src/components/SimpleTable';
import {columns} from './columns';
import {Cell, Row} from '@tanstack/react-table';

interface IRolesTableProps {
	user?: IUser;
}

export interface IScopeItem {
	id: string;
	name: string;
	type: UserRoleScopes;
	children: IScopeItem[];
	level: number;
	disabled?: boolean;
}

export const RolesContext = createContext<IRole[]>([]);

const convertObject = (
	object: arrayToTree.Tree<IObject>,
	level: number,
	disabled?: boolean
): IScopeItem => ({
	id: object.id,
	name: object.name,
	type: UserRoleScopes.OBJECT,
	children: object.children ? object.children.map(item => convertObject(item, level + 1)) : [],
	level,
	disabled
});

const rowIdent = 50;

const RolesTable = ({user}: IRolesTableProps) => {
	const userRoles = useAppSelector(extractUserRoles);
	const companies = useAppSelector(extractCompaniesAsArray);
	const objects = useAppSelector(getObjectsAsArray);
	const roles = useAppSelector(getRolesAsArray);

	const deleted = user?.deleted;

	const hasGlobalRole = hasPermission(userRoles, 'usersDelete', UserRoleScopes.ALL);

	const data = useMemo(() => {
		const objectsTree = arrayToTree<IObject>(objects, {
			parentProperty: 'parentId',
			customID: 'id'
		});
		const tree: IScopeItem[] = companies.map(company => ({
			id: company.id,
			name: company.name,
			type: UserRoleScopes.COMPANY,
			children: objectsTree
				.filter(item => item.companyId === company.id)
				.map(item => convertObject(item, 1, deleted)),
			level: 0,
			disabled: deleted
		}));
		if (hasGlobalRole) {
			tree.unshift({
				id: 'all',
				name: 'Глобальная роль',
				type: UserRoleScopes.ALL,
				children: [],
				level: 0,
				disabled: deleted
			});
		}
		return tree;
	}, [companies, objects, hasGlobalRole, deleted]);

	const getTableRowProps = (row: Row<IScopeItem>): HTMLAttributes<HTMLTableRowElement> => ({
		className: 'user-page__roles-table-row',
		style: {
			paddingLeft: (row.original.level || 0) * rowIdent
		}
	});

	const getTableCellProps = (
		cell: Cell<IScopeItem, IScopeItem>
	): HTMLAttributes<HTMLTableCellElement> => {
		if (cell.column.id === 'role') {
			return {
				className: 'user-page__roles-table-cell user-page__roles-table-cell-role'
			};
		}

		return {
			className: 'user-page__roles-table-cell'
		};
	};

	return (
		<RolesContext.Provider value={roles}>
			<div className="field__wide-label-wrap">
				<div className="field__label">Привязка ролей к компаниям и объектам:</div>
			</div>

			<SimpleTable
				data={data}
				columns={columns}
				pageSize={data.length}
				getTableRowProps={getTableRowProps}
				getTableCellProps={getTableCellProps}
			/>
		</RolesContext.Provider>
	);
};

export default RolesTable;
