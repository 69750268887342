import * as types from '@src/constants/entities/rolePermissions';
// @ts-ignore
import {CALL_BACKEND_API} from '../../middlewares/backendMiddleware';
// @ts-ignore
import SocketConnector from '../../api/SocketConnector';
import {IState} from '@src/store/modules';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IRolePermission from '@tehzor/tools/interfaces/IRolePermission';
import IError from '@tehzor/tools/interfaces/IError';
import {addErrorToast} from '@tehzor/ui-components';

const socketConnector = SocketConnector.instance;

/**
 * Получает список полномочий для ролей
 *
 * @return {Object}
 */
export const getRolePermissions = () => (dispatch: any, getState: () => IState) => {
	const rolePermissions = getState().entities.rolePermissions;
	if (rolePermissions.loaded) {
		return Promise.resolve(rolePermissions);
	}
	return dispatch({
		[CALL_BACKEND_API]: {
			actions: [
				getRolePermissionsRequest,
				getRolePermissionsSuccess,
				getRolePermissionsFailure
			],
			promise: () => socketConnector.getRolePermissions()
		}
	});
};

const getRolePermissionsRequest = () => ({
	type: types.GET_ROLE_PERMISSIONS_REQUEST
});

const getRolePermissionsSuccess = (response: INormalizedData<IRolePermission>) => ({
	type: types.GET_ROLE_PERMISSIONS_SUCCESS,
	payload: {...response}
});

function getRolePermissionsFailure(error: IError) {
	if (error.code !== 401) {
		addErrorToast({title: 'Ошибка при загрузке списка полномочий ролей'});
	}
	return {
		type: types.GET_ROLE_PERMISSIONS_FAILURE,
		payload: {...error}
	};
}
