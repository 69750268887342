import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetCheckRecordStatusesResponse = INormalizedData<ICheckRecordStatus>;

/**
 * Возвращает список статусов записей чек листов
 */
export const requestCheckRecordStatuses = async () => {
	const response = await httpRequests.withToken.get<IGetCheckRecordStatusesResponse>(
		'check-records-statuses/get'
	);
	return response.data;
};
