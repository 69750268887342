import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IEditObjectResponse = IObject;

/**
 * Изменяет объект
 *
 * @param objectId id объекта
 * @param object данные объекта
 */

export const makeObjectEditRequest = async (objectId: string, object: ISavingObject) => {
	const response = await httpRequests.withToken.put<IEditObjectResponse>(`/objects/${objectId}`, {
		objectId,
		...object
	});
	return response.data;
};
