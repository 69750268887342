import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IEditTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Обновляет набор шаблонов нарушений
 */
export const makeEditTemplatesSetRequest = async (id: string, data: ISavingProblemTemplatesSet) => {
	const response = await httpRequests.withToken.put<IEditTemplatesSetResponse>(
		`problem-templates-sets/${id}`,
		{
			...data
		}
	);
	return response.data;
};
