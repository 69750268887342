import {ToastsContainersIds} from './interfaces';
import {ToastContainer} from 'react-toastify';

interface IToastsProps {
	isDesktop?: boolean;
}

export const Toasts = ({isDesktop = true}: IToastsProps) => (
	<ToastContainer
		stacked
		className="toasts-main-container"
		containerId={ToastsContainersIds.MAIN_STACKED}
		autoClose={5000}
		position={isDesktop ? 'bottom-right' : 'top-center'}
		theme="light"
		limit={isDesktop ? 20 : 5}
	/>
);
