import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IUpdateRegulatoryStandardsResponse,
	makeUpdateRegulatoryStandardsRequest
} from '@src/api/backend/regulatoryStandards';
import {addErrorToast} from '@tehzor/ui-components';

export type IUpdateRegulatoryStandardsPayload = IUpdateRegulatoryStandardsResponse;

const request = () => ({type: types.UPDATE_REQUEST});

const success = (response: IUpdateRegulatoryStandardsResponse) => ({
	type: types.UPDATE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	addErrorToast({title: 'Ошибка при обновлении списка стандартов'});
	return {
		type: types.UPDATE_FAILURE,
		payload: error
	};
};

/**
 * Обновляет списко планов
 *
 */
export const updateRegulatoryStandards = () =>
	createApiAction<IUpdateRegulatoryStandardsResponse>(
		request,
		success,
		failure,
		makeUpdateRegulatoryStandardsRequest
	);
