import {ReactNode, createContext} from 'react';

/**
 * Контекст нужен для того чтобы прокинуть ключ в дочерние cell
 */
export const SettingsKeyCtx = createContext<string>('');

export const SettingKeyProvider = ({children, value}: {children: ReactNode; value: string}) => (
	<SettingsKeyCtx.Provider value={value}>{children}</SettingsKeyCtx.Provider>
);
