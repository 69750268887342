import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IRestoreUserResponse = IUser;

/**
 * Восстанавливает пользователя
 *
 * @param userId id пользователя
 */
export const makeUserRestoreRequest = async (userId: string) => {
	const response = await httpRequests.withToken.post<IRestoreUserResponse>('users/restore', {
		userId
	});
	return response.data;
};
