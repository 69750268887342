import IUser from '@tehzor/tools/interfaces/IUser';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';

// Функция для вычисления изменений в roles
export function calculateUserRolesUpdateDiff(
	originalRoles: IUser['roles'],
	updatedRoles: ISavingUser['roles']
) {
	const setRoles: ISavingUser['roles'] = [];
	const unsetRoles: ISavingUser['roles'] = [];

	updatedRoles?.forEach(updatedRole => {
		const originalRole = originalRoles?.find(
			role => role.roleId === updatedRole.roleId && role.scope === updatedRole.scope
		);

		if (originalRole) {
			// Добавляем только уникальные scopeIds, которых нет в originalRole
			const newScopeIds = updatedRole.scopeIds.filter(
				scopeId => !originalRole.scopeIds.includes(scopeId)
			);

			if (newScopeIds.length > 0) {
				setRoles.push({...updatedRole, scopeIds: newScopeIds});
			}
		} else {
			// Если роль отсутствует в originalRoles, добавляем ее полностью
			setRoles.push({...updatedRole});
		}
	});

	originalRoles?.forEach(originalRole => {
		const updatedRole = updatedRoles?.find(
			role => role.roleId === originalRole.roleId && role.scope === originalRole.scope
		);

		if (updatedRole) {
			const removedScopeIds = originalRole.scopeIds.filter(
				scopeId => !updatedRole.scopeIds.includes(scopeId)
			);

			if (removedScopeIds.length > 0) {
				unsetRoles.push({...originalRole, scopeIds: removedScopeIds});
			}
		} else {
			unsetRoles.push(originalRole);
		}
	});

	return {
		setRoles: setRoles.length > 0 ? setRoles : undefined,
		unsetRoles: unsetRoles.length > 0 ? unsetRoles : undefined
	};
}
