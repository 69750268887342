import {forwardRef, ReactNode, Ref} from 'react';
import styles from './Board.module.less';
import classNames from 'classnames';

interface IBoardProps {
	title?: string;
	counter?: string | number;
	children: ReactNode;
	showHeader?: boolean;
	onTitleClick?: () => void;
}

const Board = (
	{title, counter, children, showHeader = true, onTitleClick}: IBoardProps,
	ref: Ref<HTMLDivElement>
) => (
	<div className={styles.wrapper} ref={ref}>
		{showHeader && (
			<div className={styles.header}>
				<p
					className={classNames(styles.title, onTitleClick && styles.titleClickable)}
					onClick={onTitleClick}
				>
					{title} {!!counter && <span className={styles.counter}>({counter})</span>}
				</p>
			</div>
		)}
		<div className={styles.container}>{children}</div>
	</div>
);

export default forwardRef(Board);
