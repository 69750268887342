import { useCallback, useMemo, useState } from 'react';
import {IEditableCompanyEmployee, useEditableCompanyDispatchCtx} from '@src/core/hooks/states/useEditableCompanyState';
import {InlineButton, SimpleTable, UserSelectDialog} from '@tehzor/ui-components';
import {columns} from './components/columns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import EditableFieldHeader from '../EditableFieldHeader';
import IUser from '@tehzor/tools/interfaces/IUser';

interface IEditableCompanyInspectorsProps {
	value: IEditableCompanyEmployee[];
}

export const EditableCompanyInspectors = ({value}: IEditableCompanyInspectorsProps) => {
	const users = useAppSelector(s => s.entities.users);
	const editingDispatch = useEditableCompanyDispatchCtx();

	const availableUsers = useMemo(
		() =>
			users.allIds
				.reduce<IUser[]>((prev, id) => {
					if (value.every(e => e.userId !== id)) {
						if (!users.byId[id].deleted && users.byId[id].activated) {
							prev.push(users.byId[id]);
						}
					}
					return prev;
				}, [])
				.sort((a, b) => (a.fullName > b.fullName ? 1 : -1)),
		[value, users]
	);

	const selectedUsers = useMemo(
		() =>
			value.map<IUser>(({userId}) => {
				const user = users.byId[userId];
				if (user) {
					return user;
				}
				return {
					id: userId,
					firstName: '',
					lastName: '',
					fullName: userId,
					displayName: userId
				};
			}),
		[value, users]
	);

	const [fakeNewValue, setFakeNewValue] = useState<string[]>([]);
	const handleAdding = useCallback(
		(ids: string[]) => {
			setFakeNewValue([]);
			editingDispatch({type: 'update', field: 'employees', value: value.concat(ids.map(userId => ({userId})))});
		},
		[value]
	);

	return (
		<div>
			<EditableFieldHeader title="Сотрудники">
				<UserSelectDialog
					trigger={(
						<InlineButton
							type="accent"
							leftIcon={<i className="tz-plus-20"/>}
							label="Добавить"
						/>
					)}
					multiple
					data={availableUsers}
					value={fakeNewValue}
					onChange={handleAdding}
				/>
			</EditableFieldHeader>

			<SimpleTable
				columns={columns}
				data={selectedUsers}
				showHead={false}
			/>
		</div>
	);
};
