import BooleanRestriction from './BooleanRestriction';
import ProblemStatusesRestriction from './ProblemStatusesRestriction';
import {IStatusesCtx} from '../PermissionsTable';

export interface IRestrictionProps {
	id: string;
	name: string;
	dataType: string;
	permissionId: string;
	type?: keyof IStatusesCtx;
}

const Restriction = (props: IRestrictionProps) => {
	switch (props.dataType) {
		case 'boolean':
			return <BooleanRestriction {...props} />;
		case 'problemStatuses':
			return (
				<ProblemStatusesRestriction
					{...props}
					type="problemStatus"
				/>
			);
		case 'workAcceptanceStatuses':
			return (
				<ProblemStatusesRestriction
					{...props}
					type="workAcceptanceStatus"
				/>
			);
		case 'checkItemStatuses':
			return (
				<ProblemStatusesRestriction
					{...props}
					type="checkItemStatus"
				/>
			);
		case 'checkListStatuses':
			return (
				<ProblemStatusesRestriction
					{...props}
					type="checkListStatus"
				/>
			);
		default:
			return null;
	}
};

export default Restriction;
