import {useContext} from 'react';
import {Checkbox} from '@tehzor/ui-components';
import {ChannelsCtx} from './SubsTable';
import {RoleDispatchCtx, RoleSubsCtx} from '../../RolePage';
import {CellContext} from '@tanstack/react-table';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';

export const ChannelsCell = ({row}: CellContext<INotificationType, INotificationType>) => {
	const original = row.original;
	const channels = useContext(ChannelsCtx);
	const subs = useContext(RoleSubsCtx);
	const dispatch = useContext(RoleDispatchCtx);

	const current = subs.find(s => s.type === original.id);

	const handleChange = (channelId: string, checked: boolean) => {
		const value = current
			? checked
				? [...current.channels, channelId]
				: current.channels.filter(id => id !== channelId)
			: [channelId];

		dispatch({
			type: 'update-subs',
			typeId: original.id,
			channels: value,
			settings: current && value.length ? current.settings : undefined
		});
	};

	return channels.map(item => (
		<Checkbox
			key={item.id}
			className="role-page__subs-table-channel-chbox"
			checked={current ? current.channels.includes(item.id) : false}
			onChange={e => handleChange(item.id, e.target.checked)}
		>
			{item.name}
		</Checkbox>
	));
};
