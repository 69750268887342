import { useCallback, useEffect, useState } from 'react';
import './EditableCompany.less';
import {ActionButtons, Button, Dialog, TabContent, TabLink, Tabs} from '@tehzor/ui-components';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAsync from 'react-use/lib/useAsync';
import {getUsers} from '@src/store/modules/entities/users/actions';
import {
	convertToSave,
	EditableCompanyDispatchCtx,
	hasErrors,
	isCompanyEdited,
	useEditableCompanyState
} from '@src/core/hooks/states/useEditableCompanyState';
import {usePageReloadPrevent} from '@tehzor/tools/core/hooks/usePageReloadPrevent';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {addCompany} from '@src/store/modules/entities/company/actions';
import {EditableCompanyInspectors} from '@src/components/editableFields/EditableCompanyInspectors';
import MainTab from './components/MainTab';
import useConfirmDialog from '@tehzor/ui-components/src/hooks/useConfirmDialog';

interface IEditableCompanyProps {
	isOpen: boolean;

	onClose: () => void;
}

const EditableCompany = ({isOpen, onClose}: IEditableCompanyProps) => {
	const dispatch = useAppDispatch();

	const [closingDialog, getClosingConfirmation] = useConfirmDialog(
		'Вы действительно хотите закрыть?',
		'Все введённые данные будут потеряны',
		{acceptBtnProps: {type: 'accent-red'}}
	);

	useAsync(async () => {
		await dispatch(getUsers());
	}, []);

	const [editingState, editingDispatch] = useEditableCompanyState();
	const [isEdited, setIsEdited] = useState(false);
	usePageReloadPrevent(isEdited);

	useEffect(() => {
		setIsEdited(isCompanyEdited(editingState));
	}, [editingState]);

	const [savingState, save] = useAsyncFn(async () => {
		// Проверка наличия ошибок в состояниях
		if (hasErrors(editingState)) {
			editingDispatch({type: 'update-errors'});
			return;
		}
		// Проверка, были ли отредактированы поля
		if (!isCompanyEdited(editingState)) {
			return;
		}
		await dispatch(addCompany(convertToSave(editingState)));
		onClose();
	}, [editingState]);

	const handleAfterClose = useCallback(() => {
		editingDispatch({type: 'reset'});
	}, []);
	const handleClose = async () => {
		if (!isCompanyEdited(editingState)) { onClose(); }
		if (isCompanyEdited(editingState) && (await getClosingConfirmation())) {
			onClose();
		}
	};
	return (
		<Dialog
			className={{root: 'editable-company', body: 'editable-company__body'}}
			isOpen={isOpen}
			title="Создание новой компании"
			footer={(
				<ActionButtons>
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={savingState.loading || !isEdited}
						onClick={save}
					/>
					<Button
						type="cancel"
						label="Отменить"
						disabled={savingState.loading}
						onClick={onClose}
					/>
				</ActionButtons>
			)}
			fullScreenOnTablet
			onRequestClose={handleClose}
			onAfterClose={handleAfterClose}
		>
			<EditableCompanyDispatchCtx.Provider value={editingDispatch}>
				<Tabs
					links={[
						<TabLink label="Основное"/>,
						<TabLink label="Сотрудники"/>
					]}
				>
					<TabContent>
						<MainTab
							editingState={editingState}
							disabled={savingState.loading}
						/>
					</TabContent>

					<TabContent>
						<EditableCompanyInspectors
							value={editingState.employees}
						/>
					</TabContent>
				</Tabs>
			</EditableCompanyDispatchCtx.Provider>
			{closingDialog}
		</Dialog>
	);
};

export default EditableCompany;