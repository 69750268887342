import { memo, useCallback, useEffect } from 'react';
import {EditableFieldLabel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {extractTemplatesSetsAsArray} from '@src/store/modules/entities/problemTemplatesSets/selectors';
import {SingleSelect} from '@src/components/SingleSelect';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';

function determineDefaultSet(
	objects: Record<string, IObject>,
	sets: IProblemTemplatesSet[],
	companyId?: string,
	parentId?: string
) {
	if (parentId && objects[parentId]?.problemTemplatesSet) {
		return objects[parentId].problemTemplatesSet;
	}

	if (companyId) {
		const set = sets.find(s => s.companies?.includes(companyId));

		if (set) {
			return set.id;
		}
	}

	const set = sets.find(s => s.shared);

	if (set) {
		return set.id;
	}

	return undefined;
}

interface IProblemTemplatesSetFieldProps {
	value?: string;
	companyId?: string;
	parentId?: string;
	onChange?: (value: string) => void;
}

export const ProblemTemplatesSetField = memo((
	{
		value,
		companyId,
		parentId,
		onChange
	}: IProblemTemplatesSetFieldProps
) => {
	const templatesSets = useAppSelector(extractTemplatesSetsAsArray);
	const objects = useAppSelector(s => s.entities.objects);
	const dispatch = useAppDispatch();

	const filteredTemplatesSet = companyId
		? templatesSets.filter(template => template.companies?.includes(companyId) || template.shared)
		: templatesSets;

	useEffect(() => {
		const setId = determineDefaultSet(objects.byId, templatesSets, companyId, parentId);

		if (!value && setId) {
			onChange?.(setId);
		}
	}, [companyId, parentId, objects, templatesSets, onChange, value, dispatch]);

	const handleChange = useCallback((id: string) => {
		onChange?.(id);
		dispatch(objectActions.setEdited(true));
	}, [onChange, dispatch]);

	return (
		<div className="field">
			<EditableFieldLabel className="field__label">Набор шаблонов нарушений</EditableFieldLabel>
			<SingleSelect
				noHeader
				optionId={value}
				options={filteredTemplatesSet}
				onChange={handleChange}
			/>
		</div>
	);
});
