import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IUpdateRegulatoryStandardsResponse = INormalizedData<IRegulatoryStandard>;

/**
 * Обновляет список стандартов в базе данных
 */
export const makeUpdateRegulatoryStandardsRequest = async () => {
	const response = await httpRequests.withToken.post<IUpdateRegulatoryStandardsResponse>(
		'regulatory-standards/update'
	);
	return response.data;
};
