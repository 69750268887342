import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {Checkbox} from '@tehzor/ui-components';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';

interface ICheckboxFieldProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value: boolean | undefined;
	fieldName: keyof S;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
}

export const CheckboxField = <S, E>({
	className,
	style,
	label,
	value,
	fieldName,
	editingDispatch,
	required,
	disabled
}: ICheckboxFieldProps<S, E>) => {
	const handleChange = useCallback(() => {
		editingDispatch({type: 'update', field: fieldName, value: !value});
		if (required) {
			editingDispatch({type: 'update-error', field: fieldName});
		}
	}, [value, required]);

	return (
		<div
			className={className}
			style={style}
		>
			<Checkbox
				disabled={disabled}
				checked={value}
				onChange={handleChange}
			>
				{label}
			</Checkbox>
		</div>
	);
};
