import {Select2, SelectOption, SelectPopup, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import { useContext, useCallback } from 'react';
import {UserDispatchCtx} from '../../UserPage';

interface ISubscribeProps {
	value?: boolean;
	disabled?: boolean;
}

enum SubscribeOption {
	TRUE = 'true',
	FALSE = 'false',
	UNDEFINED = 'undefined'
}

const subscribeOptionsMap = {
	[SubscribeOption.TRUE]: {
		content: 'Включено',
		value: true
	},
	[SubscribeOption.FALSE]: {
		content: 'Выключено',
		value: false
	},
	[SubscribeOption.UNDEFINED]: {
		content: 'Не задано',
		value: undefined
	}
};

export const SubscribeField = ({value, disabled}: ISubscribeProps) => {
	const dispatch = useContext(UserDispatchCtx);

	const handleChange = useCallback((option: SubscribeOption) => {
		dispatch({type: 'update', field: 'subscribed', value: subscribeOptionsMap[option].value});
	}, []);

	return (
		<div className="field">
			<div className="field__label">Получение уведомлений на электронную почту</div>

			<SelectPopup
				noHeader
				trigger={(
					<TextFieldWithForwardedRef
						elementType="div"
						value={subscribeOptionsMap[String(value) as SubscribeOption].content}
						disabled={disabled}
					/>
				)}
			>
				<Select2
					onChange={handleChange}
					value={String(value)}
				>
					{Object.keys(subscribeOptionsMap).map((option: SubscribeOption) => (
						<SelectOption
							content={subscribeOptionsMap[option].content}
							inputType="radio"
							itemKey={option}
							key={option}
						/>
					))}
				</Select2>
			</SelectPopup>
		</div>
	);
};