import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

export type IDeleteTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Удаляет набор шаблонов нарушений
 */
export const makeDeleteTemplatesSetRequest = async (id: string) => {
	const response = await httpRequests.withToken.delete<IDeleteTemplatesSetResponse>(
		`problem-templates-sets/${id}`
	);
	return response.data;
};
