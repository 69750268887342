import {IState} from '@src/store/modules';
import checkLoaded from '@tehzor/tools/core/checkLoaded';
import * as types from './constants';
import IError from '@tehzor/tools/interfaces/IError';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetObjectStagesResponse, requestObjectStages} from '@src/api/backend/objectStages';
import {addErrorToast} from '@tehzor/ui-components';

export type IGetObjectStagesPayload = IGetObjectStagesResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetObjectStagesResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при получении стадий объекта'});
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает стадии объектов
 */
export const getObjectStages = () =>
	checkLoaded<IState, IGetObjectStagesResponse, ApiAction>(
		s => s.entities.objectStages,
		createApiAction<IGetObjectStagesResponse>(request, success, failure, requestObjectStages)
	);
