import './MobileHeader.less';
import classNames from 'classnames';
import {CSSProperties, ReactNode} from 'react';

interface IMobileHeaderProps {
	className?: string;
	style?: CSSProperties;
	title?: string;
	subTitle?: string;
	left?: ReactNode;
	right?: ReactNode;
	sectionsMenu?: ReactNode;
	titleButtons?: ReactNode;
}

const MobileHeader = (props: IMobileHeaderProps) => {
	const {className, style, title, subTitle, left, right, sectionsMenu, titleButtons} = props;

	return (
		<div className={classNames('m-header', className)} style={style}>
			<div className="m-header__main-row">
				<div className="m-header__left">{left}</div>
				<div className="m-header__title-section">
					<div className="m-header__title-wrap">
						<div
							className={classNames('m-header__title', {
								'm-header__title_small': !!subTitle
							})}
						>
							{title}
						</div>

						{subTitle && <div className="m-header__sub-title">{subTitle}</div>}
					</div>
					{titleButtons && <div className="m-header__title-buttons">{titleButtons}</div>}
				</div>
				<div className="m-header__right">{right}</div>
			</div>

			{!!sectionsMenu && <div className="m-header__sections-menu">{sectionsMenu}</div>}
		</div>
	);
};

export default MobileHeader;
