import {httpRequests} from '@tehzor/tools/api/httpRequests';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';

export type IAddCompanyResponse = ICompany;

/**
 * Добавляет компанию
 *
 * @param company данные компании
 */
export const makeCompanyAddRequest = async (company: ISavingCompany) => {
	const response = await httpRequests.withToken.post<IAddCompanyResponse>('companies', {
		...company
	});
	return response.data;
};
