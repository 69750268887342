import { ReactNode, useCallback, useState } from 'react';
import {Row} from '@tanstack/react-table';
import {LogDialog} from '../components/LogDialog';
import {IPreparedLogRecord} from '../utils/convertRecords';

export const useLogDialog = (): [ReactNode, (value: Row<IPreparedLogRecord>) => void] => {
	const [isOpen, setOpen] = useState<boolean>(false);
	const [row, setRow] = useState<Row<IPreparedLogRecord>>();

	const open = useCallback((value: Row<IPreparedLogRecord>) => {
		setRow(value);
		setOpen(true);
	}, []);

	const close = useCallback(() => {
		setOpen(false);
	}, []);

	const dialog = row ? (
		<LogDialog
			row={row}
			isOpen={isOpen}
			onClose={close}
		/>
	) : null;

	return [dialog, open];
};
