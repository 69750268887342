import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IDeleteTemplatesSetResponse,
	makeDeleteTemplatesSetRequest
} from '@src/api/backend/problemTemplatesSet';
import {addErrorToast} from '@tehzor/ui-components';

export type IDeleteTemplatesSetPayload = IDeleteTemplatesSetResponse;

const request = (id: string) => ({
	type: types.DELETE_REQUEST,
	payload: {id}
});

const success = (response: IDeleteTemplatesSetResponse) => ({
	type: types.DELETE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при удалении набора шаблонов нарушений'});
	}

	return {
		type: types.DELETE_FAILURE,
		payload: error
	};
};

/**
 * Изменяет набор шаблонов нарушений
 */
export const deleteTemplatesSet =
	(id: string): AppThunkAction<Promise<IDeleteTemplatesSetResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IDeleteTemplatesSetResponse>(
				() => request(id),
				success,
				failure,
				() => makeDeleteTemplatesSetRequest(id)
			)
		);
