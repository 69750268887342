import { useCallback, useContext } from 'react';
import {Select, Option} from '@tehzor/ui-components';
import {RoleDispatchCtx} from '../RolePage';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesForRole} from '@src/store/modules/entities/companies/selectors';

interface ICompanyFieldProps {
	roleId?: string;
	companyId?: string;
}

const CompanyField = ({roleId, companyId}: ICompanyFieldProps) => {
	const companies = useAppSelector(s => extractCompaniesForRole(s, roleId));
	const dispatch = useContext(RoleDispatchCtx);

	const onChange = useCallback((value: string | undefined) => {
		dispatch({type: 'update', field: 'companyId', value});
	}, []);

	return (
		<div className="field">
			<div className="field__label">Компания:</div>

			<Select
				className="role-page__field"
				allowClear
				menuMaxHeight="300px"
				value={companyId}
				onChange={onChange}
			>
				{companies.map(company => (
					<Option
						key={company.id}
						value={company.id}
					>
						{company.name}
					</Option>
				))}
			</Select>
		</div>
	);
};

export default CompanyField;
