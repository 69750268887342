import { memo } from 'react';
import {CellContext} from '@tanstack/react-table';
import IRole from '@src/interfaces/IRole';
import {format} from 'date-fns';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';

export const CreatedCell = memo(({row}: CellContext<IRole, IRole>) => {
	const createdAt = format(new Date(row.original.createdAt), dateTimeFormat);
	const createdBy = row.original.createdBy?.fullName;

	return (
		<>
			<span>{createdAt}</span>
			<br/>
			<span>{createdBy}</span>
		</>
	);
});
