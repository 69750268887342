import {memo} from 'react';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {
	CheckRecordStatusId,
	ICheckRecordStatus
} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import './CheckListIndicator.less';

interface CheckListIndicatorProps {
	statuses: INormalizedData<ICheckRecordStatus>;
	status: CheckRecordStatusId;
	count: number;
}

export const CheckListIndicator = memo(({statuses, status, count}: CheckListIndicatorProps) => {
	const statusEntity = statuses.byId[status];

	return (
		<div className="check-list-indicator">
			<i
				className="tz-check-list-16"
				style={{color: statusEntity?.color}}
			/>
			<div className="check-list-indicator__counter">{count}</div>
		</div>
	);
});
