import {CSSProperties, ReactElement, ReactNode, useCallback, useState} from 'react';
import './Tabs.less';
import DraggableMenu, {IDraggableMenuItemProps} from '../../navigation/DraggableMenu';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {getContent} from './utils/getContent';
import classNames from 'classnames';
import {convertClassNames} from '../../../utils/convertClassNames';

interface ITabsProps {
	className?:
		| string
		| {
				root?: string;
				links?: string;
				link?: string;
				fakeLink?: string;
				content?: string;
		  };
	style?: CSSProperties;
	links?: ReactElement<IDraggableMenuItemProps> | Array<ReactElement<IDraggableMenuItemProps>>;
	activeTab?: number;
	disabledTabs?: number[];
	defaultActiveTab?: number;
	manualMode?: boolean;
	children?: ReactNode;

	onActiveTabChange?: (index: number) => void;
}

const Tabs = (props: ITabsProps) => {
	const {
		className,
		style,
		links,
		activeTab,
		defaultActiveTab,
		disabledTabs,
		manualMode,
		children,
		onActiveTabChange
	} = props;

	const classes = convertClassNames(className);

	const [tabIndex, setTabIndex] = useState(activeTab || defaultActiveTab || 0);

	useUpdateEffect(() => {
		setTabIndex(activeTab || 0);
	}, [activeTab]);

	const handleTabChange = useCallback(
		(index: number) => {
			if (disabledTabs && disabledTabs.length) {
				if (disabledTabs.includes(index)) return;
			}
			if (activeTab === undefined) {
				setTabIndex(index);
			}
			if (onActiveTabChange) {
				onActiveTabChange(index);
			}
		},
		[activeTab, onActiveTabChange, disabledTabs]
	);

	const content = manualMode ? children : getContent(tabIndex, children);

	return (
		<div className={classNames('tabs', classes.root)} style={style}>
			<DraggableMenu
				className={classNames('tabs__menu', classes.links)}
				value={tabIndex}
				items={links}
				itemClassName={classNames('tabs__item', classes.link)}
				fakeItemClassName={classNames('tabs__fake-item', classes.fakeLink)}
				onSelect={handleTabChange}
			/>

			<div className={classNames('tabs__content', classes.content)}>{content}</div>
		</div>
	);
};

export default Tabs;
