import {SettingsCtx} from '@src/core/providers/SettingsProvider';
import {CellContext} from '@tanstack/react-table';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import {useContext} from 'react';

const icon = <i className="tz-settings-24 role-page__subs-table-settings-expander-icon" />;

export const SettingsExpanderCell = ({row}: CellContext<INotificationType, INotificationType>) => {
	const {toggleId, hasId} = useContext(SettingsCtx);

	return row.original.availableSettings && row.original.availableSettings.length ? (
		<IconButton
			className={classNames('role-page__subs-table-settings-expander-btn', {
				'role-page__subs-table-settings-expander-btn_open': hasId(row.original.id)
			})}
			type="transparent"
			onClick={() => toggleId(row.original.id)}
		>
			{icon}
		</IconButton>
	) : null;
};
