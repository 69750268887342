import {Button} from '@tehzor/ui-components';
import classNames from 'classnames';
import {CellContext} from '@tanstack/react-table';
import {IPreparedPermission} from '@src/pages/RolePage/components/permissions/PermissionsTable';

const icon = <i className="tz-simple-arrow-24 role-page__ptable-perm-expander-icon" />;

export const PermExpanderCell = ({row}: CellContext<IPreparedPermission, IPreparedPermission>) => {
	const original = row.original;

	return original.children && original.children.length ? (
		<Button
			onClick={row.getToggleExpandedHandler()}
			className={classNames('role-page__ptable-perm-expander-btn', {
				'role-page__ptable-perm-expander-btn_open': row.getIsExpanded()
			})}
			leftIcon={icon}
		/>
	) : (
		<div
			className={classNames('role-page__ptable-perm-expander-btn', {
				'role-page__ptable-perm-expander-btn_open': row.getIsExpanded()
			})}
		/>
	);
};
