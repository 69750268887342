import {LoadingPanel} from '@tehzor/ui-components';
import {ObjectPageHeader} from './components/ObjectPageHeader';
import {useParams} from 'react-router-dom';
import useAsync from 'react-use/lib/useAsync';
import {getObjects} from '@src/store/modules/entities/objects/actions';
import './ObjectPage.less';
import {getCustomizableFields} from '@src/actions/entities/customizableFields';
import {getObjectStages} from '@src/store/modules/entities/objectStages/actions';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getSpaceIndicatorsSets} from '@src/store/modules/entities/spaceIndicatorsSets/actions';
import {getTemplatesSets} from '@src/store/modules/entities/problemTemplatesSets/actions';
import {getObject} from '@src/store/modules/entities/object/actions';
import {EditableObjectCard} from './components/EditableObjectCard/EditableObjectCard';
import {getObjectIsLoading} from '@src/store/modules/entities/object/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';

const ObjectPage = () => {
	const {objectId} = useParams<{ objectId: string }>();
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(getObjectIsLoading);
	const loadingState = useAsync(async () => {
		if (objectId) {
			await dispatch(getObject(objectId));
		}
		const promises = [
			dispatch(getObjectStages()),
			dispatch(getObjects()),
			dispatch(getTemplatesSets()),
			dispatch(getCompanies()),
			dispatch(getCustomizableFields()),
			dispatch(getSpaceIndicatorsSets())
		];
		await Promise.all(promises);
	}, []);

	return (
		<LoadingPanel
			className="loading-panel_main"
			active={loadingState.loading || isLoading}
		>
			<div className="page-cont object-page">
				<ObjectPageHeader objectId={objectId}/>
				<EditableObjectCard objectId={objectId}/>
			</div>
		</LoadingPanel>
	);
};

export default ObjectPage;
