import IUser from '@tehzor/tools/interfaces/IUser';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import {calculateUserRolesUpdateDiff} from './calculateUserRolesUpdatesDiff';

// Функция для вычисления set и unset
export function calculateUserUpdatesDiff(original: IUser, updated: ISavingUser) {
	const set: Record<string, any> = {};
	const unset: Partial<Record<keyof ISavingUser, boolean | ISavingUser['roles']>> = {};

	// Исключаем roles из общего сравнения
	const updatedWithoutRoles = {...updated};
	delete updatedWithoutRoles.roles;

	Object.keys(updatedWithoutRoles).forEach(key => {
		const updatedValue = updatedWithoutRoles[key as keyof ISavingUser];
		const originalValue = original[key as keyof IUser];

		if (
			updatedValue !== undefined &&
			JSON.stringify(updatedValue) !== JSON.stringify(originalValue)
		) {
			set[key as keyof ISavingUser] = updatedValue;
		} else if (updatedValue === undefined) {
			unset[key as keyof ISavingUser] = true;
		}
	});

	if ('roles' in updated) {
		const {setRoles, unsetRoles} = calculateUserRolesUpdateDiff(
			original.roles || [],
			updated.roles || []
		);
		set.roles = setRoles || [];
		unset.roles = unsetRoles || [];
	}

	return {set, unset};
}
