import {toast, Id} from 'react-toastify';
import styles from '../BaseToast/BaseToast.module.less';
import {IconButton} from '../../buttons';
import {BaseToast} from '../BaseToast/BaseToast';
import {IAddToastProps} from '../interfaces';

export const addToast = (props: IAddToastProps): Id => {
	const {containerId, options, toastNode, ...toastProps} = props;
	const toastId = toast(toastNode || <BaseToast {...toastProps} />, {
		containerId,
		className: styles.layout,
		bodyClassName: styles.wrapper,
		closeButton: (
			<IconButton
				className={styles.buttonClose}
				onClick={() => toast.dismiss({id: toastId, containerId})}
			>
				<i className="tz-close-24" />
			</IconButton>
		),
		...options
	});
	return toastId;
};
