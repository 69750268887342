import {createAction} from '@reduxjs/toolkit';
import * as types from '@src/store/modules/entities/roles/constants';
import {makeRoleDeleteRequest} from '@src/api/backend/roles';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {getErrorMessage} from '../utils/getErrorMessage';
import {addErrorToast} from '@tehzor/ui-components';

const deleteRoleRequest = createAction(types.DELETE_ROLE_REQUEST);
const deleteRoleSuccess = createAction(types.DELETE_ROLE_SUCCESS, (id: string) => ({
	payload: {id}
}));
const deleteRoleFailure = createAction(types.DELETE_ROLE_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		const message = getErrorMessage(error);
		addErrorToast({title: `'Ошибка ${message}`});
	}
	return {
		payload: {...error}
	};
});

export const deleteRole = (roleId: string) =>
	createApiAction(
		deleteRoleRequest,
		() => deleteRoleSuccess(roleId),
		deleteRoleFailure,
		() => makeRoleDeleteRequest(roleId)
	);
