import { memo } from 'react';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {extractObjectStagesAsArray} from '@src/store/modules/entities/objectStages/selectors';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SingleSelect} from '@src/components/SingleSelect';
import {EditableFieldLabel} from '@tehzor/ui-components';

interface IStageFieldProps {
	value?: ObjectStageIds;
	onChange?: (value: ObjectStageIds) => void;
}

export const StageField = memo(({value, onChange}: IStageFieldProps) => {
	const stages = useAppSelector(extractObjectStagesAsArray);
	return (
		<div className="field">
			<EditableFieldLabel className="field__label">Стадия</EditableFieldLabel>
			<SingleSelect
				noHeader
				optionId={value}
				options={stages}
				onChange={onChange}
			/>
		</div>
	);
});
