import { memo, useCallback, useRef } from 'react';
import FilesUploader from '@tehzor/tools/components/FilesUploader';
import {AttachmentPreview, FilesPicker, InlineButton, NewImage} from '@tehzor/ui-components';
import IRawFile from '@tehzor/tools/interfaces/IRawFile';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import IFile from '@tehzor/tools/interfaces/IFile';

interface IImageFieldProps {
	image?: IFile;
	newImage?: IUploadingFile[];
	onNewImageChange?: (value: IUploadingFile[] | undefined) => void;
	onImageChange?: (value?: IFile) => void;
}

const imageTemplate = (
	<NewImage
		className="object-page__image-field-img"
		id=""
		name=""
		progress={0}
		status="waiting"
		type=""
		url=""
	/>
);

const deleteIcon = <i className="tz-delete object-page__image-field-delete-icon"/>;

export const ImageField = memo(({image, newImage, onNewImageChange, onImageChange}: IImageFieldProps) => {
	const uploader = useRef<any>();
	const picker = useRef<any>();

	const open = () => {
		if (picker.current) {
			picker.current.open();
		}
	};

	const upload = (files: IRawFile[]) => {
		if (uploader.current) {
			uploader.current.addFiles(files);
		}
	};

	const deleteImage = useCallback(() => {
		onImageChange?.(undefined);
	}, [onImageChange]);

	const handleChange = (value: IUploadingFile[]) => {
		if (image) {
			deleteImage();
		}
		onNewImageChange?.(value);
	};

	return (
		<div className="object-page__image-field">
			<div className="object-page__image-field-header">
				<div className="field__label object-page__image-field-label">Изображение</div>

				<InlineButton
					labelClassName="object-page__image-field-choose-btn-label"
					label={newImage?.length || image ? 'Заменить' : 'Выбрать'}
					type="accent"
					onClick={open}
				/>
			</div>

			{image && (
				<div className="object-page__image-field-img">
					<AttachmentPreview
						key={image.id}
						id={image.id}
						url={image.url}
						type={image.type}
					/>
					<div className="object-page__image-field-file-name">{image.name}</div>
					<div>
						<InlineButton
							className="new-image__btn"
							labelClassName="new-image__btn-label"
							label="Удалить"
							leftIcon={deleteIcon}
							type="cancel"
							onClick={deleteImage}
						/>
					</div>
				</div>
			)}
			<FilesUploader
				files={newImage}
				multiple={false}
				destination="objectImage"
				fileTemplate={imageTemplate}
				onFilesChange={handleChange}
				ref={uploader}
			/>
			<FilesPicker
				accept="image/jpeg,image/png,image/gif"
				multiple={false}
				onChange={upload}
				ref={picker}
			/>
		</div>
	);
});
