import {CellContext} from '@tanstack/react-table';
import {IPreparedPermission} from '@src/pages/RolePage/components/permissions/PermissionsTable';
import {memo, useContext} from 'react';
import {SettingsCtx} from '@src/core/providers/SettingsProvider';
import {SubComponent} from './SubComponent';

export const NameCell = memo(({row}: CellContext<IPreparedPermission, IPreparedPermission>) => {
	const {hasId} = useContext(SettingsCtx);

	return (
		<div>
			{row.original.name} <span className="role-page__table-id">({row.original.id})</span>
			{!!row.original.availableRestrictions?.length && hasId(row.original.id) && (
				<SubComponent original={row.original} />
			)}
		</div>
	);
});
