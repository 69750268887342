import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import IUser from '@tehzor/tools/interfaces/IUser';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddUserResponse = IUser;

/**
 * Добавляет пользователя
 *
 * @param user данные пользователя
 */
export const makeUserAddRequest = async (user: ISavingUser) => {
	const response = await httpRequests.withToken.post<IAddUserResponse>('users', {
		...user
	});
	return response.data;
};
