import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import {IEditUserResponse, makeUserEditRequest} from '@src/api/backend/user';
import {addErrorToast} from '@tehzor/ui-components';

export type IEditUserPayload = IEditUserResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditUserResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при изменении пользователя'});
	}
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет пользователя
 *
 * @param userId id пользователя
 * @param data данные пользователя
 */
export const editUser = (userId: string, data: ISavingUser) =>
	createApiAction<IEditUserResponse>(request, success, failure, () =>
		makeUserEditRequest(userId, data)
	);
