import { Dispatch, useCallback } from 'react';
import * as React from 'react';
import {EditableFieldLabel, TextField} from '@tehzor/ui-components';
import {ElementPropsType} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';
import {IEditableEntityAction} from '@tehzor/tools/core/states/editableEntityState';
import {ElementType} from '@tehzor/ui-components/src/components/inputs/TextField';

interface IEditableTextFieldProps<S, E> {
	className?: string;
	style?: React.CSSProperties;
	label?: string;
	value: string | undefined;
	fieldName: keyof S;
	editingDispatch: Dispatch<IEditableEntityAction<S, E>>;
	required?: boolean;
	disabled?: boolean;
	hasError?: boolean;
	errorMessage?: string;
	elementType?: ElementType;
	elementProps?: ElementPropsType;
}

const EditableTextField = <S, E>({
	className,
	style,
	label,
	value,
	fieldName,
	editingDispatch,
	required,
	disabled,
	hasError,
	errorMessage,
	elementType = 'input',
	elementProps
}: IEditableTextFieldProps<S, E>) => {
	const handleChange = useCallback(
		(v: string) => {
			editingDispatch({type: 'update', field: fieldName, value: v});
			if (required) {
				editingDispatch({type: 'update-error', field: fieldName});
			}
		},
		[required]
	);

	return (
		<div
			className={className}
			style={style}
		>
			<EditableFieldLabel>{label}</EditableFieldLabel>

			<TextField
				value={value}
				elementType={elementType}
				elementProps={elementProps}
				error={required && hasError ? errorMessage : undefined}
				disabled={disabled}
				onChange={handleChange}
			/>
		</div>
	);
};

export default EditableTextField;
