import IObjectStage, {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import {dateFormat} from '@tehzor/tools/utils/dateFormats';
import {DateRangePicker, TextFieldWithForwardedRef} from '@tehzor/ui-components';
import {makeDateRangeLabel} from '@tehzor/ui-components/src/components/dates/DateRangePicker';
import { useCallback } from 'react';

interface IStageFieldProps {
	className?: string;
	onChange: (from: Date, to: Date, stageId: ObjectStageIds) => void;
	onClear: (stageId: ObjectStageIds) => void;
	stage: IObjectStage;
	from?: number;
	to?: number;
	error?: boolean;
}

const icon = <i className="tz-calendar-20"/>;

export const StageField = (
	{className, onChange, onClear, stage, from, to, error}: IStageFieldProps
) => {
	const dateFrom = from ? new Date(from) : undefined;
	const dateTo = to ? new Date(to) : undefined;

	const handleChange = useCallback((from: Date, to: Date) => {
		onChange(from, to, stage.id);
	}, [stage.id, onChange]);

	const handleClear = useCallback(() => {
		onClear(stage.id);
	}, [stage.id, onClear]);

	return (
		<div
			className={className}
			key={stage.id}
		>
			<div className="field__label">
				{stage.name}
			</div>
			<DateRangePicker
				trigger={props => (
					<TextFieldWithForwardedRef
						elementType="div"
						value={makeDateRangeLabel(undefined, dateFrom, dateTo)}
						cleanable={!!(from || to)}
						onClearClick={handleClear}
						error={error ? 'Укажите срок устранения' : undefined}
						icon={icon}
						onClick={props?.toggle}
						ref={props?.ref}
					/>
				)}
				valueFrom={dateFrom}
				valueTo={dateTo}
				dateFormat={dateFormat}
				useApplyButton
				onChange={handleChange}
			/>
		</div>
	);
};