import { useCallback } from 'react';
import {IconButton} from '@tehzor/ui-components';
import {CellProps} from 'react-table';
import IUser from '@tehzor/tools/interfaces/IUser';
import {useEditableCompanyDispatchCtx} from '@src/core/hooks/states/useEditableCompanyState';

export const DeleteCell = ({row}: CellProps<IUser>) => {
	const editingDispatch = useEditableCompanyDispatchCtx();

	const handleClick = useCallback(() => {
		editingDispatch({type: 'delete-array-item', field: 'employees', index: [row.index]});
	}, [row.index]);

	return (
		<IconButton
			type="inline-blue-accent"
			onClick={handleClick}
		>
			<i className="tz-delete"/>
		</IconButton>
	);
};
