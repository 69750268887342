import {httpRequests} from '@tehzor/tools/api/httpRequests';

export interface IDeleteDevicesResponse {
	success: boolean;
}

export const requestDeleteDevice = async (userId: string, deviceId: string) => {
	const params = {
		userId
	};
	const response = await httpRequests.withToken.delete<IDeleteDevicesResponse>(
		`devices/${deviceId}`,
		{params}
	);
	return response.data;
};
