import ISavingUser from '@tehzor/tools/interfaces/users/ISavingUser';
import IUser from '@tehzor/tools/interfaces/IUser';
import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {calculateUserUpdatesDiff} from '@src/utils/calculateUserUpdatesDiff';

export type IEditUserResponse = IUser;

export async function fetchUser(userId: string): Promise<IUser> {
	const response = await httpRequests.withToken.get<IUser>(`users/${userId}`);
	return response.data;
}

/**
 * Изменяет пользователя
 *
 * @param userId id пользователя
 * @param updatedData данные пользователя
 */

export async function makeUserEditRequest(userId: string, updatedData: ISavingUser) {
	const originalData = await fetchUser(userId);
	const {set, unset} = calculateUserUpdatesDiff(originalData, updatedData);
	const response = await httpRequests.withToken.put<IUser>(`users/${userId}`, {set, unset, subscribed: updatedData.subscribed});
	return response.data;
}
