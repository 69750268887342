import {IObjectManager} from '@tehzor/tools/interfaces/objects/IObject';
import { memo, ReactNode, useCallback } from 'react';
import {FieldWithLabel} from './fieldWithLabel/fieldWithLabel';

interface IManagerFields {
	title?: ReactNode;
	value?: IObjectManager;
	hasError?: string;
	onChange?: (value: IObjectManager) => void;
}

export const ManagerFields = memo((props: IManagerFields) => {
	const {
		title,
		value,
		hasError,
		onChange
	} = props;
	const handleNameChange = useCallback((fullName: string) => {
		onChange?.({...value, fullName});
	}, [onChange, value]);

	const handlePhoneChange = useCallback((phone: string) => {
		onChange?.({...value, phone});
	}, [onChange, value]);

	return (
		<div className="object-page__manager-field">
			<div className="field__label object-page__manager-field-title">
				{title}
			</div>
			<div className="object-page__manager-field-wrap">
				<div className="object-page__manager-field-wrap__fullName">
					<FieldWithLabel
						value={value?.fullName}
						label="ФИО"
						onChange={handleNameChange}
					/>
				</div>
				<div className="object-page__manager-field-wrap__phone">
					<FieldWithLabel
						value={value?.phone}
						label="Телефон"
						onChange={handlePhoneChange}
						hasError={hasError}
					/>
				</div>
			</div>
		</div>
	);
});