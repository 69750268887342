import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {createApiAction} from '@src/store/middlewares/api';
import {
	IAddTemplatesSetResponse,
	IEditTemplatesSetResponse
} from '@src/api/backend/problemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';
import {makeCopyTemplatesSetRequest} from '@src/api/backend/problemTemplatesSet/copy';
import {addErrorToast, addSuccessToast} from '@tehzor/ui-components';

export type IEditTemplatesSetPayload = IEditTemplatesSetResponse;

const request = (data: ISavingProblemTemplatesSet) => ({
	type: types.ADD_REQUEST,
	payload: data
});

const success = (response: IAddTemplatesSetResponse) => {
	addSuccessToast({title: 'Набор шаблонов был успешно скопирован'});
	return {
		type: types.ADD_SUCCESS,
		payload: response
	};
};

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при копировании набора шаблонов нарушений'});
	}

	return {
		type: types.ADD_FAILURE,
		payload: error
	};
};

/**
 * Копирует набор шаблонов нарушений
 */
export const copyTemplatesSet =
	(
		id: string,
		data: Pick<ISavingProblemTemplatesSet, 'companies' | 'shared'>
	): AppThunkAction<Promise<IAddTemplatesSetResponse>> =>
	dispatch =>
		dispatch(
			createApiAction<IAddTemplatesSetResponse>(
				() => request(data),
				success,
				failure,
				() => makeCopyTemplatesSetRequest(id, data)
			)
		);
