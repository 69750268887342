import { StrictMode } from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {PersistGate} from 'redux-persist/es/integration/react';
import {App} from '@src/components/App';
import {Persistor} from 'redux-persist/es/types';
import {EnhancedStore} from '@reduxjs/toolkit';
import {IState} from '@src/store/modules';

export interface IRootProps {
	store: EnhancedStore<IState>;
	persistor: Persistor;
}

const Root = ({store, persistor}: IRootProps) => (
	<StrictMode>
		<PersistGate
			loading={null}
			persistor={persistor}
		>
			<Provider store={store}>
				<BrowserRouter>
					<App/>
				</BrowserRouter>
			</Provider>
		</PersistGate>
	</StrictMode>
);

export default Root;
