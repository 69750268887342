import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IArchivedObject from '@tehzor/tools/interfaces/objects/IArchivedObject';

export type IArchiveObjectResponse = IArchivedObject;

/**
 * Архивирует объект
 *
 * @param objectId id объекта
 */

export const makeObjectArchiveRequest = async (objectId: string) => {
	const response = await httpRequests.withToken.post<IArchiveObjectResponse>('objects/archive', {
		objectId
	});
	return response.data;
};
