import {ToastOptions} from 'react-toastify';
import {ReactNode} from 'react';

export enum ToastsContainersIds {
	MAIN_STACKED = 'main_stacked'
}

type IBaseToastOptions = Omit<ToastOptions, 'containerId'>;

export interface IAddToastProps {
	containerId: string;
	icon?: ReactNode;
	title?: string;
	description?: string;
	links?: ReactNode;
	actions?: ReactNode;
	toastNode?: ReactNode;
	options?: IBaseToastOptions;
}

export type ITypeToastProps = Omit<IAddToastProps, 'containerId'>;
