import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';

export type IGetTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Возвращает набор шаблонов нарушений
 * @param id идентификатор набора шаблонов нарушений
 */
export const requestTemplatesSet = async (id: string) => {
	const response = await httpRequests.withToken.get<IGetTemplatesSetResponse>(
		`problem-templates-sets/${id}`
	);
	return response.data;
};
