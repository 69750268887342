import {useCallback, useContext} from 'react';
import * as React from 'react';
import {Checkbox} from '@tehzor/ui-components';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import {SettingsValueCtx} from './FieldsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';
import IObjectFieldsSettings from '@tehzor/tools/interfaces/objects/IObjectFieldsSettings';
import {CellContext} from '@tanstack/react-table';
import {SettingsKeyCtx} from './providers/SettingsKeyProvider';

export const IsAvailableCell = ({row}: CellContext<ICustomizableField, ICustomizableField>) => {
	const settingKey = useContext(SettingsKeyCtx);
	const value = useContext(SettingsValueCtx);
	const dispatch = useAppDispatch();

	const isChecked = value?.some(item => item.fieldId === row.original.id);

	const onChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (event.target.checked) {
				dispatch(
					objectActions.addFieldSetting({
						fieldId: row.original.id,
						key: settingKey as keyof IObjectFieldsSettings
					})
				);
			} else {
				dispatch(
					objectActions.deleteFieldSetting({
						fieldId: row.original.id,
						key: settingKey as keyof IObjectFieldsSettings
					})
				);
			}
		},
		[dispatch, row.original.id, settingKey]
	);

	return (
		<Checkbox
			className={{
				root: 'object-page__contractors-checkbox',
				icon: 'object-page__contractors-checkbox-input'
			}}
			checked={isChecked}
			onChange={onChange}
		/>
	);
};
