import { memo } from 'react';
import {CellContext} from '@tanstack/react-table';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';

export const CompanyNameCell = memo(({row}: CellContext<IObject, IObject>) => {
	const companies = useAppSelector(extractCompaniesAsArray);
	const companyName = companies.find(c => c.id === row.original.companyId)?.name;
	return <>{companyName}</>;
});
