import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetObjectResponse = IObject;

/**
 * Получает объект
 *
 * @param objectId id объекта
 */

export const requestObject = async (objectId: string) => {
	const response = await httpRequests.withToken.get<IGetObjectResponse>(`objects/${objectId}`);
	return response.data;
};
