import {httpRequests} from '@tehzor/tools/api/httpRequests';
import IUser from '@tehzor/tools/interfaces/IUser';

export type IDeleteUserResponse = IUser | {success: true};

/**
 * Удаляет пользователя
 *
 * @param userId id пользователя
 */
export const makeUserDeleteRequest = async (userId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteUserResponse>(`users/${userId}`);
	return response.data;
};
