import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export type IDeleteObjectResponse = IObject;

/**
 * Удаляет объект
 *
 * @param objectId id объекта
 */
export const makeObjectDeleteRequest = async (objectId: string) => {
	const response = await httpRequests.withToken.delete<IDeleteObjectResponse>(
		`/objects/${objectId}`
	);
	return response.data;
};
