import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	getRegulatoryStandards,
	updateRegulatoryStandards
} from '@src/store/modules/entities/regulatoryStandards/actions';
import {Button, LoadingPanel} from '@tehzor/ui-components';
import './RegStandardsPage.less';

import {useAsync} from 'react-use';
import {RegStandardsTable} from './components/RegStandardsTable/RegStandardsTable';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {hasPermission} from '@tehzor/tools/utils/findPermission';
import {UserRoleScopes} from '@tehzor/tools/interfaces/IUser';

const reloadIcon = <i className="tz-reload-24"/>;

export const RegStandardsPage = () => {
	useAppHeader(
		{
			title: 'Справочник СП'
		},
		[]
	);
	const dispatch = useAppDispatch();
	const userRoles = useAppSelector(extractUserRoles);

	const loadingState = useAsync(async () => {
		await dispatch(getRegulatoryStandards());
	}, []);

	const [updateRegStandardsState, handleUpdateRegStandards] = useAsyncFn(async () => {
		await dispatch(updateRegulatoryStandards());
	});

	return (
		<LoadingPanel
			className="loading-panel_main"
			active={loadingState.loading || updateRegStandardsState.loading}
		>
			<div className="page-cont">
				<div className="container"/>
				<div className="reg-standards-page__button-block">
					{hasPermission(userRoles, 'regulatoryStandardsUpdate', UserRoleScopes.ALL) && (
						<Button
							type="accent-blue"
							label="Обновить"
							leftIcon={reloadIcon}
							onClick={handleUpdateRegStandards}
						/>
					)}
				</div>
				<RegStandardsTable/>
			</div>
		</LoadingPanel>
	);
};
