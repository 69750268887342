import {CSSProperties, forwardRef, Ref, useCallback, useMemo} from 'react';
import ButtonBase from '../ButtonBase';
import '../MobileFilterButton/MobileFilterButton.less';
import classNames from 'classnames';
import {TreeButtonCondition} from './interfaces';
import {ITwoWayTreeItem} from '@tehzor/tools/interfaces/ITwoWayTreeItem';
import {ITreeDataItem} from '../../Tree';
import {getButtonCondition} from './utils/getButtonCondition';

export interface IMobileFilterButtonProps<T extends ITreeDataItem = ITreeDataItem> {
	item: ITwoWayTreeItem<T>;
	selected: string[];
	className?: string;
	style?: CSSProperties;
	disabled?: boolean;
	onClick: (item: ITwoWayTreeItem<T>, condition: TreeButtonCondition) => void;
}

export const MobileTreeFilterButton = forwardRef(
	(
		props: IMobileFilterButtonProps,
		ref?: Ref<HTMLDivElement | HTMLAnchorElement | HTMLButtonElement>
	) => {
		const {item, selected, className, style, disabled, onClick} = props;

		const buttonCondition = useMemo(() => getButtonCondition(item, selected), [item, selected]);
		const clickHandler = useCallback(() => {
			if (disabled) return;
			onClick(item, buttonCondition);
		}, [buttonCondition, disabled, item, onClick]);

		return (
			<ButtonBase
				className={classNames(
					{
						'mobile-filter-button_active':
							buttonCondition === TreeButtonCondition.ACTIVE,
						'mobile-filter-button_partial_active':
							buttonCondition === TreeButtonCondition.PARTIAL_ACTIVE,
						'mobile-filter-button_inactive':
							buttonCondition === TreeButtonCondition.INACTIVE
					},
					className
				)}
				style={style}
				label={item.content}
				rightIcon={
					buttonCondition !== TreeButtonCondition.INACTIVE && (
						<i
							className={classNames(
								'tz-close-16',
								'mobile-filter-button__close-icon'
							)}
						/>
					)
				}
				disabled={disabled}
				classNamePrefix="mobile-filter-button"
				outerTagType="button"
				onClick={clickHandler}
				ref={ref}
			/>
		);
	}
);
