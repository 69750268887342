import {ReactNode, useCallback, useRef, useState} from 'react';
import './AuthForm.less';
import classNames from 'classnames';
import {Checkbox, TextField} from '../../inputs';
import {Button, InlineButton} from '../../buttons';
import LoadingPanel from '../../LoadingPanel';
import {ProviderButton} from './components/ProviderButton';
import {IProvider} from './interfaces/IProvider';

const emailIcon = <i className="auth-form__form-icons tz-mail-20" />;
const passwordVisibleIcon = <i className="auth-form__form-icons tz-password-visible-20" />;
const passwordHiddenIcon = <i className="auth-form__form-icons tz-password-hidden-20" />;

export interface IAuthFormProps {
	className?: string;
	lngSelect?: ReactNode;
	providers?: IProvider[];
	isRemember?: boolean;
	error?: string;
	title?: string;
	emailLabel?: string;
	passwordLabel?: string;
	recoveryLabel?: string;
	rememberLabel?: string;
	submitLabel?: string;
	registerLabel?: string;
	orText?: string;

	onLogin?: (email: string, password: string) => Promise<void>;
	onRemember?: (isRemember: boolean) => void;
	onRecovery?: () => void;
	onProviderClick?: (key: string) => void;
	onRegister?: () => void;
}

export const AuthForm = (props: IAuthFormProps) => {
	const {
		className,
		lngSelect,
		providers,
		isRemember = false,
		error,
		title,
		emailLabel,
		passwordLabel,
		recoveryLabel,
		submitLabel,
		rememberLabel,
		registerLabel,
		orText,
		onLogin,
		onRemember,
		onRecovery,
		onProviderClick,
		onRegister
	} = props;

	const classes = classNames('auth-form', className);

	const [isBusy, setBusy] = useState(false);
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);

	const handleLogin = useCallback(async () => {
		const email = emailRef.current;
		const password = passwordRef.current;

		if (!onLogin || !email || !password) {
			return;
		}

		const emailValue = email.value.trim();
		if (!emailValue) {
			email.focus();
			return;
		}

		const passwordValue = password.value;
		if (!passwordValue) {
			password.focus();
			return;
		}

		email.blur();
		password.blur();

		setBusy(true);
		try {
			await onLogin(emailValue, passwordValue);
		} finally {
			setBusy(false);
		}
	}, [onLogin]);

	const handleRemember = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (onRemember) {
				onRemember(event.currentTarget.checked);
			}
		},
		[onRemember]
	);

	const handleRecovery = useCallback(() => {
		if (onRecovery) {
			onRecovery();
		}
	}, [onRecovery]);

	const handleRegistration = useCallback(() => {
		if (onRegister) {
			onRegister();
		}
	}, [onRegister]);

	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === 'Enter') {
				event.preventDefault();
				void handleLogin();
			}
		},
		[handleLogin]
	);

	const emailProps = {
		id: 'login-email',
		type: 'email',
		onKeyDown: handleKeyDown
	} as React.InputHTMLAttributes<HTMLInputElement>;

	const passwordProps = {
		id: 'login-password',
		onKeyDown: handleKeyDown
	} as React.InputHTMLAttributes<HTMLInputElement>;

	return (
		<LoadingPanel
			className="auth-form__loading-panel"
			active={isBusy}
		>
			<div className={classes}>
				<div
					className={classNames('auth-form__header', {
						'auth-form__header_center': !lngSelect
					})}
				>
					<div className="auth-form__title">{title}</div>

					{lngSelect !== undefined && (
						<div className="auth-form__lng-select">{lngSelect}</div>
					)}
				</div>
				<form className="auth-form__form">
					<div className="auth-form__form-email">
						<label
							className="auth-form__form-label"
							htmlFor={emailProps.id}
						>
							{emailLabel}:
						</label>
						<TextField
							elementRef={emailRef}
							elementProps={emailProps}
							elementType="input"
							icon={emailIcon}
						/>
					</div>
					<div className="auth-form__form-password">
						<label
							className="auth-form__form-label"
							htmlFor={passwordProps.id}
						>
							{passwordLabel}:
						</label>
						<TextField
							elementRef={passwordRef}
							elementProps={passwordProps}
							elementType="input"
							icon={passwordVisibleIcon}
							hiddenIcon={passwordHiddenIcon}
							hideable
							isHidden
						/>
					</div>
					{onRemember && (
						<div className="auth-form__remember">
							<Checkbox
								checked={isRemember}
								onChange={handleRemember}
							>
								{rememberLabel}
							</Checkbox>
						</div>
					)}
				</form>
				{onRecovery && (
					<div className="auth-form__recovery">
						<InlineButton
							labelClassName="auth-form__recovery-btn-label"
							label={recoveryLabel}
							type="accent"
							onClick={handleRecovery}
						/>
					</div>
				)}
				{error && (
					<div className="auth-form__error">
						<i className="tz-circle-warning-32 auth-form__error-icon" />
						{error}
					</div>
				)}
				<Button
					className="auth-form__submit"
					label={submitLabel}
					type="accent-blue"
					onClick={handleLogin}
				/>
				{(onRegister || providers) && (
					<div className="auth-form__divider">
						<div className="auth-form__divider-text">{orText}</div>
					</div>
				)}
				{providers && (
					<div className="auth-form__providers">
						{providers.map(provider => (
							<ProviderButton
								{...provider}
								onClick={onProviderClick}
							/>
						))}
					</div>
				)}
				{onRegister && (
					<div className="auth-form__registration">
						<InlineButton
							className="auth-form__registration-btn"
							labelClassName="auth-form__registration-btn-label"
							label={registerLabel}
							type="accent"
							onClick={handleRegistration}
						/>
					</div>
				)}
			</div>
		</LoadingPanel>
	);
};

AuthForm.displayName = 'AuthForm';
