import CommonTextField from './CommonTextField';
import ActivatedField from './ActivatedField';
import ProfilesField from './ProfilesField';
import {IEditableUser} from '../../utils/editableUserState';
import IUser from '@tehzor/tools/interfaces/IUser';
import ColorField from './ColorField';
import {SubscribeField} from './SubscribeField';

interface IFieldsProps {
	user?: IUser;
	editableUser: IEditableUser;
}

const passwordInputProps = {type: 'password', autoComplete: 'new-password'};
const emailInputProps = {type: 'email'};

const Fields = ({user, editableUser}: IFieldsProps) => {
	const isExternalUser = !!(user?.profiles && Object.keys(user.profiles).length);

	const deleted = user?.deleted;

	return (
		<>
			<div className="fields-grid">
				<CommonTextField
					label="Фамилия"
					value={editableUser.lastName}
					field="lastName"
					disabled={deleted || isExternalUser}
				/>

				<CommonTextField
					label="Имя"
					value={editableUser.firstName}
					field="firstName"
					disabled={deleted || isExternalUser}
				/>

				<CommonTextField
					label="Отчество"
					value={editableUser.middleName}
					field="middleName"
					disabled={deleted || isExternalUser}
				/>

				<CommonTextField
					label="Полное имя"
					value={editableUser.fullName}
					field="fullName"
					disabled={deleted || isExternalUser}
				/>

				<CommonTextField
					label="Отображаемое имя"
					value={editableUser.displayName}
					field="displayName"
					disabled={deleted || isExternalUser}
				/>

				<CommonTextField
					label="Должность"
					value={editableUser.position}
					field="position"
					disabled={deleted}
				/>

				<CommonTextField
					label="Email"
					value={editableUser.email}
					field="email"
					disabled={deleted || isExternalUser}
					inputProps={emailInputProps}
				/>

				<CommonTextField
					label="Новый пароль"
					value={editableUser.password}
					field="password"
					disabled={deleted || isExternalUser}
					inputProps={passwordInputProps}
				/>

				<ColorField
					value={editableUser.color}
					disabled={deleted}
				/>

				<ActivatedField
					value={editableUser.activated}
					disabled={deleted}
				/>

				<SubscribeField
					value={editableUser.subscribed}
					disabled={deleted}
				/>
			</div>

			{user && <ProfilesField value={user.profiles}/>}
		</>
	);
};

export default Fields;
