import { memo } from 'react';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {useCompanyBreadcrumbs} from '@src/core/hooks/breadcrumbs/useCompanyBreadcrumbs';
import Actions from './Actions';

interface IPageBreadcrumbsProps {
	companyId: string;
}

const PageBreadcrumbs = ({companyId}: IPageBreadcrumbsProps) => {
	const breadcrumbs = useCompanyBreadcrumbs(companyId);

	return (
		<AppBreadcrumbs items={breadcrumbs}>
			<Actions companyId={companyId}/>
		</AppBreadcrumbs>
	);
};

export default memo(PageBreadcrumbs);
