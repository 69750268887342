import {SidebarMenu, SidebarMenuItem, SidebarMenuSeparator} from '@tehzor/ui-components';
import {useNavPermissions} from '@src/core/hooks/useNavPermissions';
import {usePageKey} from '../utils/usePageKey';
import {ISidebarMenuItemProps} from '@tehzor/ui-components/src/components/sidebar/SidebarMenuItem/SidebarMenuItem';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IAppSidebarMenuProps {
	type: ISidebarMenuItemProps['type'];

	closeSidebar?(): Promise<void>;
}

const AppSidebarMenu = ({type, closeSidebar}: IAppSidebarMenuProps) => {
	const permissions = useNavPermissions();
	const pageKey = usePageKey();
	const {pushPath} = useChangePath();

	const [, handleClick] = useAsyncFn(
		async (path?: string) => {
			if (path) {
				if (closeSidebar) {
					await closeSidebar();
				}
				pushPath(`/${path}`);
			}
		},
		[closeSidebar]
	);

	return (
		<SidebarMenu>
			{permissions.companiesView && (
				<SidebarMenuItem
					icon={<i className="tz-company-28"/>}
					label="Компании"
					type={type}
					active={pageKey === 'companies'}
					data="companies"
					onClick={handleClick}
				/>
			)}
			{permissions.objectsView && (
				<SidebarMenuItem
					icon={<i className="tz-object-28"/>}
					label="Объекты"
					type={type}
					active={pageKey === 'objects'}
					data="objects"
					onClick={handleClick}
				/>
			)}
			{(permissions.usersView || permissions.rolesView) && <SidebarMenuSeparator type={type}/>}
			{permissions.usersView && (
				<SidebarMenuItem
					icon={<i className="tz-user-28"/>}
					label="Пользователи"
					type={type}
					active={pageKey === 'users'}
					data="users"
					onClick={handleClick}
				/>
			)}
			{permissions.rolesView && (
				<SidebarMenuItem
					icon={<i className="tz-role-28"/>}
					label="Роли"
					type={type}
					active={pageKey === 'roles'}
					data="roles"
					onClick={handleClick}
				/>
			)}
			{permissions.regulatoryStandardsView && <SidebarMenuSeparator type={type}/>}
			{permissions.problemTemplatesSetsView && (
				<SidebarMenuItem
					icon={<i className="tz-problem-28"/>}
					label="Наборы шаблонов нарушений"
					type={type}
					active={pageKey === 'problem-templates-sets'}
					data="problem-templates-sets"
					onClick={handleClick}
				/>
			)}
			{permissions.regulatoryStandardsView && (
				<SidebarMenuItem
					icon={<i className="tz-reg-standard-28"/>}
					label="Справочник СП"
					type={type}
					active={pageKey === 'reg-standards'}
					data="reg-standards"
					onClick={handleClick}
				/>
			)}
			{permissions.actionsLogView && <SidebarMenuSeparator type={type}/>}
			{permissions.actionsLogView && (
				<SidebarMenuItem
					icon={<i className="tz-log-28"/>}
					label="Лог действий"
					type={type}
					active={pageKey === 'logs'}
					data="logs"
					onClick={handleClick}
				/>
			)}
		</SidebarMenu>
	);
};

export default AppSidebarMenu;
