import { memo, useMemo, useState } from 'react';
import {FilterButton, SelectPopup, SelectSearch, TreeSelect} from '@tehzor/ui-components';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

import {makeObjectsTree} from '@src/utils/makeObjectsTree';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {treeFilter} from '@tehzor/ui-components/src/components/inputs/select/SelectSearch';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';

interface IObjectsSelectProps {
	objects: IObject[];
	companies?: ICompany[];
	objectsId?: string[];
	changeFilter: (value: string[]) => void;
}

const LABEL = 'Объекты';

export const ObjectsSelect = memo((props: IObjectsSelectProps) => {
	const {companies, objects, objectsId, changeFilter} = props;
	const [search, setSearch] = useState('');
	const [selectedObjects, setSelectedObjects] = useState(objectsId);
	const [expandedObjects, setExpandedObjects] = useState<string[] | undefined>([]);
	const treeData = useMemo(() => objects && makeObjectsTree(objects, companies), [objects, companies]);
	const {filteredData, expanded} = useMemo(() => treeFilter(treeData, 'content', search), [search, treeData]);

	useUpdateEffect(() => {
		setExpandedObjects(expanded?.map(item => item.id));
	}, [expanded]);

	useUpdateEffect(() => {
		setExpandedObjects([]);
		setSelectedObjects(objectsId);
	}, [objectsId]);

	const handleApply = () => {
		if (selectedObjects) {
			changeFilter(selectedObjects);
			setSearch('');
		}
	};

	const handleExpand = (v?: string[]) => {
		setExpandedObjects(v);
	};

	const handleClear = () => {
		setSelectedObjects([]);
		setSearch('');
	};

	const handleCancel = () => {
		setSearch('');
	};

	const handleFullClear = () => {
		setSelectedObjects([]);
		changeFilter([]);
	};

	const objectNamesList = objectsId && objects.filter(obj => objectsId?.includes(obj.id)).map(obj => obj.name);

	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			onClear={handleClear}
			clearButton={!!selectedObjects?.length}
			count={selectedObjects?.length}
			search={(
				<SelectSearch
					value={search}
					onChange={setSearch}
					type="popup"
				/>
			)}
			trigger={(
				<FilterButton
					label={makeFilterLabel(LABEL, objectNamesList)}
					onClear={handleFullClear}
					active={Boolean(objectsId?.length)}
				/>
			)}
		>
			<TreeSelect
				data={filteredData}
				multiple
				value={selectedObjects}
				onChange={setSelectedObjects}
				expandedValue={expandedObjects}
				onExpand={handleExpand}
			/>
		</SelectPopup>
	);
});
