import { memo } from 'react';
import {EditableFieldLabel} from '@tehzor/ui-components';
import {getObjectsForParent} from '@src/selectors/entities/objects';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {SingleSelect} from '@src/components/SingleSelect';

interface IParentFieldProps {
	objectId?: string;
	value?: string;
	companyId?: string;
	onChange?: (value: string) => void;
}

export const ParentField = memo(({value, objectId, companyId, onChange}: IParentFieldProps) => {
	const objects = useAppSelector(s => getObjectsForParent(s, {objectId, companyId}));
	return (
		<div className="field">
			<EditableFieldLabel className="field__label">Родительский объект</EditableFieldLabel>
			<SingleSelect
				optionId={value}
				onChange={onChange}
				options={objects}
				cleanable
			/>
		</div>
	);
});
