import {createSelector} from 'reselect';
import {getInitialStateForPage} from './reducers';
import {IState} from '@src/store/modules';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {
	IPreparedProblemTemplate,
	IPreparedProblemTemplatesSet
} from '@tehzor/tools/interfaces/problemTemplatesSets/IPreparedProblemTemplatesSet';
import {ICompaniesState} from '@src/store/modules/entities/companies/reducers';
import {dateTimeCommaSeparatedFormat} from '@tehzor/tools/utils/dateFormats';
import {format} from 'date-fns';
import {IProblemTemplate} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplate';
import {IBriefCompany} from '@tehzor/tools/interfaces/companies/IBriefCompany';

/**
 * Возвращает наборы шаблонов нарушений для конкретного объекта
 */
export const extractTemplatesSetsData = createSelector(
	(state: IState) => state.entities.problemTemplatesSets,
	data => data.all || getInitialStateForPage()
);

/**
 * Возвращает наборы шаблонов нарушений в виде массива
 */
export const extractTemplatesSetsAsArray = createSelector([extractTemplatesSetsData], data =>
	data.allIds.map(id => data.byId[id])
);

const prepareTemplate = (item: IProblemTemplate): IPreparedProblemTemplate => ({
	id: item.id,
	description: item.description,
	categoryId: item.categoryId || '',
	reason: item.reason
});

const prepareTemplatesSet = (
	item: IProblemTemplatesSet,
	companies: ICompaniesState
): IPreparedProblemTemplatesSet => ({
	id: item.id,
	name: item.name,
	data: item.data.map(prepareTemplate),
	shared: item.shared || false,
	companies: (item.companies || []).reduce(
		(acc: string[], companyId) =>
			companies.byId[companyId] ? acc.concat(companies.byId[companyId].name) : acc,
		[]
	),
	companiesAsMap: (item.companies || []).reduce<IBriefCompany[]>(
		(acc, companyId) =>
			companies.byId[companyId]
				? acc.concat({
						id: companies.byId[companyId].id,
						name: companies.byId[companyId].name
				  })
				: acc,
		[]
	),
	createdAt: item.createdAt ? format(item.createdAt, dateTimeCommaSeparatedFormat) : '',
	createdBy: item.createdBy?.fullName || '',
	modifiedAt: item.modifiedAt ? format(item.modifiedAt, dateTimeCommaSeparatedFormat) : '',
	modifiedBy: item.modifiedBy?.fullName || '',
	// TODO: getPermission
	canDelete: true,
	canEdit: true
});

export const extractPreparedTemplatesSetsAsArray = createSelector(
	[extractTemplatesSetsAsArray, (state: IState) => state.entities.companies],
	(data, companies) => data.map(item => prepareTemplatesSet(item, companies))
);
