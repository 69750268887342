import { useCallback, useContext } from 'react';
import {RoleDispatchCtx, RolePermissionsCtx} from '../../../RolePage';
import {StatusesCtx} from '../PermissionsTable';
import {Option, Select} from '@tehzor/ui-components';
import {IRestrictionProps} from './Restriction';

const ProblemStatusesRestriction = ({id, name, permissionId, type}: IRestrictionProps) => {
	const permissions = useContext(RolePermissionsCtx);
	const dispatch = useContext(RoleDispatchCtx);
	const statuses = useContext(StatusesCtx);

	const p = permissions.find(item => item.permissionId === permissionId);
	const selected = (p?.restrictions?.[id] as string[]) || [];

	const onChange = useCallback(
		(value: string[]) => {
			dispatch({
				type: 'update-restriction',
				permissionId,
				restriction: id,
				value: value.length ? value : undefined
			});
		},
		[permissionId, id]
	);

	return (
		<div className="role-page__settings-select-wrap">
			<div className="role-page__settings-label-wrap">
				<span className="role-page__settings-label">{name}</span>
				<span className="role-page__settings-id">({id})</span>
			</div>

			<Select
				className="role-page__settings-select"
				value={selected}
				multiple
				onChange={onChange}
			>
				{type &&
					statuses[type].map(status => (
						<Option
							key={status.id}
							value={status.id}
						>
							{status.name}
						</Option>
					))}
			</Select>
		</div>
	);
};

export default ProblemStatusesRestriction;
