import { useMemo } from 'react';
import {buildRolesColumns} from './buildRegStandardsColumns';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	getPaginatedRegStandards,
	getRegStandardsPageOffset,
	getRegStandardsPageSize
} from '@src/selectors/entities/regStandards';
import {SimpleTable} from '@src/components/SimpleTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {
	regulatoryStandardsSettingsActions
} from '@src/store/modules/settings/pages/regulatoryStandards/regulatoryStandardsSettingsSlice';
import {defaultPageSizes} from '@src/constants/defaultPageSizes';

export const RegStandardsTable = () => {
	const dispatch = useAppDispatch();
	const offset = useAppSelector(getRegStandardsPageOffset);
	const pageSize = useAppSelector(getRegStandardsPageSize);
	const {data, pageCount, currentPage} = useAppSelector(getPaginatedRegStandards);
	const columns = useMemo(() => buildRolesColumns, []);
	const {setOffset, setPageSize} = regulatoryStandardsSettingsActions;

	const handlePageChange = (page: number) => {
		const newOffset = page * pageSize;
		if (offset !== newOffset) {
			dispatch(setOffset(page * pageSize));
		}
	};

	const handlePageSizeChange = (value: number) => {
		const newOffset = Math.floor(offset / value);
		dispatch(setPageSize(value));
		dispatch(setOffset(newOffset));
	};

	return (
		<SimpleTable
			pageCount={pageCount}
			currentPage={currentPage}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			pageSizeOptions={defaultPageSizes}
			pageSize={pageSize}
			columns={columns}
			data={data}
		/>
);
};
