import {extractObjectStagesAsArray} from '@src/store/modules/entities/objectStages/selectors';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import { memo, useCallback } from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {StageField} from './StageField';
import {IEditableObjectState} from '@src/store/modules/entities/object/objectSlice';

interface IStageFieldsProps {
	value?: IEditableObjectState['stages'];
	title?: string;
	hasError?: boolean;
	onChange?: (stageId: ObjectStageIds, dateFrom?: number, dateTo?: number) => void;
}

export const StageFields = memo((props: IStageFieldsProps) => {
	const {
		value,
		title,
		hasError,
		onChange
	} = props;
	const stages = useAppSelector(extractObjectStagesAsArray);

	const handleChange = useCallback((dateFrom: Date, dateTo: Date, stageId: ObjectStageIds) => {
		onChange?.(stageId, dateFrom.getTime(), dateTo.getTime());
	}, [onChange]);

	const handleClear = useCallback((stageId: ObjectStageIds) => {
		onChange?.(stageId, undefined, undefined);
	}, [onChange]);

	return (
		<div className="object-page__stages">
			<div className="object-page__add-fields-title field__label">{title}</div>
			<div className="object-page__stages-wrap">
				{stages.map(stage => (
					<StageField
						className="object-page__stages-stage"
						onChange={handleChange}
						onClear={handleClear}
						stage={stage}
						from={value?.[stage.id]?.from}
						to={value?.[stage.id]?.to}
						error={hasError}
						key={stage.id}
					/>
				))}
			</div>
		</div>
	);
});