import { useCallback } from 'react';
import {useIsBeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries/index';
import {toggleMobileSidebarVisibility} from '@src/store/modules/app/sidebar/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {IconButton, MenuArrowButton} from '@tehzor/ui-components';
import {useChangePath} from '@src/core/hooks/useChangePath';

interface IMobileLeftButtonProps {
	backBtnVisible?: boolean;
}

const MobileLeftButton = (props: IMobileLeftButtonProps) => {
	const {backBtnVisible} = props;

	const dispatch = useAppDispatch();
	const isMobile = useIsBeforeTablet();

	const {goBack} = useChangePath();
	const openSidebar = useCallback(() => {
		dispatch(toggleMobileSidebarVisibility(true));
	}, []);

	if (backBtnVisible) {
		return (
			<IconButton
				className="m-header__button_grey"
				onClick={goBack}
			>
				<i className="tz-arrow"/>
			</IconButton>
		);
	}
	if (isMobile) {
		return <MenuArrowButton onClick={openSidebar}/>;
	}
	return null;
};

export default MobileLeftButton;
