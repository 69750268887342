import {useEffect} from 'react';
import './App.less';
import AppHeader from '@src/components/AppHeader';
import AppContent from '@src/components/AppContent';
import {ResourcesLoader} from '../ResourcesLoader';
import ScrollRestoration from '@tehzor/tools/components/ScrollRestoration';
import history from '@src/core/history';
import {BeforeTablet} from '@tehzor/ui-components/src/utils/mediaQueries';
import AppBottomMenu from '../AppBottomMenu';
import {PageLeavingBlock, Toasts} from '@tehzor/ui-components';
import MobileDetect from 'mobile-detect';
import '@tehzor/ui-components/src/components/Toasts/toastify.less';

/**
 * Корневой компонент приложения
 */
export const App = () => {
	useEffect(() => {
		// Добавление корневого класса в зависимости от устройства
		const md = new MobileDetect(window.navigator.userAgent);
		document.body.classList.add(md.mobile() ? 'mobile' : 'desktop');
	}, []);

	return (
		// Оборачивание в AppHeader необходимо для доступности контекста шапки в компонентах страницы
		<AppHeader>
			<div
				id="scroll-container"
				className="app__wrap"
			>
				<ScrollRestoration
					history={history}
					scrollContainerId="scroll-container"
				>
					<PageLeavingBlock history={history}>
						<AppContent />
					</PageLeavingBlock>
				</ScrollRestoration>

				<BeforeTablet>
					<AppBottomMenu />
				</BeforeTablet>

				<ResourcesLoader />
				<Toasts />
			</div>
		</AppHeader>
	);
};
