import { useCallback, useContext } from 'react';
import {PopupColorPicker} from '@tehzor/ui-components';
import {UserDispatchCtx} from '../../UserPage';

const ColorField = ({value, disabled}: {value: string, disabled?: boolean}) => {
	const dispatch = useContext(UserDispatchCtx);

	const onChange = useCallback((color: string) => {
		dispatch({type: 'update', field: 'color', value: color});
	}, []);

	return (
		<div className="field">
			<div className="field__label">Цвет:</div>

			<PopupColorPicker
				value={value}
				disabled={disabled}
				onChange={onChange}
			/>
		</div>
	);
};

export default ColorField;
