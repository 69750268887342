import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import ISavingCompany from '@tehzor/tools/interfaces/companies/ISavingCompany';
import {IEditCompanyResponse, makeCompanyEditRequest} from '@src/api/backend/company';
import {addErrorToast} from '@tehzor/ui-components';

export type IEditCompanyPayload = IEditCompanyResponse;

const request = () => ({type: types.EDIT_REQUEST});

const success = (response: IEditCompanyResponse) => ({
	type: types.EDIT_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при изменении компании'});
	}
	return {
		type: types.EDIT_FAILURE,
		payload: {...error}
	};
};

/**
 * Изменяет компанию
 *
 * @param companyId id компании
 * @param company данные компании
 */
export const editCompany = (companyId: string, company: ISavingCompany) =>
	createApiAction<IEditCompanyResponse>(request, success, failure, () =>
		makeCompanyEditRequest(companyId, company)
	);
