import {IListDevice} from '@tehzor/tools/interfaces/IDevice';
import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetDevicesResponse = INormalizedData<IListDevice>;

export const requestGetDevices = async (userId: string) => {
	const params = {
		userId
	};
	const response = await httpRequests.withToken.get<IGetDevicesResponse>(`devices`, {params});
	return response.data;
};
