import styles from './BaseToast.module.less';
import {ReactNode} from 'react';

interface IToastProps {
	icon?: ReactNode;
	title?: string;
	description?: string;
	links?: ReactNode;
	actions?: ReactNode;
}

export const BaseToast = ({icon, title, description, links, actions}: IToastProps) => {
	return (
		<>
			<div className={styles.container}>
				{icon && <div className={styles.iconWrapper}>{icon}</div>}
				<div className={styles.info}>
					{title && <p className={styles.title}>{title}</p>}
					{description && <p className={styles.description}>{description}</p>}
					{links && <div className={styles.links}>{links}</div>}
				</div>
			</div>
			{actions && <div className={styles.actions}>{actions}</div>}
		</>
	);
};
