import { memo } from 'react';
import {LoadingPanel} from '@tehzor/ui-components';
import {LogPageHeader} from './components/LogPageHeader';
import './LogPage.less';
import useAsync from 'react-use/lib/useAsync';
import {getLogRecords} from '@src/actions/entities/logRecords';
import {getLogRecordTypes} from '@src/actions/entities/logRecordTypes';

import {Filters} from './components/filters/Filters';
import {getUsers} from '@src/store/modules/entities/users/actions';
import {RecordsTable} from './components/RecodsTable/RecordsTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLogRecordsIsLoading} from '@src/selectors/entities/logRecords';

export const LogPage = memo(() => {
	useAppHeader(
		{
			title: 'Лог действий'
		},
		[]
	);
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(getLogRecordsIsLoading);
	const loadingState = useAsync(async () => {
		await Promise.allSettled([dispatch(getLogRecords()), dispatch(getLogRecordTypes()), dispatch(getUsers())]);
	}, []);
	return (
		<LoadingPanel
			className="loading-panel_main"
			active={loadingState.loading || isLoading}
		>
			<div className="page-cont">
				<LogPageHeader/>
				<Filters/>
				<RecordsTable/>
			</div>
		</LoadingPanel>
	);
});
