import { HTMLAttributes, memo, useMemo } from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFilteredUsers, getUsersPageOffset, getUsersPageSize} from '@src/selectors/entities/users';
import {SimpleTable} from '@src/components/SimpleTable';
import {buildUsersColumns, IUserWithObjects} from './buildUsersColumns';
import {Row} from '@tanstack/react-table';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {usersSettingsActions} from '@src/store/modules/settings/pages/users/usersSettingsSlice';
import {defaultPageSizes} from '@src/constants/defaultPageSizes';

export const UsersTable = memo(() => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const offset = useAppSelector(getUsersPageOffset);
	const pageSize = useAppSelector(getUsersPageSize);
	const {data, currentPage, pageCount} = useAppSelector(extractFilteredUsers);
	const {setOffset, setPageSize} = usersSettingsActions;
	const columns = useMemo(() => buildUsersColumns, []);
	const goToUser = (userId: string) => {
		if (userId) {
			pushPath(`/users/${userId}`);
		}
	};

	const getUsersTableRowProps = (row: Row<IUserWithObjects>): HTMLAttributes<HTMLTableRowElement> => ({
		onClick: () => goToUser(row.original.id)
	});

	const handlePageChange = (page: number) => {
		const newOffset = page * pageSize;
		if (offset !== newOffset) {
			dispatch(setOffset(page * pageSize));
		}
	};

	const handlePageSizeChange = (value: number) => {
			const newOffset = Math.floor(offset / value);
			dispatch(setPageSize(value));
			dispatch(setOffset(newOffset));
		};

	return (
		<SimpleTable
			columns={columns}
			pageCount={pageCount}
			currentPage={currentPage}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			pageSizeOptions={defaultPageSizes}
			pageSize={pageSize}
			data={data}
			getTableRowProps={getUsersTableRowProps}
		/>
);
});
