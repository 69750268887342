import { memo } from 'react';
import {FieldWithLabel} from '@src/pages/ObjectPage/components/fieldWithLabel/fieldWithLabel';
import {CompanyField} from '../CompanyField';
import {ParentField} from '../ParentField';
import {StageField} from '../StageField';
import {SpaceIndicatorsSetField} from '../SpaceIndicatorsSetField';
import {ProblemTemplatesSetField} from '../ProblemTemplatesSetField';
import {IncObjectField} from '../IncObjectField';
import {ObjectIdsForIncField} from '../ObjectIdsForIncField';
import {ManagerFields} from '../ManagerFields';
import {StageFields} from '../StageFields';
import {ImageField} from '../ImageField';
import {IEditableObjectState, IObjectErrors} from '@src/store/modules/entities/object/objectSlice';
import {ObjectStageIds} from '@tehzor/tools/interfaces/objects/IObjectStage';
import IUploadingFile from '@tehzor/tools/interfaces/IUploadingFile';
import {IObjectManager} from '@tehzor/tools/interfaces/objects/IObject';
import IFile from '@tehzor/tools/interfaces/IFile';

interface IEditableObjectCardProps {
	objectId?: string;
	formState?: IEditableObjectState;
	validateErrors?: IObjectErrors;
	onNameChange?: (value: string) => void;
	onExternalIdChange?: (value: string) => void;
	onFullNameChange?: (value: string) => void;
	onCompanyChange?: (value: string) => void;
	onParentChange?: (value: string) => void;
	onCityChange?: (value: string) => void;
	onAddressChange?: (value: string) => void;
	onGeneralContractorChange?: (value: string) => void;
	onNewImageChange?: (newImage?: IUploadingFile[]) => void;
	onImageChange?: (Image?: IFile) => void;
	onObjectIdsForIncChange?: (value: string[]) => void;
	onIncObjectChange?: (value: string) => void;
	onProblemTemplatesSetChange?: (value: string) => void;
	onSpaceIndicatorsSetChange?: (value: string) => void;
	onProjectManagerChange?: (value: IObjectManager) => void;
	onConstructionManagerChange?: (value: IObjectManager) => void;
	onStagesChange?: (stageId: ObjectStageIds, dateFrom: number, dateTo: number) => void;
	onStageChange?: (value: string) => void;
}

export const EditableObjectForm = memo((props: IEditableObjectCardProps) => {
	const {
		objectId,
		formState,
		validateErrors,
		onNameChange,
		onFullNameChange,
		onExternalIdChange,
		onCompanyChange,
		onParentChange,
		onCityChange,
		onAddressChange,
		onGeneralContractorChange,
		onNewImageChange,
		onImageChange,
		onObjectIdsForIncChange,
		onIncObjectChange,
		onProblemTemplatesSetChange,
		onSpaceIndicatorsSetChange,
		onProjectManagerChange,
		onConstructionManagerChange,
		onStagesChange,
		onStageChange
	} = props;

	return (
		<>
			<div className="fields-grid">
				<FieldWithLabel
					value={formState?.name}
					label="Наименование"
					onChange={onNameChange}
					hasError={validateErrors?.name}
				/>

				<FieldWithLabel
					value={formState?.fullName}
					label="Полное наименование"
					onChange={onFullNameChange}
				/>

				<FieldWithLabel
					value={formState?.externalId}
					label="Внешний id"
					onChange={onExternalIdChange}
				/>

				<CompanyField
					objectId={objectId}
					value={formState?.companyId}
					onChange={onCompanyChange}
					hasError={validateErrors?.companyId}
				/>

				<ParentField
					objectId={objectId}
					value={formState?.parentId}
					companyId={formState?.companyId}
					onChange={onParentChange}
				/>

				<FieldWithLabel
					value={formState?.city}
					label="Город"
					onChange={onCityChange}
				/>

				<FieldWithLabel
					value={formState?.address}
					label="Адрес"
					onChange={onAddressChange}
				/>

				<FieldWithLabel
					value={formState?.generalContractor}
					label="Генеральный подрядчик"
					onChange={onGeneralContractorChange}
				/>

				<StageField
					value={formState?.stage}
					onChange={onStageChange}
				/>

				<SpaceIndicatorsSetField
					value={formState?.spaceIndicatorsSet}
					companyId={formState?.companyId}
					parentId={formState?.parentId}
					onChange={onSpaceIndicatorsSetChange}
				/>

				<ProblemTemplatesSetField
					value={formState?.problemTemplatesSet}
					companyId={formState?.companyId}
					parentId={formState?.parentId}
					onChange={onProblemTemplatesSetChange}
				/>

				{objectId && (
					<>
						<IncObjectField
							value={formState?.incObjectId}
							objectId={objectId}
							onChange={onIncObjectChange}
						/>

						<ObjectIdsForIncField
							value={formState?.objectIdsForInc}
							objectId={objectId}
							onChange={onObjectIdsForIncChange}
						/>
					</>
				)}
			</div>

			<div className="object-page__add-fields">
				<div className="object-page__add-fields-title field__label">Руководители</div>
				<ManagerFields
					title="Рук. проекта"
					value={formState?.projectManager}
					onChange={onProjectManagerChange}
					hasError={validateErrors?.projectManager}
				/>

				<ManagerFields
					title="Рук. строительства"
					value={formState?.constructionManager}
					onChange={onConstructionManagerChange}
					hasError={validateErrors?.constructionManager}
				/>
			</div>

			<div className="object-page__add-fields">
				<StageFields
					title="Стадии"
					value={formState?.stages}
					onChange={onStagesChange}
				/>
			</div>
			<ImageField
				image={formState?.image}
				newImage={formState?.newImage}
				onImageChange={onImageChange}
				onNewImageChange={onNewImageChange}
			/>
		</>
	);
});
