import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {AppThunkAction} from '@src/store/interfaces/thunk';
import {StoreError} from '@tehzor/tools/errors/StoreError';
import {
	IGetTemplatesSetsResponse,
	requestTemplatesSets
} from '@src/api/backend/problemTemplatesSets';
import {addErrorToast} from '@tehzor/ui-components';

export type IGetTemplatesSetsPayload = IGetTemplatesSetsResponse;

const request = (objectId: string) => ({
	type: types.GET_REQUEST,
	payload: {objectId}
});

const success = (objectId: string, response: IGetTemplatesSetsResponse) => ({
	type: types.GET_SUCCESS,
	payload: {
		...response,
		objectId
	}
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при загрузке списка наборов шаблонов нарушений'});
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список наборово шаблонов нарушений
 */
export const getTemplatesSets =
	(objectId = 'all'): AppThunkAction<Promise<IGetTemplatesSetsResponse>> =>
	(dispatch, getState) =>
		dispatch(
			checkExpiration<IState, IGetTemplatesSetsResponse, ApiAction>(
				s => s.entities.problemTemplatesSets[objectId],
				createApiAction<IGetTemplatesSetsResponse>(
					() => request(objectId),
					(res: IGetTemplatesSetsResponse) => success(objectId, res),
					failure,
					() => {
						const s = getState();

						if (
							!s.entities.problemTemplatesSets[objectId] ||
							!s.settings.pages.problemTemplatesSets[objectId]
						) {
							throw new StoreError('Empty store before getting problemTemplatesSets');
						}

						return requestTemplatesSets();
					}
				)
			)
		);
