import * as React from 'react';
import classNames from 'classnames';
import {AnimatePresence, motion} from 'framer-motion';

const animationVariants = {
	opened: {opacity: 1, height: 'auto'},
	collapsed: {opacity: 0, height: 0}
};

const animationTransition = {type: 'tween', duration: 0.25};

interface ITableRowSubContentProps {
	className?: string;
	style?: React.CSSProperties;
	children?: React.ReactNode;
	expanded?: boolean;
}

const TableRowSubContent = ({className, style, children, expanded}: ITableRowSubContentProps) =>
	(children !== undefined ? (
		<AnimatePresence initial={false}>
			{expanded && (
				<motion.div
					className={classNames('table2__row-sub-content', className)}
					style={style}
					initial="collapsed"
					animate="opened"
					exit="collapsed"
					variants={animationVariants}
					transition={animationTransition}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	) : null);

export default TableRowSubContent;
