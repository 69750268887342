import {CSSProperties, ReactElement, ReactNode} from 'react';
import Button, {ButtonType} from '../../buttons/Button';
import {IMenuComponentProps, IMenuItemProps} from '../MenuItem';
import {convertClassNames} from '../../../utils/convertClassNames';
import {IPopupBasicProps} from '../../containers/Popup';
import MenuLayer from '../MenuLayer';
import IconButton from '../../buttons/IconButton';
import classNames from 'classnames';
import styles from './SearchFieldsMenu.module.less';

const icon = <i className="tz-expand-arrow-heavy" />;

interface IButtonMenuProps<V, M extends IMenuComponentProps<V>> {
	className?:
		| string
		| {
				root?: string;
				button?: string;
				wrap?: string;
				label?: string;
				leftIcon?: string;
				rightIcon?: string;
		  };
	style?: CSSProperties;
	children?: ReactElement<M> | Array<ReactElement<IMenuItemProps>>;
	type?: ButtonType;
	leftIcon?: ReactNode;
	label?: ReactNode;
	isMobile?: boolean;
	disabled?: boolean;
	popupArrowVisible?: boolean;
	popupProps?: IPopupBasicProps;
}

const SearchFieldsMenu = <V, M extends IMenuComponentProps<V>>(props: IButtonMenuProps<V, M>) => {
	const {
		className,
		style,
		children,
		type,
		leftIcon,
		label,
		disabled,
		popupArrowVisible,
		popupProps,
		isMobile
	} = props;

	const classes = convertClassNames(className);

	return (
		<MenuLayer
			className={classes.root}
			trigger={({toggle, ref, disabled: triggerDisabled}) =>
				isMobile ? (
					<IconButton
						onClick={toggle}
						ref={ref}
						className={classNames(styles.iconButton, classes.button)}
						disabled={disabled}
					>
						{icon}
					</IconButton>
				) : (
					<Button
						className={{
							root: classNames(styles.button, classes.button),
							wrap: classNames(styles.buttonWrap, classes.wrap),
							label: classNames(styles.buttonLabel, classes.label),
							leftIcon: classes.leftIcon,
							rightIcon: classNames(styles.rightIcon, classes.rightIcon)
						}}
						style={style}
						type={type}
						leftIcon={leftIcon}
						label={label}
						rightIcon={icon}
						disabled={disabled || triggerDisabled || !children}
						onClick={toggle}
						ref={ref}
					/>
				)
			}
			popupArrowVisible={popupArrowVisible}
			popupProps={{placement: 'bottom-start', preferX: 'left', ...popupProps}}
		>
			{children}
		</MenuLayer>
	);
};

SearchFieldsMenu.displayName = 'SearchFieldsMenu';

export default SearchFieldsMenu;
