import {NameCell} from './NameCell';
import {ChannelsCell} from './ChannelsCell';
import {SettingsExpanderCell} from './SettingsExpanderCell';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import {ColumnDef} from '@tanstack/react-table';

export const columns: Array<ColumnDef<INotificationType>> = [
	{
		id: 'name',
		cell: NameCell,
		minSize: 100
	},
	{
		id: 'channels',
		cell: ChannelsCell,
		minSize: 60,
		maxSize: 500
	},
	{
		id: '_settingsExpander',
		cell: SettingsExpanderCell,
		size: 50
	}
];
