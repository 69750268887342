import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {IState} from '@src/store/modules';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IGetUsersResponse, requestUsers} from '@src/api/backend/users';
import {addErrorToast} from '@tehzor/ui-components';

export type IGetUsersPayload = IGetUsersResponse;

const request = () => ({type: types.GET_REQUEST});

const success = (response: IGetUsersResponse) => ({
	type: types.GET_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при загрузке списка пользователей'});
	}
	return {
		type: types.GET_FAILURE,
		payload: {...error}
	};
};

/**
 * Получает список объектов
 */
export const getUsers = () =>
	checkExpiration<IState, IGetUsersResponse, ApiAction>(
		s => s.entities.users,
		createApiAction<IGetUsersResponse>(request, success, failure, requestUsers)
	);
