import { createContext, Dispatch, memo, useCallback, useEffect, useReducer } from 'react';
import './RolePage.less';
import {
	ActionButtons,
	Button,
	LoadingPanel,
	TabContent,
	TabLink,
	Tabs
} from '@tehzor/ui-components';
import {getCurrentRole} from '@src/selectors/entities/roles';
import NameField from './components/NameField';
import useAsync from 'react-use/lib/useAsync';
import IPermission from '@tehzor/tools/interfaces/IPermission';
import CompanyField from './components/CompanyField';
import {getRolePermissions} from '@src/actions/entities/rolePermissions';
import PermissionsTable from './components/permissions/PermissionsTable';
import {RolePageHeader} from './components/RolePageHeader';
import {convertToSave, IEditableRole, init, reducer} from '@src/pages/RolePage/editableRoleState';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import SubsTable from './components/subscriptions/SubsTable';
import {getTypes} from '@src/actions/notifications/types';
import {getChannels} from '@src/actions/notifications/channels';
import {getIntervals} from '@src/actions/notifications/intervals';
import {getPeriods} from '@src/actions/notifications/periods';
import {getCompanies} from '@src/store/modules/entities/companies/actions';
import {getProblemStatuses} from '@src/store/modules/entities/problemStatuses/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useParams} from 'react-router-dom';
import {getRoles} from '@src/store/modules/entities/roles/actions/get';
import {editRole} from '@src/store/modules/entities/roles/actions/edit';
import {addRole} from '@src/store/modules/entities/roles/actions/add';
import {getCheckRecordStatuses} from '@src/store/modules/entities/checkRecordStatuses/actions';
import {getWorkAcceptanceStatuses} from '@src/store/modules/entities/workAcceptanceStatuses/actions';

export const RoleDispatchCtx = createContext<Dispatch<any>>(() => ({}));
export const RolePermissionsCtx = createContext<IPermission[]>([]);
export const RoleSubsCtx = createContext<IEditableRole['defaultSubs']>([]);

export const RolePage = memo(() => {
	// Если Использовать useStrictParams попадаем в ошибку :с
	const {roleId} = useParams();
	const role = useAppSelector(s => getCurrentRole(s, roleId));

	const dispatch = useAppDispatch();
	const {pushPath} = useChangePath();

	const [editableRole, roleDispatch] = useReducer(reducer, role, init);

	const loadingState = useAsync(async () => {
		if (roleId) {
			await dispatch(getRoles());
		}
		await Promise.allSettled([
			dispatch(getRolePermissions()),
			dispatch(getCompanies()),
			dispatch(getProblemStatuses()),
			dispatch(getCheckRecordStatuses()),
			dispatch(getWorkAcceptanceStatuses()),
			dispatch(getTypes()),
			dispatch(getChannels()),
			dispatch(getIntervals()),
			dispatch(getPeriods())
		]);
	}, [roleId]);

	useEffect(() => {
		roleDispatch({type: 'reset', role});
	}, [role]);

	const [, save] = useAsyncFn(async () => {
		if (roleId) {
			await dispatch(editRole(roleId, convertToSave(editableRole, role, roleId)));
		} else {
			const savedRole = await dispatch(addRole(convertToSave(editableRole, role)));
			pushPath(`/roles/${savedRole.id}`);
		}
	}, [roleId, editableRole]);

	const cancel = useCallback(() => {
		if (roleId) {
			roleDispatch({type: 'reset', role});
		} else {
			pushPath('/roles');
		}
	}, [roleId, role]);

	return (
		<LoadingPanel
			className="role-page__loading-panel"
			active={loadingState.loading}
		>
			<div className="page-cont role-page">
				<RolePageHeader
					roleId={roleId}
					role={role}
				/>

				<RoleDispatchCtx.Provider value={roleDispatch}>
					<div className="panel role-page__panel">
						<div className="fields-grid">
							<NameField name={editableRole.name} />

							<CompanyField
								roleId={roleId}
								companyId={editableRole.companyId}
							/>
						</div>

						<Tabs
							className={{links: 'role-page__tabs-links'}}
							links={[
								<TabLink label="Полномочия" />,
								<TabLink label="Подписки на уведомления по умолчанию" />
							]}
						>
							<TabContent>
								<RolePermissionsCtx.Provider value={editableRole.permissions}>
									<PermissionsTable />
								</RolePermissionsCtx.Provider>
							</TabContent>

							<TabContent>
								<RoleSubsCtx.Provider value={editableRole.defaultSubs}>
									<SubsTable />
								</RoleSubsCtx.Provider>
							</TabContent>
						</Tabs>

						{(!roleId || editableRole.edited) && (
							<ActionButtons className="role-page__buttons">
								<Button
									type="accent-blue"
									label="Сохранить"
									onClick={save}
								/>
								<Button
									type="cancel"
									label="Отменить"
									onClick={cancel}
								/>
							</ActionButtons>
						)}
					</div>
				</RoleDispatchCtx.Provider>
			</div>
		</LoadingPanel>
	);
});
