import {CSSProperties, memo, ReactNode} from 'react';
import './MobilePagination.less';
import {Button} from '../../buttons';
import classNames from 'classnames';
import {DefaultTitle} from './components/DefaultTitle';

interface IMobilePaginationProps {
	className?: string;
	style?: CSSProperties;
	page: number;
	pageCount: number;
	disabled?: boolean;
	renderTitle?: (p: {page: number; pageCount: number}) => ReactNode;
	onPageChange: (value: number) => void;
}

export const MobilePagination = memo((props: IMobilePaginationProps) => {
	const {
		className,
		style,
		pageCount,
		page,
		disabled,
		renderTitle = DefaultTitle,
		onPageChange
	} = props;

	const prev = () => {
		if (page > 0) {
			onPageChange(page - 1);
		}
	};

	const next = () => {
		if (page < pageCount - 1) {
			onPageChange(page + 1);
		}
	};

	return pageCount > 1 ? (
		<div className={classNames('m-pagination', className)} style={style}>
			<Button
				className={{
					root: 'm-pagination__btn m-pagination__btn_prev',
					leftIcon: 'm-pagination__btn-icon'
				}}
				leftIcon={<i className="tz-simple-arrow-20" style={{transform: 'rotate(90deg)'}} />}
				label="Пред."
				disabled={page <= 0 || disabled}
				onClick={prev}
			/>
			<div className="m-pagination__title">{renderTitle({page, pageCount})}</div>
			<Button
				className={{
					root: 'm-pagination__btn m-pagination__btn_next',
					rightIcon: 'm-pagination__btn-icon'
				}}
				rightIcon={
					<i className="tz-simple-arrow-20" style={{transform: 'rotate(270deg)'}} />
				}
				label="След."
				disabled={page >= pageCount - 1 || disabled}
				onClick={next}
			/>
		</div>
	) : null;
});
