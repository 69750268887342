import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IDeleteCompanyResponse, makeCompanyDeleteRequest} from '@src/api/backend/company';
import {addErrorToast} from '@tehzor/ui-components';

export interface IDeleteCompanyPayload {
	id: string;
}

const request = () => ({type: types.DELETE_REQUEST});

const success = (companyId: string) => ({
	type: types.DELETE_SUCCESS,
	payload: {id: companyId} as IDeleteCompanyPayload
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при удалении компании'});
	}
	return {
		type: types.DELETE_FAILURE,
		payload: {...error}
	};
};

/**
 * Удаляет компанию
 *
 * @param companyId id компании
 */
export const deleteCompany = (companyId: string) =>
	createApiAction<IDeleteCompanyResponse>(
		request,
		() => success(companyId),
		failure,
		() => makeCompanyDeleteRequest(companyId)
	);
