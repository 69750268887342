import { useState } from 'react';
import {Button, SelectSearch} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getObjectsAsArray} from '@src/selectors/entities/objects';
import {extractCompaniesAsArray} from '@src/store/modules/entities/companies/selectors';
import {getRolesAsArray} from '@src/selectors/entities/roles';
import {ObjectsSelect} from '@src/components/ObjectsSelect';
import {RoleSelect} from '@src/components/RoleSelect';
import {MultipleSelect} from '@src/components/MultipleSelect';
import {BooleanSelect} from '@src/components/BooleanSelect/BooleanSelect';
import {useUsersFilters} from '../../hooks/useUsersFilters/useUsersFilters';

export const UsersFilter = () => {
	const objects = useAppSelector(getObjectsAsArray);
	const companies = useAppSelector(extractCompaniesAsArray);
	const roles = useAppSelector(getRolesAsArray);
	const [fullName, setFullName] = useState('');
	const [position, setPosition] = useState('');
	const [email, setEmail] = useState('');

	const {
		onFullNameChange,
		onPositionChange,
		onEmailChange,
		onRoleChange,
		onObjectsChange,
		onCompanyChange,
		onDeactivatedChange,
		onDeletedChange,
		clearFiltersState,
		hasUserFilters,
		filtersState: {companiesIds, objectsIds, roleId, deactivated, deleted}
	} = useUsersFilters();

	const handleFullNameChange = (value: string) => {
		setFullName(value);
	};
	const applyFullNameFilter = () => {
		onFullNameChange(fullName);
	};
	const clearFullNameFilter = () => {
		onFullNameChange('');
	};

	const handlePositionChange = (value: string) => {
		setPosition(value);
	};
	const applyPositionFilter = () => {
		onPositionChange(position);
	};
	const clearPositionFilter = () => {
		onPositionChange('');
	};

	const handleEmailChange = (value: string) => {
		setEmail(value);
	};
	const applyEmailFilter = () => {
		onEmailChange(email);
	};
	const clearEmailFilter = () => {
		onEmailChange('');
	};

	const clear = () => {
		clearFiltersState();
		setFullName('');
		setPosition('');
		setEmail('');
	};

	return (
		<div className="users-page__filters-container">
			<SelectSearch
				type="filter"
				placeholder="ФИО"
				value={fullName}
				onChange={handleFullNameChange}
				onSearch={applyFullNameFilter}
				onClear={clearFullNameFilter}
			/>
			<SelectSearch
				type="filter"
				placeholder="Email"
				value={email}
				onChange={handleEmailChange}
				onSearch={applyEmailFilter}
				onClear={clearEmailFilter}
			/>
			<SelectSearch
				type="filter"
				placeholder="Должность"
				value={position}
				onChange={handlePositionChange}
				onSearch={applyPositionFilter}
				onClear={clearPositionFilter}
			/>
			<MultipleSelect
				label="Компании"
				options={companies}
				optionsIds={companiesIds}
				onChangeFilter={onCompanyChange}
			/>
			<ObjectsSelect
				objects={objects}
				companies={companies}
				objectsId={objectsIds}
				changeFilter={onObjectsChange}
			/>
			<RoleSelect
				roles={roles}
				roleId={roleId}
				changeFilter={onRoleChange}
			/>
			<BooleanSelect
				label="Удален"
				value={deleted}
				changeFilter={onDeletedChange}
			/>
			<BooleanSelect
				label="Деактивирован"
				value={deactivated}
				changeFilter={onDeactivatedChange}
			/>
			{hasUserFilters && (
				<Button
					onClick={clear}
					label="Сбросить"
					type="cancel"
				/>
			)}
		</div>
	);
};
