import { memo, useState } from 'react';
import {FilterButton, Select2, SelectOption, SelectPopup} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

interface IBooleanSelectProps {
	label: string;
	value?: boolean;
	changeFilter: (value: boolean | undefined) => void;
}

export const BooleanSelect = memo(({value, label, changeFilter}: IBooleanSelectProps) => {
	const [selectedValue, setSelectedValue] = useState('');

	const hasTrue = selectedValue === 'Да';
	const selectValue = hasTrue ? 'Да' : 'Нет';

	useUpdateEffect(() => {
		setSelectedValue(value === undefined ? '' : selectValue);
	}, [value]);

	const handleApply = () => {
		if (selectedValue) {
			changeFilter(hasTrue);
		}
	};

	const handleCancel = () => {
		if (value) {
			setSelectedValue(selectValue);
		}
	};

	const handleFullClear = () => {
		setSelectedValue('');
		changeFilter(undefined);
	};

	const makeLabel = value !== undefined ? (value ? 'Да' : 'Нет') : undefined;
	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			trigger={(
				<FilterButton
					label={makeFilterLabel(label, makeLabel)}
					onClear={handleFullClear}
					active={value !== undefined}
				/>
			)}
		>
			<Select2
				value={selectedValue}
				onChange={setSelectedValue}
			>
				<SelectOption
					itemKey="Да"
					inputType="radio"
					content="Да"
				/>
				<SelectOption
					itemKey="Нет"
					inputType="radio"
					content="Нет"
				/>
			</Select2>
		</SelectPopup>
	);
});
