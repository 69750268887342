import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';

export type IRestoreObjectResponse = IObject;

/**
 * Восстанавливает объект
 *
 * @param objectId id объекта
 */

export const makeObjectRestoreRequest = async (objectId: string) => {
	const response = await httpRequests.withToken.post<IRestoreObjectResponse>('objects/restore', {
		objectId
	});
	return response.data;
};
