import {useContext} from 'react';
import ICustomizableField from '@tehzor/tools/interfaces/ICustomizableField';
import {SettingsValueCtx} from './FieldsTable';
import classNames from 'classnames';
import {CellContext} from '@tanstack/react-table';

export const NameCell = ({row}: CellContext<ICustomizableField, ICustomizableField>) => {
	const {original} = row;
	const value = useContext(SettingsValueCtx);
	const isChecked = value?.some(item => item.fieldId === original.id);

	return (
		<div className={classNames({'object-page__fields-settings-name-col_disabled': !isChecked})}>
			<span>({original.name})</span>
			<span className="object-page__fields-settings-name-id">({original.id})</span>
		</div>
	);
};
