import { useCallback, useContext } from 'react';
import {TextField} from '@tehzor/ui-components';
import {RoleDispatchCtx} from '../RolePage';

const NameField = ({name}: { name: string }) => {
	const dispatch = useContext(RoleDispatchCtx);

	const onChange = useCallback((value: string) => {
		dispatch({type: 'update', field: 'name', value});
	}, []);

	return (
		<div className="field">
			<div className="field__label">Название:</div>

			<TextField
				className="role-page__field"
				elementType="input"
				value={name}
				onChange={onChange}
			/>
		</div>
	);
};

export default NameField;
