import { memo } from 'react';
import {Row} from '@tanstack/react-table';

import {Dialog} from '@tehzor/ui-components';
import {DetailsInfo} from './DetailsInfo';
import {DetailsError} from './DetailsError';
import {IPreparedLogRecord} from '../utils/convertRecords';

interface ILogDialogProps {
	row: Row<IPreparedLogRecord>;
	isOpen: boolean;
	onClose: () => void;
}

export const LogDialog = memo((props: ILogDialogProps) => {
	const {isOpen, onClose, row} = props;
	const {typeId, fields, error} = row.original;

	return (
		<Dialog
			className="log-page__dialog"
			title={row.original.type}
			fullScreenOnTablet
			isOpen={isOpen}
			onRequestClose={onClose}
		>
			<div className="log-page__details">
				<DetailsInfo
					typeId={typeId}
					fields={fields}
				/>

				<DetailsError error={error} />
			</div>
		</Dialog>
	);
});
