import { memo } from 'react';
import {CellContext} from '@tanstack/react-table';
import IRole from '@src/interfaces/IRole';
import {format} from 'date-fns';
import {dateTimeFormat} from '@tehzor/tools/utils/dateFormats';

export const ModifiedCell = memo(({row}: CellContext<IRole, IRole>) => {
	const modifiedAt = format(new Date(row.original.modifiedAt), dateTimeFormat);
	const modifiedBy = row.original.modifiedBy?.fullName;

	return (
		<>
			<span>{modifiedAt}</span>
			<br/>
			<span>{modifiedBy}</span>
		</>
	);
});
