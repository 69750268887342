import {createContext, HTMLAttributes, useContext, useMemo} from 'react';
import {IState} from '@src/store/modules';
import {connect} from 'react-redux';
import IRolePermission from '@tehzor/tools/interfaces/IRolePermission';
import arrayToTree from 'array-to-tree';
import {getRolePermissionsAsArray} from '@src/selectors/entities/rolePermissions';
import memoizeOne from 'memoize-one';
import IPermission from '@tehzor/tools/interfaces/IPermission';
import {RolePermissionsCtx} from '../../RolePage';
import {IProblemStatus} from '@tehzor/tools/interfaces/problems/IProblemStatus';
import {extractProblemStatusesAsArray} from '@src/store/modules/entities/problemStatuses/selectors';
import {extractCheckRecordStatusesByEntityTypeAsArray} from '@src/store/modules/entities/checkRecordStatuses/selectors';
import {extractWorkAcceptanceStatusesAsArray} from '@src/store/modules/entities/workAcceptanceStatuses/selectors';
import {ICheckRecordStatus} from '@tehzor/tools/interfaces/checkRecords/ICheckRecordStatus';
import {IWorkAcceptanceStatus} from '@tehzor/tools/interfaces/workAcceptances/IWorkAcceptanceStatus';
import {SimpleTable} from '@src/components/SimpleTable';
import {columns} from './columns';
import {Cell, Row} from '@tanstack/react-table';
import {RestrictionProvider} from '@src/core/providers/SettingsProvider';

interface IPermissionWithChildren extends IRolePermission {
	children?: IPermissionWithChildren[];
}

interface IPreparedPermission extends IPermissionWithChildren {
	children?: IPreparedPermission[];
	level?: number;
}

interface IPermissionsTableProps {
	rolePermissions: IRolePermission[];
	problemStatus: IProblemStatus[];
	checkListStatus: ICheckRecordStatus[];
	checkItemStatus: ICheckRecordStatus[];
	workAcceptanceStatus: IWorkAcceptanceStatus[];
}

export type IStatuses = IProblemStatus | ICheckRecordStatus | IWorkAcceptanceStatus;

export interface IStatusesCtx {
	problemStatus: IProblemStatus[];
	checkListStatus: ICheckRecordStatus[];
	checkItemStatus: ICheckRecordStatus[];
	workAcceptanceStatus: IWorkAcceptanceStatus[];
}

const TreeDataCtx = createContext<IPreparedPermission[]>([]);
const SemiSelectedCtx = createContext<string[]>([]);
const StatusesCtx = createContext<IStatusesCtx>({
	problemStatus: [],
	checkListStatus: [],
	checkItemStatus: [],
	workAcceptanceStatus: []
});

/**
 * Добавляет к элементам свойство level
 *
 * @param {IPermissionWithChildren[]} treeData дерево полномочий
 * @param {number} level начальный индекс
 */
const fillLevel = (treeData: IPermissionWithChildren[], level: number): IPreparedPermission[] =>
	treeData.map(item => ({
		...item,
		children: item.children && fillLevel(item.children, level + 1),
		level
	}));

/**
 * Преобразовывает массив полномочий в дерево
 *
 * @param {IRolePermission[]} data массив полномочий
 */
const convertPermissions = memoizeOne((data: IRolePermission[]): IPreparedPermission[] => {
	const treeData = arrayToTree<IRolePermission>(data, {
		parentProperty: 'parentId',
		customID: 'id'
	});
	return fillLevel(treeData, 0);
});

/**
 * Возвращает массив id частично выбранных элементов
 *
 * @param {IPreparedPermission[]} treeData дерево полномочий
 * @param {IPermission[]} selected выбранные элементы
 */
const getSemiSelected = (treeData: IPreparedPermission[], selected: IPermission[]): string[] => {
	let result: string[] = [];
	for (const item of treeData) {
		const semi: string[] = [];
		if (item.children) {
			semi.push(...getSemiSelected(item.children, selected));
			const currentSelected = item.children.filter(p =>
				selected.some(s => s.permissionId === p.id)
			);

			if (
				semi.length ||
				(currentSelected.length > 0 && currentSelected.length < item.children.length)
			) {
				semi.push(item.id);
			}
		}
		result = result.concat(semi);
	}
	return result;
};

const rowIdent = 50;

const PermissionsTable = ({
	rolePermissions,
	problemStatus,
	checkListStatus,
	checkItemStatus,
	workAcceptanceStatus
}: IPermissionsTableProps) => {
	const permissions = useContext(RolePermissionsCtx);
	const data = convertPermissions(rolePermissions);
	const semiSelected = getSemiSelected(data, permissions);

	const statuses: IStatusesCtx = useMemo(
		() => ({
			problemStatus,
			checkListStatus,
			checkItemStatus,
			workAcceptanceStatus
		}),
		[problemStatus, checkListStatus, checkItemStatus, workAcceptanceStatus]
	);

	const getTableRowProps = (
		row: Row<IPreparedPermission>
	): HTMLAttributes<HTMLTableRowElement> => ({
		className: 'role-page__ptable-row',
		style: {
			paddingLeft: (row.original.level || 0) * rowIdent
		}
	});

	const getTableCellProps = (
		cell: Cell<IPreparedPermission, IPreparedPermission>
	): HTMLAttributes<HTMLTableCellElement> => {
		if (cell.column.id === 'restExpander') {
			return {
				className: 'role-page__ptable-tbody role-page__ptable-tbody-restExpander'
			};
		}

		if (cell.column.id === 'checkbox') {
			return {
				className: 'role-page__ptable-tbody role-page__ptable-tbody-checkbox'
			};
		}

		return {
			className: 'role-page__ptable-tbody'
		};
	};

	return (
		<RestrictionProvider>
			<TreeDataCtx.Provider value={data}>
				<SemiSelectedCtx.Provider value={semiSelected}>
					<StatusesCtx.Provider value={statuses}>
						<div className="field__wide-label-wrap">
							<div className="field__label">Полномочия:</div>
						</div>

						<SimpleTable
							data={data}
							columns={columns}
							pageCount={data.length}
							getTableRowProps={getTableRowProps}
							getTableCellProps={getTableCellProps}
							isTHeadVisible={false}
						/>
					</StatusesCtx.Provider>
				</SemiSelectedCtx.Provider>
			</TreeDataCtx.Provider>
		</RestrictionProvider>
	);
};

const mapStateToProps = (state: IState) =>
	({
		rolePermissions: getRolePermissionsAsArray(state),
		problemStatus: extractProblemStatusesAsArray(state),
		checkListStatus: extractCheckRecordStatusesByEntityTypeAsArray(state, 'check-list'),
		checkItemStatus: extractCheckRecordStatusesByEntityTypeAsArray(state, 'check-item'),
		workAcceptanceStatus: extractWorkAcceptanceStatusesAsArray(state)
	}) as IPermissionsTableProps;

export {IPreparedPermission, TreeDataCtx, SemiSelectedCtx, StatusesCtx};
export default connect(mapStateToProps)(PermissionsTable);
