import {AppInfo} from '@tehzor/ui-components';
import config from '@src/core/config';

const {appName, appVersion} = config;

const AppSidebarInfo = () => (
	<AppInfo
		name={appName}
		version={appVersion}
		company="ООО «Цифровая Эволюция»"
		startYear={2017}
	/>
);

export default AppSidebarInfo;
