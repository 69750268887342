import {HTMLAttributes, createContext} from 'react';
import {IState} from '@src/store/modules';
import {connect} from 'react-redux';
import {getNotificationTypesAsArray} from '@src/selectors/notifications/types';
import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import {columns} from './columns';
import INotificationChannel from '@tehzor/tools/interfaces/INotificationChannel';
import INotificationInterval from '@tehzor/tools/interfaces/INotificationInterval';
import INotificationPeriod from '@tehzor/tools/interfaces/INotificationPeriod';
import {getNotificationChannelsAsArray} from '@src/selectors/notifications/channels';
import {getNotificationIntervalsAsArray} from '@src/selectors/notifications/intervals';
import {getNotificationPeriodsAsArray} from '@src/selectors/notifications/periods';
import {SimpleTable} from '@src/components/SimpleTable';
import {RestrictionProvider} from '@src/core/providers/SettingsProvider';

const ChannelsCtx = createContext<INotificationChannel[]>([]);

const IntervalsCtx = createContext<INotificationInterval[]>([]);

const PeriodsCtx = createContext<INotificationPeriod[]>([]);

interface ISubsTableProps {
	types: INotificationType[];
	channels: INotificationChannel[];
	intervals: INotificationInterval[];
	periods: INotificationPeriod[];
}

const SubsTable = ({types, channels, intervals, periods}: ISubsTableProps) => {
	const getTableRowProps = (): HTMLAttributes<HTMLTableRowElement> => ({
		className: 'role-page__subs-table-row'
	});

	return (
		<>
			<div className="field__wide-label-wrap">
				<div className="field__label">Подписки на уведомления по умолчанию:</div>
			</div>

			<RestrictionProvider>
				<ChannelsCtx.Provider value={channels}>
					<IntervalsCtx.Provider value={intervals}>
						<PeriodsCtx.Provider value={periods}>
							<SimpleTable
								data={types}
								columns={columns}
								pageCount={types.length}
								getTableRowProps={getTableRowProps}
							/>
						</PeriodsCtx.Provider>
					</IntervalsCtx.Provider>
				</ChannelsCtx.Provider>
			</RestrictionProvider>
		</>
	);
};

const mapStateToProps = (state: IState) =>
	({
		types: getNotificationTypesAsArray(state),
		channels: getNotificationChannelsAsArray(state),
		intervals: getNotificationIntervalsAsArray(state),
		periods: getNotificationPeriodsAsArray(state)
	}) as ISubsTableProps;

export {ChannelsCtx, IntervalsCtx, PeriodsCtx};
export default connect(mapStateToProps)(SubsTable);
