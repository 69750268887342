import {ColumnDef} from '@tanstack/react-table';
import {ExpanderCell} from './ExpanderCell';
import {NameCell} from './NameCell';
import {RoleCell} from './RoleCell';
import {IScopeItem} from './RolesTable';

export const columns: Array<ColumnDef<IScopeItem>> = [
	{
		id: '_expander',
		cell: ExpanderCell,
		size: 50
	},
	{
		id: 'name',
		cell: NameCell,
		minSize: 100
	},
	{
		id: 'role',
		cell: RoleCell,
		size: 350
	}
];
