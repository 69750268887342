import INotificationType from '@tehzor/tools/interfaces/notifications/INotificationType';
import Setting from './Setting';

export const Settings = <D,>({original}: {original: D}) => {
	const {id, availableSettings} = original as INotificationType;

	return availableSettings && availableSettings.length ? (
		<div className="role-page__settings role-page__subs-settings">
			<div className="role-page__settings-title">Дополнительные настройки:</div>

			{availableSettings.map(item => (
				<div
					key={item.key}
					className="role-page__settings-item"
				>
					<Setting
						typeId={id}
						settingKey={item.key}
						name={item.name}
						type={item.type}
					/>
				</div>
			))}
		</div>
	) : null;
};
