import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import {usePageReloadPrevent} from '@tehzor/tools/core/hooks/usePageReloadPrevent';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';
import {usePageLeavingBlock} from '@tehzor/ui-components';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {
	convertToSave,
	isEdited,
	useEditableProblemTemplatesSetState
} from '@src/core/hooks/states/useEditableProblemTemplatesSetState';
import EditableProblemTemplatesSet from '../EditableProblemTemplatesSet';
import {createUseDispatchContext} from '@src/core/hooks/createUseDispatchContext';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {getRegulatoryStandards} from '@src/store/modules/entities/regulatoryStandards/actions';
import {getObjectStages} from '@src/store/modules/entities/objectStages/actions';

export const {DispatchContext, useDispatchContext} = createUseDispatchContext<
	ISavingProblemTemplatesSet,
	IProblemTemplatesSet
>();

const fieldsSettings = {
	name: {fieldId: 'name', isRequired: true},
	companies: {fieldId: 'companies', isRequired: true},
	data: {fieldId: 'data', isRequired: true},
	shared: {fieldId: 'shared', isRequired: true}
};

/**
 * Логика редактирования и сохранения набора шаблонов нарушений (нового или существующего)
 */
export const useEditableProblemTemplatesSet = (
	problemTemplatesSet: IProblemTemplatesSet | undefined,
	saving: boolean
): [React.ReactNode, () => ISavingProblemTemplatesSet | undefined, (
) => void, boolean] => {
	const dispatch = useAppDispatch();
	const [editingState, editingDispatch] = useEditableProblemTemplatesSetState(problemTemplatesSet);
	const [isBlocking, setIsBlocking] = useState(false);

	usePageLeavingBlock('problemTemplatesSet', isBlocking, undefined, 'Все введённые данные будут потеряны');
	usePageReloadPrevent(isBlocking);

	useEffect(() => {
		void dispatch(getRegulatoryStandards());
		void dispatch(getObjectStages());
	}, []);

	useEffect(() => {
		setIsBlocking(isEdited(editingState, problemTemplatesSet));
	}, [editingState, problemTemplatesSet]);

	const getSavingData = useCallback(() => {
		if (!isEdited(editingState, problemTemplatesSet)) {
			return undefined;
		}

		return convertToSave(editingState, problemTemplatesSet, true);
	}, [editingState, problemTemplatesSet, fieldsSettings]);

	const reset = useCallback(() => {
		editingDispatch({
			type: 'reset',
			entity: problemTemplatesSet
		});
	}, [problemTemplatesSet]);

	// Сброс данных для редактирования при изменении начальных данных
	useUpdateEffect(reset, [problemTemplatesSet]);

	const fields = (
		<DispatchContext.Provider value={editingDispatch}>
			<EditableProblemTemplatesSet
				editingState={editingState}
				editingDispatch={editingDispatch}
				fieldsSettings={fieldsSettings}
				saving={saving}
			/>
		</DispatchContext.Provider>
	);

	return [fields, getSavingData, reset, isBlocking];
};
