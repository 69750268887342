import { HTMLAttributes, useMemo } from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {
	getLogRecordsAsArray,
	getLogRecordsCount,
	getLogRecordsOffset,
	getLogRecordsPageSize
} from '@src/selectors/entities/logRecords';
import {buildRecordsColumns} from './buildRecordsColumns';
import {SimpleTable} from '@src/components/SimpleTable';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {changeLogRecordsOffset, getLogRecords} from '@src/actions/entities/logRecords';
import {getLogRecordTypesById} from '@src/selectors/entities/logRecordTypes';
import {getUsersById} from '@src/selectors/entities/users';
import {Row} from '@tanstack/react-table';
import {useLogDialog} from '@src/pages/LogPage/hooks/useLogDialog';
import {logSettingsActions} from '@src/store/modules/settings/pages/log/logSettingsSlice';
import {convertRecords, IPreparedLogRecord} from '../../utils/convertRecords';

const pageSizes = [10, 20, 50, 100];

export const RecordsTable = () => {
	const dispatch = useAppDispatch();
	const records = useAppSelector(getLogRecordsAsArray);
	const typesMap = useAppSelector(getLogRecordTypesById);
	const usersMap = useAppSelector(getUsersById);
	const pageSize = useAppSelector(getLogRecordsPageSize);
	const offset = useAppSelector(getLogRecordsOffset);
	const total = useAppSelector(getLogRecordsCount);
	const pagesCount = Math.ceil(total / pageSize) || -1;
	const currentPage = Math.floor(offset / pageSize);

	const [logDialog, openLogDialog] = useLogDialog();

	const changePage = (page: number) => {
		if (offset !== page * pageSize) {
			dispatch(changeLogRecordsOffset(page * pageSize));
			dispatch(getLogRecords());
		}
	};
	const changePageSize = (size: number) => {
		if (pageSize !== size) {
			const newOffset = Math.floor(offset / size);
			dispatch(logSettingsActions.setPageSize(size));
			dispatch(changeLogRecordsOffset(newOffset));
			dispatch(getLogRecords());
		}
	};

	const handleRowClick = (row: Row<IPreparedLogRecord>) => {
		const {error, fields} = row.original;
		if (error || fields) openLogDialog(row);
	};

	const getLogRecordsTableRowProps = (
		row: Row<IPreparedLogRecord>
	): HTMLAttributes<HTMLTableRowElement> => {
		const {error} = row.original;
		const className = error ? 'log-page__error-row' : '';
		return {className, onClick: () => handleRowClick(row)};
	};

	const data = useMemo(
		() => convertRecords(records, typesMap, usersMap),
		[records, typesMap, usersMap]
	);
	const columns = useMemo(() => buildRecordsColumns, []);

	return (
		<>
			<SimpleTable
				columns={columns}
				data={data}
				pageSize={pageSize}
				currentPage={currentPage}
				pageCount={pagesCount}
				pageSizeOptions={pageSizes}
				onPageChange={changePage}
				onPageSizeChange={changePageSize}
				getTableRowProps={getLogRecordsTableRowProps}
			/>
			{logDialog}
		</>
	);
};
