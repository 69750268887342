import {createContext, ReactNode, useMemo, useState} from 'react';

interface ISettingsIdsCtx {
	hasId: (id: string) => boolean;
	toggleId: (id: string) => void;
}

/**
 * Контекст нужен для определения какие сейчас настройки активны
 */
export const SettingsCtx = createContext<ISettingsIdsCtx>({} as ISettingsIdsCtx);

export const RestrictionProvider = ({children}: {children: ReactNode}) => {
	const [settings, setSettings] = useState<string[]>([]);

	const ctx = useMemo<ISettingsIdsCtx>(
		() => ({
			hasId: (id: string) => settings.includes(id) ?? false,
			toggleId: (id: string) => {
				if (settings.includes(id)) {
					setSettings(settings.filter(settingsId => settingsId !== id));
				} else {
					setSettings([...settings, id]);
				}
			}
		}),
		[settings, setSettings]
	);

	return <SettingsCtx.Provider value={ctx}>{children}</SettingsCtx.Provider>;
};
