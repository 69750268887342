import {ISavingObject} from '@src/interfaces/saving/ISavingObject';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IAddObjectResponse = IObject;

/**
 * Добавляет объект
 *
 * @param object данные объекта
 */

export const makeObjectAddRequest = async (object: ISavingObject) => {
	const response = await httpRequests.withToken.post<IAddObjectResponse>('/objects', object);
	return response.data;
};
