import {forwardRef, ForwardedRef} from 'react';
import TextField, {ITextFieldProps} from './TextField';

const TextFieldWithForwardedRef = forwardRef(
	(
		props: ITextFieldProps,
		ref?: ForwardedRef<HTMLDivElement | HTMLInputElement | HTMLTextAreaElement>
	) => <TextField {...props} elementRef={ref} />
);

export default TextFieldWithForwardedRef;
