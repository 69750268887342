import {Navigate, RouteProps, useLocation} from 'react-router-dom';
import ActivationWaiting from '@src/components/ActivationWaiting';
import useAppSelector from '@src/core/hooks/useAppSelector';

/**
 * Обёртка для Route, которая проверяет авторизован ли пользователь
 */
export const PrivateRoute = (props: RouteProps) => {
	const path = useLocation().pathname;
	const {element} = props;
	const isAuthenticated = useAppSelector(s => s.auth.isAuthenticated);
	const isActivated = useAppSelector(s => s.auth.profile.activated);

	if (isAuthenticated) {
		if (isActivated && element) {
			return element as JSX.Element;
		}
		return <ActivationWaiting/>;
	}
	return (
		<Navigate
			to="/login"
			state={{redirect: path}}
		/>
);
};
