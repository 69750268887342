import { PureComponent } from 'react';
import {Pagination, PaginationPageSize} from '../../pagination';

interface IPaginationProps {
	showPageSizeOptions: boolean;
	page: number;
	pages: number;
	pageSize: number;
	pageSizeOptions?: number[];
	pageRangeDisplayed?: number;
	marginPagesDisplayed?: number;

	onPageChange(page: number): void;

	onPageSizeChange(pageSize: number): void;
}

class TablePagination extends PureComponent<IPaginationProps> {
	static displayName = 'TablePagination';

	render() {
		const {
			showPageSizeOptions,
			page,
			pages,
			pageSize,
			pageSizeOptions,
			pageRangeDisplayed = 3,
			marginPagesDisplayed = 1,
			onPageSizeChange
		} = this.props;

		return (
			<div className="table__pagination">
				{showPageSizeOptions && pageSizeOptions && (
					<PaginationPageSize
						pageSize={pageSize}
						pageSizeOptions={pageSizeOptions}
						onPageSizeChange={onPageSizeChange}
					/>
				)}

				{pages > 1 && (
					<Pagination
						className="table__pagination-paginate"
						pageCount={pages}
						pageRangeDisplayed={pageRangeDisplayed}
						marginPagesDisplayed={marginPagesDisplayed}
						forcePage={page}
						onPageChange={this._handlePageChange}
					/>
				)}

			</div>
		);
	}

	private _handlePageChange = (selectedItem: { selected: number }) => {
		const {onPageChange} = this.props;
		onPageChange(selectedItem.selected);
	};
}

export default TablePagination;
