import {CSSProperties} from 'react';
import './AdjacentPageLinks.less';
import APLButton from './components/APLButton';
import LinkButton from '../buttons/LinkButton';
import classNames from 'classnames';

const arrowIcon = <i className="tz-long-arrow-24 adjacent-page-links__back-link-icon" />;

interface IPageLink {
	url: string;
	label: string;
}

export interface IAdjacentPageLinksProps {
	className?: string;
	style?: CSSProperties;
	showBackLink?: boolean;
	backLinkTitle?: string;
	pageLinks?: IPageLink[];
	currentUrl: string;

	onBack?(): void;

	onNavigate?(url: string): void;
}

const AdjacentPageLinks = (props: IAdjacentPageLinksProps) => {
	const {
		className,
		style,
		showBackLink = true,
		backLinkTitle = 'Назад',
		pageLinks,
		currentUrl,
		onBack,
		onNavigate
	} = props;

	return (
		<div
			className={classNames('adjacent-page-links', className)}
			style={style}
		>
			{showBackLink && (
				<LinkButton
					className="adjacent-page-links__back-link"
					label={backLinkTitle}
					leftIcon={arrowIcon}
					onClick={onBack}
				/>
			)}

			{pageLinks?.map((link, index) => (
				<APLButton
					key={index}
					className="adjacent-page-links__link"
					url={link.url}
					label={link.label}
					isActive={link.url === currentUrl}
					onClick={onNavigate}
				/>
			))}
		</div>
	);
};

AdjacentPageLinks.displayName = 'AdjacentPageLinks';

export default AdjacentPageLinks;
