import {TypesFilter} from './TypesFilter';
import {UsersFilter} from './UsersFilter';
import {Button} from '@tehzor/ui-components';
import {WithErrorFilter} from './WithErrorFilter';
import {DateFilter} from '@src/components/DateFilter';
import {useLogFilters} from '@src/pages/LogPage/hooks/useLogFilters';

export const Filters = () => {
	const {
		filterState,
		onLogTypesChange,
		onUsersChange,
		onDateChange,
		onErrorChange,
		onClearFilter,
		hasLogFilters
	} = useLogFilters();

	return (
		<div className="log-page__filters-container">
			<TypesFilter
				logTypesIds={filterState.types}
				changeFilter={onLogTypesChange}
			/>

			<UsersFilter
				usersIds={filterState.users}
				changeFilter={onUsersChange}
			/>

			<WithErrorFilter
				changeFilter={onErrorChange}
				withErrors={filterState.withError}
			/>

			<DateFilter
				label="Дата"
				changeFilters={onDateChange}
				to={filterState.dateTo}
				from={filterState.dateFrom}
			/>
			{hasLogFilters && (
				<Button
					onClick={onClearFilter}
					label="Сбросить"
					type="cancel"
				/>
			)}
		</div>
	);
};
