import { useContext } from 'react';
import {IStatuses, StatusesCtx} from '../PermissionsTable';
import SelectableParam from './SelectableParam';

export interface IParamProps {
	id: string;
	name: string;
	dataType: string;
	permissionId: string;
}

const Param = (props: IParamProps) => {
	const {dataType} = props;
	const paramsData = useContext(StatusesCtx);
	const items = paramsData[dataType] as IStatuses[];

	switch (dataType) {
		case 'problemStatus' || 'checkListStatus' || 'checkItemStatus' || 'workAcceptanceStatus':
			return (
				<SelectableParam
					{...props}
					items={items}
				/>
			);
		default:
			return null;
	}
};

export default Param;
