import {EditorInfo, EntityGrid, Plate} from '@tehzor/ui-components';
import ICompany from '@tehzor/tools/interfaces/companies/ICompany';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUser} from '@src/selectors/entities/users';

interface ILeftSideProps {
	company?: ICompany;
}

const LeftSide = ({company}: ILeftSideProps) => {
	const createdBy = useAppSelector(s => extractUser(s, company?.createdBy));
	const modifiedBy = useAppSelector(s => extractUser(s, company?.modifiedBy));

	if (!company) {
		return <Plate/>
	}

	return (
		<Plate>
			<EntityGrid>
				<EditorInfo
					icon={<i className="tz-document-20"/>}
					label="Создана"
					date={company.createdAt}
					user={createdBy}
					fullRow
				/>
				{company.createdAt !== company.modifiedAt && (
					<EditorInfo
						icon={<i className="tz-edit-20"/>}
						label="Изменена"
						date={company.modifiedAt}
						user={modifiedBy}
						fullRow
					/>
				)}
			</EntityGrid>
		</Plate>
	);
};

export default LeftSide;
