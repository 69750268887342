import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import {CellContext} from '@tanstack/react-table';
import {IPreparedPermission} from '@src/pages/RolePage/components/permissions/PermissionsTable';
import {useCallback, useContext} from 'react';
import {SettingsCtx} from '@src/core/providers/SettingsProvider';

const icon = <i className="tz-settings-24 role-page__ptable-rest-expander-icon" />;

const isHasNoChildren = (original: IPreparedPermission) =>
	!original.children || !original.children.length;

const isHasAvailableRestrictions = (original: IPreparedPermission) =>
	original.availableRestrictions && original.availableRestrictions.length;

const isHasAvailableParams = (original: IPreparedPermission) =>
	original.availableRestrictions && original.availableRestrictions.length;

export const RestExpanderCell = ({row}: CellContext<IPreparedPermission, IPreparedPermission>) => {
	const original = row.original;
	const {toggleId} = useContext(SettingsCtx);

	const handleClick = useCallback(() => toggleId(original.id), [toggleId, original.id]);

	return isHasNoChildren(original) &&
		(isHasAvailableParams(original) || isHasAvailableRestrictions(original)) ? (
		<IconButton
			onClick={handleClick}
			type="transparent"
			className={classNames('role-page__ptable-rest-expander-btn', {
				'role-page__ptable-rest-expander-btn_open': row.getIsExpanded()
			})}
		>
			{icon}
		</IconButton>
	) : null;
};
