import {memo, useCallback, useEffect} from 'react';
import {ActionButtons, Button, TabContent, TabLink, Tabs} from '@tehzor/ui-components';
import {EditableObjectForm} from '../EditableObjectForm/EditableObjectForm';
import {useObjectForm} from '../../utils/useObjectForm';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {addObject, editObject, getObject} from '@src/store/modules/entities/object/actions';
import {convertToSave} from '../../utils/convertToSave';
import {getProfile} from '@src/store/modules/auth/profile/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractUserRoles} from '@src/store/modules/auth/profile/selectors';
import {getObjectData, getObjectEdited} from '@src/store/modules/entities/object/selectors';
import {usePermissions} from '../../utils/usePermissions';
import GlobalUploader from '@tehzor/tools/core/GlobalUploader';
import {useChangePath} from '@src/core/hooks/useChangePath';
import FieldsSettings from '../fieldsSettings/FieldsSettings';

interface IEditableObjectCardProps {
	objectId?: string;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const globalUploader = GlobalUploader.instance;
export const EditableObjectCard = memo(({objectId}: IEditableObjectCardProps) => {
	const dispatch = useAppDispatch();
	const {replacePath, goBack} = useChangePath();
	const userRoles = useAppSelector(extractUserRoles);
	const objectData = useAppSelector(getObjectData);
	const edited = useAppSelector(getObjectEdited);
	const permissions = usePermissions(userRoles, objectId);
	const fieldsDisabled = objectId ? !permissions.canEdit : !permissions.canAdd;
	const {formState, isValid, validateErrors, methods} = useObjectForm();

	useEffect(() => {
		if (!objectId) {
			dispatch(objectActions.clearObject());
		}
	}, [dispatch, objectId]);

	const [, save] = useAsyncFn(async () => {
		if (objectId) {
			await dispatch(editObject(objectId, convertToSave(formState, objectData, objectId)));
			await dispatch(getObject(objectId));
			await dispatch(getProfile());
		} else {
			const savedObject = await dispatch(addObject(convertToSave(formState, objectData)));
			await dispatch(getObject(savedObject.id));
			await dispatch(getProfile());
			replacePath(`/objects/${savedObject.id}`);
		}
	}, [dispatch, formState, objectData, objectId]);

	const cancel = useCallback(() => {
		if (formState.newImage?.length) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			globalUploader.deleteFile(formState.newImage[0].key);
		}
		if (objectId) {
			dispatch(objectActions.resetObject());
		} else {
			goBack();
		}
	}, [dispatch, formState, objectId]);
	return (
		<div className="panel object-page__panel">
			<Tabs
				className={{links: 'role-page__tabs-links'}}
				links={[
					<TabLink label="Основная информация" />,
					<TabLink label="Настраиваемые поля" />
				]}
			>
				<TabContent>
					<EditableObjectForm
						validateErrors={validateErrors}
						objectId={objectId}
						formState={formState}
						{...methods}
					/>
				</TabContent>
				<TabContent>
					<FieldsSettings value={formState?.fieldsSettings} />
				</TabContent>
			</Tabs>
			{(!objectId || edited) && (
				<ActionButtons className="object-page__buttons">
					<Button
						type="accent-blue"
						label="Сохранить"
						disabled={fieldsDisabled || !isValid}
						onClick={save}
					/>
					<Button
						type="cancel"
						label="Отменить"
						onClick={cancel}
					/>
				</ActionButtons>
			)}
		</div>
	);
});
