import * as types from '../constants';
import IError from '@tehzor/tools/interfaces/IError';
import {createApiAction} from '@src/store/middlewares/api';
import {IGetObjectResponse, requestObject} from '@src/api/backend/object';
import {createAction} from '@reduxjs/toolkit';
import {addErrorToast} from '@tehzor/ui-components';

export type IGetObjectPayload = IGetObjectResponse;

export const getObjectRequest = createAction(types.GET_REQUEST);
export const getObjectSuccess = createAction<IGetObjectResponse>(types.GET_SUCCESS);
export const getObjectFailure = createAction(types.GET_FAILURE, (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при загрузке объекта'});
	}
	return {
		payload: {...error}
	};
});

/**
 * Получает объект
 *
 * @param objectId id объекта
 */
export const getObject = (objectId: string) =>
	createApiAction<IGetObjectResponse>(getObjectRequest, getObjectSuccess, getObjectFailure, () =>
		requestObject(objectId)
	);
