import {httpRequests} from '@tehzor/tools/api/httpRequests';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {ISavingProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/ISavingProblemTemplatesSet';

export type IAddTemplatesSetResponse = IProblemTemplatesSet;

/**
 * Добавляет набор шаблонов нарушений
 */

export const makeAddTemplatesSetRequest = async (data: ISavingProblemTemplatesSet) => {
	const response = await httpRequests.withToken.post<IAddTemplatesSetResponse>(
		'problem-templates-sets',
		{
			...data
		}
	);
	return response.data;
};
