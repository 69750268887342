import {CSSProperties, ReactNode, useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {SelectDialog, TextField} from '../../index';
import Tree, {ITreeDataItem} from '../../../Tree';
import Select from '../../select/Select';
import SelectSearch, {treeFilter} from '../../select/SelectSearch';
import {useUpdateEffect} from 'react-use';

export interface ITreeSetDataItem extends ITreeDataItem {
	categoriesSetId?: string;
	total?: number;
}

interface ICategoriesSetSelectProps {
	className?: string;
	style?: CSSProperties;
	value?: string[];
	treeData: ITreeSetDataItem[];
	disabled?: boolean;
	multiple?: boolean;
	dialogTitle?: string;
	placeholder?: string;
	searchPlaceholder?: string;
	dialogAcceptBtnLabel?: string;
	dialogRejectBtnLabel?: string;
	error?: string;
	icon?: ReactNode;
	treeCheckOnlyChildren?: boolean;

	onChange?(value: string[]): void;
}

const categoryIcon = <i className="tz-category-20" />;

const getTitle = (treeData: ITreeSetDataItem[], value: string[]) => {
	if (!value.length || treeData === undefined) {
		return '';
	}
	const selected = value.map(id => treeData.find(item => item.id === id));
	const sets = treeData.filter(item => !item?.categoriesSetId);

	let title = '';
	let titleCategories = selected.filter(item => item?.categoriesSetId);
	const titleSets: ITreeSetDataItem[] = [];

	for (const set of sets) {
		if (!set?.total || set?.disabled) {
			continue;
		}

		const categories = selected.filter(item => item?.categoriesSetId === set.id);

		if (!categories.length) {
			continue;
		}

		if (categories.length >= set?.total) {
			titleCategories = titleCategories.filter(item => item?.categoriesSetId !== set.id);
			titleSets.push(set);
		}
	}

	title += titleSets.map(item => item.content).join(', ');

	title += titleCategories.map(item => item?.content).join(', ');

	return title;
};

const getAllParents = (id: string, treeData: ITreeSetDataItem[]): string[] => {
	const item = treeData.find(item => item.id === id);

	if (!item?.parentId) {
		return [];
	}
	return [item.parentId].concat(getAllParents(item.parentId, treeData));
};

const getInitialExpanded = (checked: string[], treeData: ITreeSetDataItem[]): string[] => {
	const keys: string[] = [];
	for (const id of checked) {
		if (!treeData.find(item => item.id === id)) continue;
		const parentIds = getAllParents(id, treeData);

		for (const parentId of parentIds) {
			if (!keys.includes(parentId)) {
				keys.push(parentId);
			}
		}
	}
	return keys;
};

export const CategorySelect = ({
	className,
	style,
	value,
	treeData,
	disabled = false,
	multiple = false,
	dialogTitle = 'Вид работ',
	dialogAcceptBtnLabel,
	dialogRejectBtnLabel,
	searchPlaceholder,
	placeholder,
	error,
	icon,
	treeCheckOnlyChildren = false,
	onChange
}: ICategoriesSetSelectProps) => {
	const [checked, setChecked] = useState<string[]>(value || []);
	const [search, setSearch] = useState('');
	const [expanded, setExpanded] = useState<string[] | undefined>(
		getInitialExpanded(checked, treeData)
	);

	useUpdateEffect(() => {
		setChecked(value || []);
	}, [value]);

	const {filteredData} = useMemo(
		() => treeFilter(treeData, 'content', search),
		[search, treeData]
	);

	const clearSearch = useCallback(() => setSearch(''), []);

	const handleExpanded = useCallback((value: string[]) => {
		setExpanded(value);
	}, []);

	const handleChange = useCallback(() => {
		clearSearch();
		const arr = treeData
			.filter(item => checked.includes(item.id) && item.parentId)
			.map(item => item.id);
		onChange?.(arr);
	}, [checked, clearSearch, onChange, treeData]);

	const classes = classNames('categories-set-select', className);
	const triggerClasses = classNames(
		'categories-set-select__field',
		{'categories-set-select__field-disabled': disabled},
		'text-field_interactive'
	);

	const title = useMemo(() => getTitle(treeData, checked), [treeData, checked]);

	const trigger = (
		<TextField
			className={triggerClasses}
			elementType="div"
			value={title}
			disabled={disabled}
			placeholder={placeholder}
			error={error}
			icon={icon !== undefined ? icon : categoryIcon}
		/>
	);

	return (
		<div className={classes} style={style}>
			{!disabled ? (
				<SelectDialog
					dataTestId="CategorySelectDialog"
					title={dialogTitle}
					trigger={trigger}
					acceptBtnLabel={dialogAcceptBtnLabel}
					rejectBtnLabel={dialogRejectBtnLabel}
					onChange={handleChange}
					onClose={clearSearch}
				>
					<Select className="categories-set-select__dialog">
						<SelectSearch
							value={search}
							onChange={setSearch}
							placeholder={searchPlaceholder}
						/>
						<Tree
							data={filteredData}
							checkedValue={checked}
							onCheck={setChecked}
							expandedValue={expanded}
							onExpand={handleExpanded}
							multiple={multiple}
							isCheckable
							checkOnlyChildren={treeCheckOnlyChildren}
						/>
					</Select>
				</SelectDialog>
			) : (
				trigger
			)}
		</div>
	);
};
