import { useCallback, useRef } from 'react';
import AppSidebarMenu from './AppSidebarMenu';
import AppSidebarInfo from './AppSidebarInfo';
import {MobileSidebar, UserButton} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {useLocation} from 'react-router-dom';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {toggleMobileSidebarVisibility} from '@src/store/modules/app/sidebar/actions';
import {useChangePath} from '@src/core/hooks/useChangePath';

const MobileAppSidebar = () => {
	const user = useAppSelector(s => s.auth.profile);
	const {pushPath} = useChangePath();
	const visible = useAppSelector(s => s.app.sidebar.mobile.visible);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const ref = useRef<MobileSidebar>(null);

	const close = useCallback(async () => {
		if (ref.current) {
			await ref.current.close();
		}
	}, []);

	const goToProfile = useCallback(async () => {
		await close();
		if (location.pathname !== '/profile') {
			pushPath('/profile');
		}
	}, [location.pathname]);

	const handleVisibilityChange = useCallback((value: boolean) => {
		dispatch(toggleMobileSidebarVisibility(value));
	}, []);

	return (
		<MobileSidebar
			className="app-sidebar app-sidebar_mobile"
			menu={(
				<AppSidebarMenu
					type="mobile"
					closeSidebar={close}
				/>
			)}
			userInfo={(
				<UserButton
					user={user}
					avatarSize="56"
					onClick={goToProfile}
				/>
			)}
			appInfo={<AppSidebarInfo/>}
			visible={visible}
			onVisibilityChange={handleVisibilityChange}
			ref={ref}
		/>
	);
};

export default MobileAppSidebar;
