import Param from './Param';
import IRolePermission from '@tehzor/tools/interfaces/IRolePermission';

interface IRestrictionsProps {
	permissionId: string;
	params: NonNullable<IRolePermission['availableParams']>;
	level?: number;
}

const rowIdent = 50;

export const Params = ({permissionId, params, level = 0}: IRestrictionsProps) => (
	<div
		className="role-page__settings"
		style={{marginLeft: `${level * rowIdent}px`}}
	>
		<div className="role-page__settings-title">Параметры:</div>

		{params.map(item => (
			<div
				key={item.id}
				className="role-page__settings-item"
			>
				<Param
					{...item}
					permissionId={permissionId}
				/>
			</div>
		))}
	</div>
);
