import './ProblemTemplatesSetPage.less';
import AppBreadcrumbs from '@src/components/AppBreadcrumbs';
import {IBreadcrumb} from '@tehzor/ui-components/src/components/navigation/Breadcrumbs';
import {ActionButtons, Button, EditorInfo, EntityGrid, Plate, LoadingPanel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractTemplatesSet} from '@src/store/modules/entities/problemTemplatesSet/selectors';
import {useEditableProblemTemplatesSet} from '@src/components/EditableProblemTemplatesSet/hooks/useEditableProblemTemplatesSet';
import {useAppHeader} from '@src/components/AppHeader/hooks/useAppHeader';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useUnmount from 'react-use/lib/useUnmount';
import {
	addTemplatesSet,
	editTemplatesSet,
	getTemplatesSet
} from '@src/store/modules/entities/problemTemplatesSet/actions';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {useAsync} from 'react-use';
import {RESET} from '@src/store/modules/entities/problemTemplatesSet/constants';
import {useStrictParams} from '@src/core/hooks/useStrictParams';
import {useChangePath} from '@src/core/hooks/useChangePath';

const breadcrumbs: IBreadcrumb[] = [];

// eslint-disable-next-line no-shadow
enum ControllerMode {
	New = 'new-problem-template-set',
	Edit = 'edit-problem-template-set'
}

/**
 * Один контроллер на два случая — создание нового шаблона нарушений и редактирование существующего
 */
export const ProblemTemplatesSetPage = () => {
	const {templatesSetId} = useStrictParams<{templatesSetId: string}>();
	const dispatch = useAppDispatch();
	const item = useAppSelector(extractTemplatesSet);
	const [fields, getSavingData] = useEditableProblemTemplatesSet(item, false);
	const {replacePath} = useChangePath();

	const mode = templatesSetId && templatesSetId !== 'add' ? ControllerMode.Edit : ControllerMode.New;

	// Для улучшения UX сбрасываем данные стора при выходе со страницы, иначе получаем мигание устаревших данных при повторном посещении
	useUnmount(() => dispatch({type: RESET}));

	useAsync(async () => {
		if (mode === ControllerMode.Edit) {
			await dispatch(getTemplatesSet(templatesSetId));
		}
	}, [templatesSetId]);

	useAppHeader(
		{
			title: mode === ControllerMode.New ? 'Новый набор шаблонов нарушений' : item.name,
			showBackBtn: true
		},
		[item.name, mode]
	);

	const [savingState, save] = useAsyncFn(async () => {
		const savingData = getSavingData();

		if (!savingData) return;

		if (mode === ControllerMode.Edit) {
			await dispatch(editTemplatesSet(item.id, savingData));
		} else {
			const response = await dispatch(addTemplatesSet(savingData));

			// setTimeout нужен для корректной работы с history API из асинхронности
			if (response && response.id) setTimeout(() => replacePath(`/problem-templates-sets/${response.id}`), 10);
		}
	}, [getSavingData, templatesSetId]);

	// Пока данные не загружены, показываем заглушку для улучшения UX
	if (mode === ControllerMode.Edit && !item.id) {
		return (
			<LoadingPanel
				active
				className="templates-set-page__loading-panel"
			/>
);
	}

	return (
		<div className="page-cont templates-set-page">
			{breadcrumbs && <AppBreadcrumbs items={breadcrumbs}/>}

			{item.createdAt && (
				<div className="templates-set-page__entity_info">
					<Plate>
						<EntityGrid>
							<EditorInfo
								icon={<i className="tz-document-20"/>}
								label="Создано"
								date={item.createdAt}
								user={item.createdBy}
								fullRow
							/>
							{item.createdAt !== item.modifiedAt && (
								<EditorInfo
									icon={<i className="tz-edit-20"/>}
									label="Изменено"
									date={item.modifiedAt}
									user={item.modifiedBy}
									fullRow
								/>
							)}
						</EntityGrid>
					</Plate>
				</div>
			)}

			<div className="templates-set-page__entity_fields">
				<Plate>
					{fields}

					<ActionButtons>
						<Button
							type="accent-blue"
							label={mode === ControllerMode.Edit ? 'Сохранить' : 'Добавить'}
							disabled={savingState.loading}
							onClick={save}
						/>
					</ActionButtons>
				</Plate>
			</div>
		</div>
	);
};
