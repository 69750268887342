import { memo, useState } from 'react';
import {FilterButton, Select2, SelectOption, SelectPopup} from '@tehzor/ui-components';
import {makeFilterLabel} from '@src/utils/makeFiltersLabel';
import useUpdateEffect from 'react-use/lib/useUpdateEffect';

interface IHasErrorFilterProps {
	withErrors?: boolean;
	changeFilter: (value: boolean | undefined) => void;
}

const LABEL = 'Ошибки';

export const WithErrorFilter = memo(({withErrors, changeFilter}: IHasErrorFilterProps) => {
	const [selectedError, setSelectedError] = useState('');

	const hasErrors = selectedError === 'С ошибками';
	const selectValue = hasErrors ? 'С ошибками' : 'Без ошибок';

	useUpdateEffect(() => {
		setSelectedError(withErrors === undefined ? '' : selectValue);
	}, [withErrors]);

	const handleApply = () => {
		if (selectedError) {
			changeFilter(hasErrors);
		}
	};

	const handleCancel = () => {
		if (withErrors) {
			setSelectedError(selectValue);
		}
	};

	const handleFullClear = () => {
		setSelectedError('');
		changeFilter(undefined);
	};

	const errorLabel = withErrors !== undefined ? (withErrors ? 'С ошибками' : 'Без ошибок') : undefined;
	return (
		<SelectPopup
			footer
			onCancel={handleCancel}
			onApply={handleApply}
			trigger={(
				<FilterButton
					label={makeFilterLabel(LABEL, errorLabel)}
					onClear={handleFullClear}
					active={withErrors !== undefined}
				/>
			)}
		>
			<Select2
				value={selectedError}
				onChange={setSelectedError}
			>
				<SelectOption
					itemKey="С ошибками"
					inputType="radio"
					content="С ошибками"
				/>
				<SelectOption
					itemKey="Без ошибок"
					inputType="radio"
					content="Без ошибок"
				/>
			</Select2>
		</SelectPopup>
	);
});
