import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import IUser from '@tehzor/tools/interfaces/IUser';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetUsersResponse = INormalizedData<IUser>;

/**
 * Получает список пользователей
 */
export const requestUsers = async () => {
	const response = await httpRequests.withToken.get<IGetUsersResponse>('users');
	return response.data;
};
