export const handleDownloadS3File = async (url: string, fileName: string) => {
	const response = await fetch(url, {
		method: 'GET'
	});

	if (!response.ok) {
		throw new Error(`Ошибка при скачивании файла: ${response.statusText}`);
	}

	const blob = await response.blob();

	const downloadUrl = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = downloadUrl;
	a.download = fileName;
	a.click();

	URL.revokeObjectURL(downloadUrl);
	a.remove();
};
