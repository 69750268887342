import {IGetOneUserResponse, requestUser} from '@src/api/backend/users/getOne';
import {ApiAction, createApiAction} from '@src/store/middlewares/api';
import {IState} from '@src/store/modules';
import IError from '@tehzor/tools/interfaces/IError';
import checkExpiration from '@tehzor/tools/utils/checkExpiration';
import * as types from '../constants';
import {addErrorToast} from '@tehzor/ui-components';

export type IGetOneUserPayload = IGetOneUserResponse;

const request = () => ({type: types.GET_ONE_REQUEST});

const success = (response: IGetOneUserResponse) => ({
	type: types.GET_ONE_SUCCESS,
	payload: response
});

const failure = (error: IError) => {
	if (error.statusCode !== 401) {
		addErrorToast({title: 'Ошибка при загрузке пользователя'});
	}
	return {
		type: types.GET_ONE_FAILURE,
		payload: {...error}
	};
};

export const getOneUser = (userId: string) =>
	checkExpiration<IState, IGetOneUserResponse, ApiAction>(
		s => s.entities.user,
		createApiAction<IGetOneUserResponse>(request, success, failure, () => requestUser(userId))
	);
