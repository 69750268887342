import { useCallback, useContext } from 'react';
import {TextField} from '@tehzor/ui-components';
import {UserDispatchCtx} from '../../UserPage';
import {ElementPropsType} from '@tehzor/ui-components/src/components/inputs/TextField/TextField';

interface ICommonTextFieldProps {
	label: string;
	value: string;
	field: string;
	disabled?: boolean;
	inputProps?: ElementPropsType;
}

const CommonTextField = ({label, value, field, disabled, inputProps}: ICommonTextFieldProps) => {
	const dispatch = useContext(UserDispatchCtx);

	const onChange = useCallback((v: string) => {
		dispatch({type: 'update', field, value: v});
	}, []);

	return (
		<div className="field">
			<div className="field__label">
				{label}
				:
			</div>

			<TextField
				className="user-page__field"
				elementType="input"
				elementProps={inputProps}
				disabled={disabled}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

export default CommonTextField;
