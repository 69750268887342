import INormalizedData from '@tehzor/tools/interfaces/INormalizedData';
import {IProblemTemplatesSet} from '@tehzor/tools/interfaces/problemTemplatesSets/IProblemTemplatesSet';
import {httpRequests} from '@tehzor/tools/api/httpRequests';

export type IGetTemplatesSetsResponse = INormalizedData<IProblemTemplatesSet>;

/**
 * Возвращает список наборов шаблонов нарушений
 */
export const requestTemplatesSets = async () => {
	const response = await httpRequests.withToken.get<IGetTemplatesSetsResponse>(
		'problem-templates-sets'
	);
	return response.data;
};
