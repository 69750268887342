import { memo } from 'react';
import {CellContext} from '@tanstack/react-table';
import IRegulatoryStandard from '@tehzor/tools/interfaces/IRegulatoryStandard';

export const LinkCell = memo(({row}: CellContext<IRegulatoryStandard, IRegulatoryStandard>) => {
	const regulatoryStandardLink = row.original.link;
	if (!regulatoryStandardLink) return null;
	return (
		<a
			href={regulatoryStandardLink}
			target="_blank"
			rel="noreferrer"
		>
			Посмотреть
		</a>
	);
});
