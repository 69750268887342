import {useContext} from 'react';
import * as React from 'react';
import {RoleDispatchCtx, RolePermissionsCtx} from '../../RolePage';
import {Checkbox} from '@tehzor/ui-components';
import {IPreparedPermission, SemiSelectedCtx, TreeDataCtx} from './PermissionsTable';
import IPermission from '@tehzor/tools/interfaces/IPermission';
import {CellContext} from '@tanstack/react-table';

/**
 * Возвращает id всех дочерних элементов
 *
 * @param {IPreparedPermission} perm полномочие
 */
const getChildrenIds = (perm: IPreparedPermission): string[] => {
	if (perm.children) {
		return perm.children.reduce((acc, item) => acc.concat(getChildrenIds(item)), [
			perm.id
		] as string[]);
	}
	return [perm.id];
};

/**
 * Обновляет массив выделенных элементов,
 * добавляя или удаляя родительские элементы, которые были неявно выбраны/удалены
 *
 * @param {IPreparedPermission[]} data дерево всех полномочий
 * @param {IPermission[]} selected выбранные полномочия
 */
const updateParents = (data: IPreparedPermission[], selected: IPermission[]): IPermission[] => {
	const result = [];

	for (const item of data) {
		if (item.children) {
			const currentSelected = updateParents(item.children, selected);

			if (item.children.every(c => currentSelected.some(s => s.permissionId === c.id))) {
				result.push({
					permissionId: item.id
				});
			}

			result.push(...currentSelected);
		} else {
			const permission = selected.find(s => s.permissionId === item.id);
			if (permission) {
				result.push({
					permissionId: item.id,
					restrictions: permission.restrictions,
					fields: permission.fields,
					params: permission.params
				});
			}
		}
	}
	return result;
};

export const CheckboxCell = ({row}: CellContext<IPreparedPermission, IPreparedPermission>) => {
	const original = row.original;
	const permissions = useContext(RolePermissionsCtx);
	const dispatch = useContext(RoleDispatchCtx);
	const semiSelected = useContext(SemiSelectedCtx);
	const data = useContext(TreeDataCtx);

	const isSelected = permissions.some(item => item.permissionId === original.id);
	const isSemiSelected = semiSelected.includes(original.id);

	const inputClass = isSemiSelected
		? 'role-page__ptable-checkbox-input_semi'
		: 'role-page__ptable-checkbox-input';

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const ids = getChildrenIds(original);

		const newPerms =
			event.target.checked || isSemiSelected
				? [...permissions, ...ids.map(id => ({permissionId: id}))]
				: permissions.filter(item => !ids.includes(item.permissionId));

		dispatch({
			type: 'update',
			field: 'permissions',
			value: updateParents(data, newPerms)
		});
	};

	return (
		<Checkbox
			className={{root: 'role-page__ptable-checkbox', icon: inputClass}}
			checked={isSelected || isSemiSelected}
			semiChecked={isSemiSelected}
			onChange={onChange}
		/>
	);
};
