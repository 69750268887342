import { HTMLAttributes, memo, useMemo } from 'react';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {extractFilteredRoles, getRolesPageOffset, getRolesPageSize} from '@src/selectors/entities/roles';
import {buildRolesColumns} from './buildRolesColumns';
import {SimpleTable} from '@src/components/SimpleTable';
import {Row} from '@tanstack/react-table';
import IRole from '@src/interfaces/IRole';
import {useChangePath} from '@src/core/hooks/useChangePath';
import useAppDispatch from '@src/core/hooks/useAppDispatch';
import {rolesSettingsActions} from '@src/store/modules/settings/pages/roles/rolesSettingsSlice';
import {defaultPageSizes} from '@src/constants/defaultPageSizes';

export const RolesTable = memo(() => {
	const {pushPath} = useChangePath();
	const dispatch = useAppDispatch();
	const {data, currentPage, pageCount} = useAppSelector(extractFilteredRoles);
	const offset = useAppSelector(getRolesPageOffset);
	const pageSize = useAppSelector(getRolesPageSize);
	const columns = useMemo(() => buildRolesColumns, []);
	const {setOffset, setPageSize} = rolesSettingsActions;

	const goToRole = (roleId: string) => {
		pushPath(`/roles/${roleId}`);
	};

	const handlePageChange = (page: number) => {
		const newOffset = page * pageSize;
		if (offset !== newOffset) {
			dispatch(setOffset(page * pageSize));
		}
	};

	const handlePageSizeChange = (value: number) => {
		const newOffset = Math.floor(offset / value);
		dispatch(setPageSize(value));
		dispatch(setOffset(newOffset));
	};

	const getRolesTableRowProps = (row: Row<IRole>): HTMLAttributes<HTMLTableRowElement> => ({
		onClick: () => goToRole(row.original.id)
	});

	return (
		<SimpleTable
			data={data}
			columns={columns}
			pageCount={pageCount}
			currentPage={currentPage}
			onPageChange={handlePageChange}
			onPageSizeChange={handlePageSizeChange}
			pageSizeOptions={defaultPageSizes}
			pageSize={pageSize}
			getTableRowProps={getRolesTableRowProps}
		/>
);
});
