import {CellContext} from '@tanstack/react-table';
import {IconButton} from '@tehzor/ui-components';
import classNames from 'classnames';
import {IScopeItem} from './RolesTable';

const icon = <i className="tz-simple-arrow-24 user-page__roles-table-expander-icon" />;

export const ExpanderCell = ({row}: CellContext<IScopeItem, IScopeItem>) =>
	row.original.children.length ? (
		<IconButton
			className={classNames('user-page__roles-table-expander-btn', {
				'user-page__roles-table-expander-btn_open': row.getIsExpanded()
			})}
			type="transparent"
			onClick={row.getToggleExpandedHandler()}
		>
			{icon}
		</IconButton>
	) : null;
