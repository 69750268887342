import { memo } from 'react';
import {CellContext} from '@tanstack/react-table';
import {IPreparedLogRecord} from '../utils/convertRecords';

const toastInfoIcon = <i className="tz-info-20" />;

export const TypeCell = memo(({row}: CellContext<IPreparedLogRecord, IPreparedLogRecord>) => {
	const {type, error, fields} = row.original;
	return (
		<div className="log-page__type-cell">
			{error || fields ? toastInfoIcon : null} {type}
		</div>
	);
});
