import { memo, useCallback, useEffect } from 'react';
import {EditableFieldLabel} from '@tehzor/ui-components';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {IObject} from '@tehzor/tools/interfaces/objects/IObject';
import ISpaceIndicatorsSet from '@tehzor/tools/interfaces/spaceIndicatorsSets/ISpaceIndicatorsSet';
import {extractSpaceIndicatorsSetsAsArray} from '@src/store/modules/entities/spaceIndicatorsSets/selectors';
import {SingleSelect} from '@src/components/SingleSelect';
import {objectActions} from '@src/store/modules/entities/object/objectSlice';
import useAppDispatch from '@src/core/hooks/useAppDispatch';

function determineDefaultSet(
	objects: Record<string, IObject>,
	sets: ISpaceIndicatorsSet[],
	companyId?: string,
	parentId?: string
) {
	if (parentId && objects[parentId]?.spaceIndicatorsSet) {
		return objects[parentId].spaceIndicatorsSet;
	}
	if (companyId) {
		const set = sets.find(s => s.companies?.includes(companyId));
		if (set) {
			return set.id;
		}
	}
	const set = sets.find(s => s.shared);
	if (set) {
		return set.id;
	}
	return undefined;
}
interface ISpaceIndicatorsSetFieldProps {
	value?: string;
	companyId?: string;
	parentId?: string;
	onChange?: (value: string) => void;
}

export const SpaceIndicatorsSetField = memo((
	{
		value,
		companyId,
		parentId,
		onChange
	}: ISpaceIndicatorsSetFieldProps
) => {
	const indicatorsSets = useAppSelector(extractSpaceIndicatorsSetsAsArray);
	const objects = useAppSelector(s => s.entities.objects);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const setId = determineDefaultSet(objects.byId, indicatorsSets, companyId, parentId);
		if (setId) {
			onChange?.(setId);
		}
	}, [companyId, parentId, objects, indicatorsSets, onChange]);

	const handleChange = useCallback((id: string) => {
		onChange?.(id);
		dispatch(objectActions.setEdited(true));
	}, [onChange, dispatch]);

	return (
		<div className="field">
			<EditableFieldLabel className="field__label">Набор индикаторов помещений</EditableFieldLabel>
			<SingleSelect
				noHeader
				optionId={value}
				options={indicatorsSets}
				onChange={handleChange}
			/>
		</div>
	);
});