import { memo } from 'react';
import {ILogRecord} from '@tehzor/tools/interfaces/ILogRecord';
import useAppSelector from '@src/core/hooks/useAppSelector';
import {getLogRecordTypesById} from '@src/selectors/entities/logRecordTypes';

interface IDetailsListProps {
	typeId: string;
	fields?: ILogRecord['fields'];
}

const renderValue = (value: unknown) => {
	if (typeof value === 'number' || typeof value === 'string') {
		return String(value);
	}
	if (Array.isArray(value)) {
		return value.join(', ');
	}
	return JSON.stringify(value);
};

export const DetailsInfo = memo(({typeId, fields}: IDetailsListProps) => {
	const typesById = useAppSelector(getLogRecordTypesById);

	const type = typesById[typeId];

	if (!fields) {
		return null;
	}

	return (
		<>
			<div className="field__label log-page__details-title">Информация:</div>

			<div className="log-page__details-list">
				{type?.availableFields
					? type.availableFields.map(item =>
							fields[item.key] ? (
								<div
									key={item.key}
									className="log-page__details-list-row"
								>
									<div className="log-page__details-list-name">{item.name}</div>
									<div className="log-page__details-list-value">
										{renderValue(fields[item.key])}
									</div>
								</div>
							) : null
					  )
					: Object.entries(fields).map(([key, value]) => (
							<div
								key={key}
								className="log-page__details-list-row"
							>
								<div className="log-page__details-list-name">{key}</div>
								<div className="log-page__details-list-value">
									{renderValue(value)}
								</div>
							</div>
					  ))}
			</div>
		</>
	);
});
